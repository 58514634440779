import React, { Fragment, useState } from 'react';
import { CargaContenido } from "../cargaContenido";
import './estilos.css';

export const Footer = (props) => {
    //console.log('footer',props.data)
    if (props.data == undefined) {
        return (
            <div className="section sectionFooter">
                <div className="container">
                    <div className="footer">
                        <div className="menu_footer_logo">
                            <img src="https://businessplus.es/assets/imgpruebas/logotipo.svg" />
                        </div>
                        <ul className="menuFooter">
                            <li className="itemMenuFooter"><a href="/politica-de-privacidad/">Política de privacidadd</a></li>
                            <li className="itemMenuFooter"><a href="/politica-de-cookies/">Política de cookies</a></li>
                            <li className="itemMenuFooter"><a href="/ayuda-y-soporte/">Ayuda y soporte</a></li>
                            <li className="itemMenuFooter"><a href="/quienes-somos/">¿Quienes somos?</a></li>
                            <li className="itemMenuFooter"><a href="/aviso-legal/">Aviso legal</a></li>

                            <div className="menuFooter_rrss">
                                <div className="menuFooter_rrss">
                                    <a href="https://twitter.com/businessplus_es" target="_blank">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="19.167" height="15.567" viewBox="0 0 19.167 15.567">
                                            <path id="Icon_awesome-twitter" data-name="Icon awesome-twitter" d="M17.2,7.26c.012.17.012.341.012.511A11.1,11.1,0,0,1,6.032,18.948,11.1,11.1,0,0,1,0,17.184a8.126,8.126,0,0,0,.949.049,7.867,7.867,0,0,0,4.877-1.678A3.935,3.935,0,0,1,2.153,12.83a4.954,4.954,0,0,0,.742.061,4.155,4.155,0,0,0,1.034-.134A3.929,3.929,0,0,1,.778,8.9V8.854a3.956,3.956,0,0,0,1.776.5A3.934,3.934,0,0,1,1.338,4.1a11.166,11.166,0,0,0,8.1,4.111,4.434,4.434,0,0,1-.1-.9,3.932,3.932,0,0,1,6.8-2.688,7.734,7.734,0,0,0,2.493-.949A3.918,3.918,0,0,1,16.9,5.837a7.875,7.875,0,0,0,2.262-.608A8.444,8.444,0,0,1,17.2,7.26Z" transform="translate(0 -3.381)" />
                                        </svg>
                                    </a>
                                    <a href="https://www.linkedin.com/company/businesspluses" target="_blank">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16.771" height="16.771" viewBox="0 0 16.771 16.771">
                                            <path id="Icon_awesome-linkedin-in" data-name="Icon awesome-linkedin-in" d="M3.754,16.771H.277V5.574H3.754ZM2.014,4.047A2.023,2.023,0,1,1,4.027,2.014,2.031,2.031,0,0,1,2.014,4.047ZM16.767,16.771H13.3V11.321c0-1.3-.026-2.965-1.808-2.965-1.808,0-2.085,1.411-2.085,2.871v5.544H5.932V5.574H9.267V7.1h.049a3.654,3.654,0,0,1,3.29-1.808c3.519,0,4.166,2.317,4.166,5.327v6.151Z" transform="translate(0 -0.001)" />
                                        </svg>
                                    </a>
                                    <a href="https://www.facebook.com/Businesspluses" target="_blank">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="18.671" viewBox="0 0 10 18.671">
                                            <path id="Icon_awesome-facebook-f" data-name="Icon awesome-facebook-f" d="M10.954,10.5l.519-3.379H8.23V4.931a1.69,1.69,0,0,1,1.905-1.826h1.474V.228A17.975,17.975,0,0,0,8.993,0C6.323,0,4.578,1.618,4.578,4.548V7.123H1.609V10.5H4.578v8.169H8.23V10.5Z" transform="translate(-1.609)" />
                                        </svg>
                                    </a>
                                    <a href="https://www.instagram.com/businesspluses/" target="_blank">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17.352" height="17.352" viewBox="0 0 17.352 17.352">
                                            <path id="Unión_1" data-name="Unión 1" d="M2494,46.352a4,4,0,0,1-4-4V33a4,4,0,0,1,4-4h9.352a4,4,0,0,1,4,4v9.353a4,4,0,0,1-4,4Zm.337-8.676a4.339,4.339,0,1,0,4.339-4.338A4.343,4.343,0,0,0,2494.337,37.676Zm8.677-4.772a1.3,1.3,0,1,0,1.3-1.3A1.3,1.3,0,0,0,2503.014,32.9Zm-6.941,4.772a2.6,2.6,0,1,1,2.6,2.6A2.6,2.6,0,0,1,2496.073,37.676Z" transform="translate(-2490 -29)" />
                                        </svg>

                                    </a>
                                    <a href="https://www.youtube.com/businessplus_es" target="_blank">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20.445" height="14.375" viewBox="0 0 20.445 14.375">
                                            <path id="Icon_awesome-youtube" data-name="Icon awesome-youtube" d="M21.068,6.749A2.569,2.569,0,0,0,19.26,4.93c-1.594-.43-7.988-.43-7.988-.43s-6.393,0-7.988.43A2.569,2.569,0,0,0,1.477,6.749,26.949,26.949,0,0,0,1.05,11.7a26.949,26.949,0,0,0,.427,4.953,2.531,2.531,0,0,0,1.808,1.79c1.594.43,7.988.43,7.988.43s6.393,0,7.988-.43a2.531,2.531,0,0,0,1.808-1.79,26.949,26.949,0,0,0,.427-4.953,26.949,26.949,0,0,0-.427-4.953ZM9.181,14.742V8.662l5.344,3.04Z" transform="translate(-1.05 -4.5)" />
                                        </svg>
                                    </a>
                                    <a href="https://www.tiktok.com/@businesspluses" target="_blank">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20.445" height="18" viewBox="0 0 20.445 18">
                                            <path class="st0" d="M16.2,4.4c-1,0-1.9-0.3-2.6-0.9c-0.8-0.6-1.4-1.5-1.6-2.6c-0.1-0.3-0.1-0.5-0.1-0.8H9.1v7.6l0,4.2
                                            c0,1.1-0.7,2.1-1.7,2.4c-0.3,0.1-0.6,0.1-0.9,0.1c-0.4,0-0.8-0.2-1.2-0.4C4.5,13.7,4,12.9,4,12c0-1.4,1.1-2.6,2.5-2.6
                                            c0.3,0,0.5,0,0.8,0.1V7.4V6.7c-0.3,0-0.5-0.1-0.8-0.1c-1.5,0-3,0.6-4,1.8c-0.8,0.9-1.2,2-1.3,3.2c-0.1,1.5,0.5,3,1.6,4.1
                                            c0.2,0.2,0.3,0.3,0.5,0.4c0.9,0.7,2.1,1.1,3.3,1.1c0.3,0,0.5,0,0.8-0.1c1.1-0.2,2.2-0.7,3-1.5c1-1,1.6-2.3,1.6-3.7l0-6.2
                                            c0.5,0.4,1,0.7,1.6,0.9c0.9,0.4,1.8,0.6,2.8,0.6v-2L16.2,4.4C16.2,4.4,16.2,4.4,16.2,4.4L16.2,4.4z"/>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </ul>
                        <div className="copyfooter">
                            <p>© Business+. Todos los derechos reservados. <a href=" https://www.spinmedia.es" target="_blank">Powered by Spinmedia</a></p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div className="section sectionFooter">
            <div className="container">
                <div className="footer">
                    <div className="menu_footer_logo">
                        <img src="https://businessplus.es/assets/imgpruebas/logotipo.svg" />
                    </div>
                    <ul className="menuFooter">
                        {props.data.map(itemData =>
                            itemData.tipoZona == 'menu' && itemData.titulo == "Menú Pie" && itemData.menuItems != null && itemData.menuItems.map(item => {
                                return (
                                    <li className="itemMenuFooter"><a href={item.enlace}>{item.titulo}</a></li>
                                )
                            }
                            )
                        )}
                        <div className="menuFooter_rrss">
                            <a href="https://twitter.com/businessplus_es" target="_blank">
                                <svg xmlns="http://www.w3.org/2000/svg" width="19.167" height="15.567" viewBox="0 0 19.167 15.567">
                                    <path id="Icon_awesome-twitter" data-name="Icon awesome-twitter" d="M17.2,7.26c.012.17.012.341.012.511A11.1,11.1,0,0,1,6.032,18.948,11.1,11.1,0,0,1,0,17.184a8.126,8.126,0,0,0,.949.049,7.867,7.867,0,0,0,4.877-1.678A3.935,3.935,0,0,1,2.153,12.83a4.954,4.954,0,0,0,.742.061,4.155,4.155,0,0,0,1.034-.134A3.929,3.929,0,0,1,.778,8.9V8.854a3.956,3.956,0,0,0,1.776.5A3.934,3.934,0,0,1,1.338,4.1a11.166,11.166,0,0,0,8.1,4.111,4.434,4.434,0,0,1-.1-.9,3.932,3.932,0,0,1,6.8-2.688,7.734,7.734,0,0,0,2.493-.949A3.918,3.918,0,0,1,16.9,5.837a7.875,7.875,0,0,0,2.262-.608A8.444,8.444,0,0,1,17.2,7.26Z" transform="translate(0 -3.381)" />
                                </svg>
                            </a>
                            <a href="https://www.linkedin.com/company/businesspluses" target="_blank">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16.771" height="16.771" viewBox="0 0 16.771 16.771">
                                    <path id="Icon_awesome-linkedin-in" data-name="Icon awesome-linkedin-in" d="M3.754,16.771H.277V5.574H3.754ZM2.014,4.047A2.023,2.023,0,1,1,4.027,2.014,2.031,2.031,0,0,1,2.014,4.047ZM16.767,16.771H13.3V11.321c0-1.3-.026-2.965-1.808-2.965-1.808,0-2.085,1.411-2.085,2.871v5.544H5.932V5.574H9.267V7.1h.049a3.654,3.654,0,0,1,3.29-1.808c3.519,0,4.166,2.317,4.166,5.327v6.151Z" transform="translate(0 -0.001)" />
                                </svg>
                            </a>
                            <a href="https://www.facebook.com/BusinessPlus-107615521419057" target="_blank">
                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="18.671" viewBox="0 0 10 18.671">
                                    <path id="Icon_awesome-facebook-f" data-name="Icon awesome-facebook-f" d="M10.954,10.5l.519-3.379H8.23V4.931a1.69,1.69,0,0,1,1.905-1.826h1.474V.228A17.975,17.975,0,0,0,8.993,0C6.323,0,4.578,1.618,4.578,4.548V7.123H1.609V10.5H4.578v8.169H8.23V10.5Z" transform="translate(-1.609)" />
                                </svg>
                            </a>
                            <a href="https://www.instagram.com/businesspluses/" target="_blank">
                                <svg xmlns="http://www.w3.org/2000/svg" width="17.352" height="17.352" viewBox="0 0 17.352 17.352">
                                    <path id="Unión_1" data-name="Unión 1" d="M2494,46.352a4,4,0,0,1-4-4V33a4,4,0,0,1,4-4h9.352a4,4,0,0,1,4,4v9.353a4,4,0,0,1-4,4Zm.337-8.676a4.339,4.339,0,1,0,4.339-4.338A4.343,4.343,0,0,0,2494.337,37.676Zm8.677-4.772a1.3,1.3,0,1,0,1.3-1.3A1.3,1.3,0,0,0,2503.014,32.9Zm-6.941,4.772a2.6,2.6,0,1,1,2.6,2.6A2.6,2.6,0,0,1,2496.073,37.676Z" transform="translate(-2490 -29)" />
                                </svg>

                            </a>
                            <a href="https://www.youtube.com/businessplus_es" target="_blank">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20.445" height="14.375" viewBox="0 0 20.445 14.375">
                                    <path id="Icon_awesome-youtube" data-name="Icon awesome-youtube" d="M21.068,6.749A2.569,2.569,0,0,0,19.26,4.93c-1.594-.43-7.988-.43-7.988-.43s-6.393,0-7.988.43A2.569,2.569,0,0,0,1.477,6.749,26.949,26.949,0,0,0,1.05,11.7a26.949,26.949,0,0,0,.427,4.953,2.531,2.531,0,0,0,1.808,1.79c1.594.43,7.988.43,7.988.43s6.393,0,7.988-.43a2.531,2.531,0,0,0,1.808-1.79,26.949,26.949,0,0,0,.427-4.953,26.949,26.949,0,0,0-.427-4.953ZM9.181,14.742V8.662l5.344,3.04Z" transform="translate(-1.05 -4.5)" />
                                </svg>
                            </a>
                            <a href="https://www.tiktok.com/@businesspluses" target="_blank">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20.445" height="18" viewBox="0 0 20.445 18">
                                    <path class="st0" d="M16.2,4.4c-1,0-1.9-0.3-2.6-0.9c-0.8-0.6-1.4-1.5-1.6-2.6c-0.1-0.3-0.1-0.5-0.1-0.8H9.1v7.6l0,4.2
                                    c0,1.1-0.7,2.1-1.7,2.4c-0.3,0.1-0.6,0.1-0.9,0.1c-0.4,0-0.8-0.2-1.2-0.4C4.5,13.7,4,12.9,4,12c0-1.4,1.1-2.6,2.5-2.6
                                    c0.3,0,0.5,0,0.8,0.1V7.4V6.7c-0.3,0-0.5-0.1-0.8-0.1c-1.5,0-3,0.6-4,1.8c-0.8,0.9-1.2,2-1.3,3.2c-0.1,1.5,0.5,3,1.6,4.1
                                    c0.2,0.2,0.3,0.3,0.5,0.4c0.9,0.7,2.1,1.1,3.3,1.1c0.3,0,0.5,0,0.8-0.1c1.1-0.2,2.2-0.7,3-1.5c1-1,1.6-2.3,1.6-3.7l0-6.2
                                    c0.5,0.4,1,0.7,1.6,0.9c0.9,0.4,1.8,0.6,2.8,0.6v-2L16.2,4.4C16.2,4.4,16.2,4.4,16.2,4.4L16.2,4.4z"/>
                                </svg>
                            </a>
                        </div>
                    </ul>
                    <div className="copyfooter">
                        <p>© Business+. Todos los derechos reservados. <a href=" https://www.spinmedia.es" target="_blank">Powered by Spinmedia</a></p>
                    </div>
                </div>
            </div>
        </div>
    )
}