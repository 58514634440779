import React, { Fragment, useState, useEffect, useContext } from 'react'
import axios from 'axios';
import { Helmet } from "react-helmet";
import Script from 'react-load-script';

import { Header } from "../../componentes/header";
import { CargaContenido } from "../../componentes/cargaContenido";
import { SliderItems } from "../../componentes/sliderItems";
import { CajaItem } from "../../componentes/cajaItem"
import { Footer } from "../../componentes/footer";
import { VideoPlayer } from '../../componentes/videoPlayer';
import { RegistroPresencial } from '../../componentes/registroPresencial';
import { RegistroOnline } from '../../componentes/registroOnline';
import Context from '../../Context';
import GuardarLogContext from '../../ContextLog'
import { ModalRegistro } from "../../componentes/modalRegistro";
import { ModalLogin } from "../../componentes/modalLogin";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserLock } from '@fortawesome/free-solid-svg-icons';
import { ScrollToTop } from '../../componentes/scrollToTop';
import { GridPonentes } from "../../componentes/gridPonentes";
import { Listadopreguntas } from '../../componentes/Listadopreguntas'

export const Evento = (props) => {
    console.log('Evento:: props.eventoSeleccionado',props.eventoSeleccionado)
    
    const [inscrito, setInscrito] = useState(props.eventoSeleccionado.inscrito);
    console.log("Evento:: inscrito::", inscrito);
    const log = useContext(GuardarLogContext)
    const [esBajoDemanda, setEsBajoDemanda] = useState(false);
    function bajoDemandaCheck() {
        if (props.eventoSeleccionado.idTipoEvento === 2) {
            setEsBajoDemanda(true)
        } else {
            setEsBajoDemanda(false)
        }
    }
    useEffect(() => {
        bajoDemandaCheck()
    }, [])

    const tiempoRecarga = 30000;
    useEffect(() => {
        const interval = setInterval(() => {
            if (props.eventoSeleccionado.idTipoEvento === 12) {
                window.location.reload();
            }
        }, tiempoRecarga);
        return () => clearInterval(interval);
    }, [])

    function checkRecordatorio() {
        if (localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-recordatorio`)) {
            log.logEvento(props.eventoSeleccionado.idEvento, esBajoDemanda, "logPorMailRecordatorio", true)
            localStorage.removeItem(`${process.env.REACT_APP_ID_STORAGE}-recordatorio`);
        }
    }
    useEffect(() => {
        checkRecordatorio()
    }, [])

    useEffect(() => {
        console.log("Evento:: prop inscrito")
        setInscrito(props.eventoSeleccionado.inscrito)
    }, [props])

    function logEntradaEvento() {
        if (
            props.isAuth &&
            (!localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-idEntradaEvento`) || localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-idEntradaEvento`) != props.eventoSeleccionado.idEvento)
        ) {
            localStorage.setItem(`${process.env.REACT_APP_ID_STORAGE}-idEntradaEvento`, props.eventoSeleccionado.idEvento)
            log.logEvento(props.eventoSeleccionado.idEvento, esBajoDemanda, "entradaEvento", true)
        }
    }
    useEffect(() => {
        logEntradaEvento()
    }, [])

    let fechaEvento = props.eventoSeleccionado.fechaEvento;
    let year = fechaEvento.split("-")[0];
    let month = fechaEvento.split("-")[1];
    if (month.substr(0, 1) == "0") {
        month = month.substr(1);
    }
    month = month - 1;
    let day = fechaEvento.split("-")[2].split("T")[0];
    let monthNames = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"];
    let monthName = monthNames[month];

    if (day < 10) {
        day = day.split('0')[1]
    }

    let horaEvento = props.eventoSeleccionado.horaInicio;
    let hour = horaEvento.split(":")[0];
    let minute = horaEvento.split(":")[1];
    var ampm = hour >= 12 ? 'pm' : 'am';

    console.log('zonasContenido', props.zonasContenido)
    console.log('seoContenido', props.seoContenido)
    console.log('eventoSeleccionado', props.eventoSeleccionado)
    let esPresencial = false;


    // const playEvent = async (tipo) => {

    //     if (props.isAuth) {
    //         let BajoDemanda = false
    //         if (tipo == 2) { BajoDemanda = true }
    //         props.logEvento(props.eventoSeleccionado.idEvento, BajoDemanda, 'play', 'true')
    //     }
    // }
    // const pauseEvent = async (tipo) => {
    //     if (props.isAuth) {
    //         let BajoDemanda = false
    //         if (tipo == 2) { BajoDemanda = true }
    //         props.logEvento(props.eventoSeleccionado.idEvento, BajoDemanda, 'pause', 'true')
    //     }
    // }

    // const endedEvent = async (tipo) => {
    //     if (props.isAuth) {
    //         let BajoDemanda = false
    //         if (tipo == 2) { BajoDemanda = true }
    //         props.logEvento(props.eventoSeleccionado.idEvento, BajoDemanda, 'End', 'true')
    //     }
    // }

    const [isAgenda, setIsAgenda] = useState(false);
    props.zonasContenido.map(item => {
        if (item.idZona === 6 && !isAgenda) {
            setIsAgenda(true)
        }
        if (!item.idZona === 6 && isAgenda) {
            setIsAgenda(true)
        }
    })
    const [isChat, setIsChat] = useState(false);
    props.zonasContenido.map(item => {
        if (item.tipoZona === "chat_preguntas" && !isChat) {
            setIsChat(true)
        }
    })

    if (!props.zonasContenido) { return (<div></div>) }

    return (
        <Context.Consumer>{({ modalRegistro, toggleModalRegisitro, modalLogin, toggleModalLogin, activateAuth, isAuth, updateEmailLogin, emailLogin }) => <>
            {
                props.seoContenido !== null &&
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="description" content={props.seoContenido.seoDescription} />
                    <title>{props.seoContenido.seoTitle}</title>
                    <meta property="og:title" content={props.seoContenido.seoTitle} />
                    <meta property="og:description" content={props.seoContenido.seoDescription} />
                    <meta property="og:locale" content="es_ES" />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:site_name" content="Business+"></meta>
                    <meta property="article:modified_time" content={props.seoContenido.fechaModificacion}></meta>
                    <meta property="og:image" content={props.seoContenido.seoImage} />
                    <meta property="og:image:width" content={props.seoContenido.seoImageWidth} />
                    <meta property="og:image:height" content={props.seoContenido.seoImageHeight} />
                    <meta name="twitter:title" content={props.seoContenido.seoTitle} />
                    <meta name="twitter:description" content={props.seoContenido.seoDescription} />
                    <meta name="twitter:image" content={props.seoContenido.seoImage} />

                    <meta property="og:type" content="website" />
                    <meta itemprop="name" content={props.seoContenido.seoTitle} />
                    <meta itemprop="url" content={window.location.href} />
                    <meta itemprop="thumbnailUrl" content={props.seoContenido.seoImage} />
                    <meta name="twitter:url" content={window.location.href} />
                    <meta name="twitter:card" content="summary" />
                </Helmet>
            }
            <ModalRegistro mostrarModal={modalRegistro} toggleModalRegisitro={toggleModalRegisitro} />
            <ModalLogin updateEmailLogin={updateEmailLogin} emailLogin={emailLogin}  mostrarModal={modalLogin} toggleModalLogin={toggleModalLogin} toggleModalRegisitro={toggleModalRegisitro} activateAuth={activateAuth} />
            <Header logo={props.zonasContenido[0].texto} data={props.zonasContenido} />

            <div className="section evento bg-gradientDark">
                <div className="evento_container">
                    <div className="evento_online_main_content">
                        {
                            props.isAuth ?
                                !inscrito && !props.eventoSeleccionado.privado ?
                                    props.zonasContenido.map(item =>
                                        item.idZona === 3 && item.tipoZona === 'imagen' &&
                                        <div className="container-img">
                                            <img className="img-responsive" src={`${item.imagen}`} />
                                            <div className="centered">
                                                <div className="claimLogin">
                                                    <div className="d-flex justify-content-center imgCandado">
                                                    </div>
                                                    <RegistroOnline
                                                        isAuth={props.isAuth}
                                                        idEvento={props.eventoSeleccionado.idEvento}
                                                        dataEvento={props.eventoSeleccionado}
                                                        inscrito={inscrito}
                                                        setInscrito={setInscrito}
                                                        logEvento={log.logEvento}>
                                                    </RegistroOnline>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    :
                                    <>
                                        {
                                            /*
                                           player tipoeventos
                                           1 webinar
                                           2 bajo demanda
                                           3 presencial
                                           4 directo
                                           5 contenido
                                           6 tutorial
                                           10 congreso online
                                           11 canal
                                           12 contenido con recarga
                                           */

                                            // directo o webinar amazon 
                                            ((props.eventoSeleccionado.idTipoEvento == 1 || props.eventoSeleccionado.idTipoEvento == 4) && props.eventoSeleccionado.idContenedorPlayer == null) &&
                                            props.zonasContenido.map(item =>
                                                <Fragment>
                                                    
                                                    {item.idZona === 3 && item.tipoZona == 'imagen' &&
                                                        <VideoPlayer idEvento={props.eventoSeleccionado.idEvento} urlSource={props.eventoSeleccionado.urlPlayer} tipo='application/x-mpegURL' directo={true} />
                                                    }
                                                </Fragment>
                                            )
                                        }
                                        {
                                            // directo o webinar conrado 
                                            ((props.eventoSeleccionado.idTipoEvento == 1 || props.eventoSeleccionado.idTipoEvento == 4) && props.eventoSeleccionado.idContenedorPlayer !== null) &&
                                            <Fragment>
                                                <div id={props.eventoSeleccionado.idContenedorPlayer}>

                                                </div>
                                                <Script
                                                    url={props.eventoSeleccionado.urlPlayer}
                                                    onLoad={log.logEvento(props.eventoSeleccionado.idEvento, esBajoDemanda, "playStream", true)}
                                                />
                                            </Fragment>
                                        }
                                        {
                                            //bajo demanda 
                                            props.eventoSeleccionado.idTipoEvento == 2 &&
                                            props.zonasContenido.map(item =>
                                                <Fragment>
                                                   
                                                    {item.idZona === 3 && item.tipoZona == 'imagen' &&
                                                        <>
                                                        
                                                        <VideoPlayer idEvento={props.eventoSeleccionado.idEvento} urlSource={props.eventoSeleccionado.urlPlayer} tipo='' isBajodemanda="true" />
                                                        </>
                                                       
                                                    }
                                                </Fragment>
                                            )
                                        }
                                        {
                                            //contenido, presencial, congreso online, contenido con recarga
                                            (props.eventoSeleccionado.idTipoEvento == 5 || props.eventoSeleccionado.idTipoEvento == 3 || props.eventoSeleccionado.idTipoEvento == 10 || props.eventoSeleccionado.idTipoEvento == 12) &&
                                            props.zonasContenido.map(item =>
                                                item.idZona === 3 && item.tipoZona === 'imagen' &&
                                                <div className="container-img">
                                                    <img className="img-responsive" src={`${item.imagen}`} />
                                                    <div className="anuncio_emision">
                                                        <img src="https://businessplus.es/assets/imgpruebas/anuncio_emision_logo.svg" alt="icono anuncio de emisión" />
                                                        <div className="anuncio_emision_texto">
                                                            <p>La emisión en directo empezará el dia:</p>
                                                            <span>{day} de {monthName}, {year} a las {hour}:{minute} {ampm}.</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            )

                                        }
                                    </>

                                :
                                <>
                                    {   //user no login 

                                        props.zonasContenido.map(item =>
                                            item.idZona === 3 && item.tipoZona === 'imagen' && props.eventoSeleccionado.privado ?
                                            <div className="container-img">
                                                <img className="img-responsive" src={`${item.imagen}`} />
                                                <div className="centered">
                                                    <div className="claimLogin botones_registro_videoplayer">
                                                        <div className="botones_registro_videoplayer_icon">
                                                            <FontAwesomeIcon icon={faUserLock} />
                                                        </div>
                                                        <p className="botones_registro_videoplayer_msg">Para poder disfrutar de este contenido es <span>necesario</span> tener una cuenta en Business+</p>
                                                        <img src="https://businessplus.es/assets/imgpruebas/Icon-arrow-forward.svg" alt="" />
                                                        <div onClick={() => toggleModalLogin()} className="boton_registro_videoplayer1">ya tengo cuenta</div>
                                                        <div onClick={() => toggleModalRegisitro()} className="boton_registro_videoplayer2">Crear Cuenta Gratis</div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            item.idZona === 3 && item.tipoZona === 'imagen' && !props.eventoSeleccionado.privado &&
                                            <div className="container-img">
                                                <img className="img-responsive" src={`${item.imagen}`} />
                                                <div className="centered">
                                                    <RegistroPresencial
                                                                isAuth={props.isAuth}
                                                                idEvento={props.eventoSeleccionado.idEvento}
                                                                dataEvento={props.eventoSeleccionado}
                                                                inscrito={inscrito}
                                                                setInscrito={setInscrito}
                                                                esPresencial={esPresencial}
                                                                logEvento={log.logEvento}>
                                                            </RegistroPresencial>
                                                </div>
                                            </div>
                                        )
                                        //end user no login
                                    }
                                </>
                        }
                        {
                            props.zonasContenido.map(item =>
                                item.idZona === 4 &&
                                <CargaContenido item={item} tipo={item.tipoZona} eventoSeleccionado={props.eventoSeleccionado} isAuth={isAuth} inscrito={inscrito} />
                            )
                        }
                        {
                            !props.eventoSeleccionado.privado ?
                                <RegistroPresencial
                                    isAuth={props.isAuth}
                                    idEvento={props.eventoSeleccionado.idEvento}
                                    dataEvento={props.eventoSeleccionado}
                                    inscrito={inscrito}
                                    setInscrito={setInscrito}
                                    esPresencial={esPresencial}
                                    logEvento={log.logEvento}>
                                </RegistroPresencial>
                                : null
                        }
                    </div>


                    <div className="evento_online_lateral_content">
                        {
                            ((props.eventoSeleccionado.idTipoEvento === 4 || props.eventoSeleccionado.idTipoEvento === 1) && ((props.isAuth && inscrito) || (props.isAuth && props.eventoSeleccionado.privado === true)) && isChat) &&
                            <div className="caja-lateral widget_chat">
                                <h3>Chat en directo</h3>
                                <p>Te damos la bienvenida al chat en directo. Participa y resuelve tus dudas.</p>
                                <a href="#chatDirecto"><div className="chat_button">IR AL CHAT</div></a>
                            </div>
                        }
                        {
                            (props.eventoSeleccionado.idTipoEvento === 4 || props.eventoSeleccionado.idTipoEvento === 1) &&
                            props.zonasContenido.map(item =>
                                <Fragment>
                                    {
                                        item.idZona === 10 && item.orden === 1 &&
                                        <div className="caja-lateral caja-stands">
                                            {item.titulo !== null ?
                                                <div className="titulo">{item.titulo}</div>
                                                : null
                                            }
                                            {
                                                props.zonasContenido.map(item =>
                                                    item.idZona === 10 &&
                                                    <a href={item.enlace} target="blank" onClick={() => log.logEvento(props.eventoSeleccionado.idEvento, esBajoDemanda, "visualiza-standvirtual", item.enlace.split("/")[2])} ><img src={item.imagen} alt="" /></a>
                                                )
                                            }
                                        </div>
                                    }
                                </Fragment>
                            )
                        }
                        {
                            props.zonasContenido.map(item =>
                                <Fragment>
                                    {item.idZona === 5 &&
                                        <div className="caja-lateral">
                                            {
                                                props.isAuth ?
                                                    item.orden === 2 && !props.eventoSeleccionado.privado ?
                                                        <Fragment>
                                                            <CargaContenido item={item} tipo={item.tipoZona} />
                                                            <div className="detallesorden2_separador"></div>
                                                            <RegistroPresencial
                                                                isAuth={props.isAuth}
                                                                idEvento={props.eventoSeleccionado.idEvento}
                                                                dataEvento={props.eventoSeleccionado}
                                                                inscrito={inscrito}
                                                                setInscrito={setInscrito}
                                                                esPresencial={esPresencial}
                                                                logEvento={log.logEvento}>
                                                            </RegistroPresencial>

                                                        </Fragment>
                                                        :
                                                        <CargaContenido item={item} tipo={item.tipoZona} />

                                                    :
                                                    item.orden === 2 && !props.eventoSeleccionado.privado ?
                                                        <Fragment>
                                                            <CargaContenido item={item} tipo={item.tipoZona} />
                                                            <div className="detallesorden2_separador"></div>
                                                            <RegistroPresencial
                                                                isAuth={props.isAuth}
                                                                idEvento={props.eventoSeleccionado.idEvento}
                                                                inscrito={inscrito}
                                                                setInscrito={setInscrito}
                                                                esPresencial={esPresencial}
                                                                logEvento={log.logEvento}
                                                                dataEvento={props.eventoSeleccionado}>
                                                            </RegistroPresencial>

                                                        </Fragment>
                                                        :
                                                        <CargaContenido item={item} tipo={item.tipoZona} />
                                            }
                                        </div>
                                    }
                                </Fragment>
                            )
                        }

                        {
                            (props.eventoSeleccionado.idTipoEvento !== 4 && props.eventoSeleccionado.idTipoEvento !== 1) &&
                            props.zonasContenido.map(item =>
                                <Fragment>
                                    {
                                        item.idZona === 10 && item.orden === 1 &&
                                        <div className="caja-lateral caja-stands">
                                            {item.titulo !== null ?
                                                <div className="titulo">{item.titulo}</div>
                                                : null
                                            }
                                            {
                                                props.zonasContenido.map(item =>
                                                    item.idZona === 10 &&
                                                    <a href={item.enlace} target="blank" onClick={() => log.logEvento(props.eventoSeleccionado.idEvento, esBajoDemanda, "visualiza-standvirtual", item.enlace.split("/")[2])} ><img src={item.imagen} alt="" /></a>
                                                )
                                            }
                                        </div>
                                    }
                                </Fragment>
                            )
                        }

                    </div>
                    {
                        isAgenda ?
                            <div className="agenda-evento">
                                <h2>Agenda evento</h2>
                                {
                                    props.zonasContenido.map(item =>
                                        <Fragment>
                                            {item.idZona === 6 &&
                                                <div className="item-agenda">
                                                    <CargaContenido item={item} tipo={item.tipoZona} />
                                                </div>
                                            }
                                        </Fragment>
                                    )
                                }
                            </div>
                            : null
                    }
                </div>
            </div>

            {
                props.zonasContenido.map(item =>
                    item.tipoZona === "listado_ponentes" ?
                        <div className="section bg-gradientDarkFlip section_ponentes">
                            <div className="container_ponentes">
                                <div class="titulo">Ponentes</div>
                                {
                                    props.zonasContenido.map(item =>
                                        item.tipoZona === "listado_ponentes" &&
                                        <GridPonentes ponentes={item.ponentes} />

                                    )
                                }
                            </div>
                        </div>
                        : null
                )
            }

            {
                props.zonasContenido.map(item =>
                    item.idZona ===11 &&
                    <Fragment>
                    <CargaContenido item={item} tipo={item.tipoZona} isAuth={props.isAuth} />
                    </Fragment>
                )
            }



            {
                props.zonasContenido.map(item =>
                    item.idZona === 8 && item.orden === 1 ?
                        <div className="section bg-white section_sponsors">
                            {
                                <div className="cajaSponsors destacado">
                                    <div className="tituloSponsor">{item.titulo}</div>
                                    <div className="gridsponsors">
                                        {
                                            props.zonasContenido.map(item =>
                                                item.idZona === 8 && item.orden === 1 &&
                                                item.logotipos.map(logotipoSponsor =>
                                                    <div className="itemSponsor"><a href={logotipoSponsor.enlace} target={logotipoSponsor.target}><img src={logotipoSponsor.urlLogotipo} alt={logotipoSponsor.alt} /></a></div>
                                                )
                                            )
                                        }
                                    </div>
                                </div>
                            }

                            {
                                props.zonasContenido.map(item =>
                                    item.idZona === 8 && item.orden != 1 ?

                                        <div className="cajaSponsors">
                                            <div className="tituloSponsor">{item.titulo}</div>
                                            <div className="gridsponsors">
                                                {
                                                    item.logotipos.map(logotipoSponsor =>
                                                        <div className="itemSponsor"><a href={logotipoSponsor.enlace} target={logotipoSponsor.target}><img src={logotipoSponsor.urlLogotipo} alt={logotipoSponsor.alt} /></a></div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        : null
                                )
                            }
                        </div>
                        : null
                )
            }
            <Footer logo={props.zonasContenido[0].texto} data={props.zonasContenido} />
            <ScrollToTop />
        </>}
        </Context.Consumer>
    )

}