import React, { useState } from 'react'
import axios from 'axios';
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import './estilos.css';

export const CajaItemEventoPresencial = (props) => {
    //console.log('props presencial:::', props)

    return (
        <div className="cajaItem_eventoPresencial"  >
            <a href={props.enlace}>
                <img src={props.imagen} />
            </a>
        </div>
    )
}