import React, { useState, useRef } from 'react';
import axios from 'axios';
import { Botonbase } from "../botonbase";
import './estilos.css'; // Tell webpack that Button.js uses these styles

export const ModalRegistroPresencial = (props) => {
  const cerrarModalRegistroPresencial = () => {
    props.setmostrarModal(false)
    window.location.reload()
  }

  return (
    <div className={`baseModal ${props.mostrarModal ? 'open' : ''}`}>
      <div className="modalBody modalInscripcionbody">
        <p className="modalRegistro_cerrar" onClick={() => cerrarModalRegistroPresencial()}>cerrar x</p>
        <div className="tituloheader">Business <span class="cross">+</span></div>
        <div className="row modalInscripcion" >
          <div className="titulo">Tu inscripción se ha realizado correctamente. </div>
          <p>Te hemos enviado un email con los datos de tu inscripción. Por favor, revisa tu bandeja de entrada, correo no deseado o spam donde encontrarás la confirmación de inscripción al evento.</p>

          <div className="btn transparente" onClick={() => cerrarModalRegistroPresencial()}>Cerrar</div>
        </div >
      </div>
    </div>

  )
}