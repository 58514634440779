import React, { Fragment, useState, useEffect } from 'react'
import axios from 'axios';
import './estilos.css';

export const SubirImagen = (props) => {
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState("");
  const [frontImage, setFrontImage] = useState(props.imagenUsuario);
  const [errorImage, setErrorImage] = useState(false);
  const [confirmationImage, setConfirmationImage] = useState(false);
  const [errorImageMsg, setErrorImageMsg] = useState('Ha habido un error');

  const saveFile = (e) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
    setConfirmationImage(false);
    setErrorImage(false);
  };

  const uploadFile = async (e) => {
    let axiosConfig = {
      headers: {
        'Accept': 'image/*',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-token`)}`
      },
    };

    let imageHasError = false;
    if (file) {
      let fileSize = file.size;
      let fileType = file.type.split("/")[1].toString();

      console.log("file:", file);
      console.log("fileType:", fileType);
      console.log("fileSize:", fileSize);

      if (fileType != "jpeg" && fileType != "jpg" && fileType != "png" && fileType != "gif" && fileType != "svg") {
        imageHasError = true;
        setErrorImage(true);
        setErrorImageMsg('El tipo de imagen seleccionado no es correcto')
      }
      if (fileSize > 10000000) {
        imageHasError = true;
        setErrorImage(true);
        setErrorImageMsg('La imagen que intentas subir pesa más de 10 megas')
      }
    }

    const formData = new FormData();
    formData.append("FileName", fileName);
    formData.append("FormFile", file);
    formData.append("idUsuario", localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-idu`));

    if (imageHasError) return
    try {
      const res = await axios.post(`${process.env.REACT_APP_URL_API}Usuarios/subirImagenUsuario`, formData, axiosConfig);
      console.log(res);
      setErrorImage(false);
      setConfirmationImage(true);
      fetchNewImage()
    } catch (ex) {
      console.log(ex);
      setErrorImage(true);
      setErrorImageMsg('Ha habido un error, intentelo de nuevo más tarde')
    }
  };

  const fetchNewImage = async () => {
    try {
      let axiosConfig = {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-token`)}`
        },
      };
      await axios.get(`${process.env.REACT_APP_URL_API}usuarios/${localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-idu`)}`, axiosConfig)
        .then(result => {
          console.log('Img-usuario::', result.data.data.imagenUsuario)
          setFrontImage(result.data.data.imagenUsuario)
        })

    } catch (error) {
      console.log('error', error)
    }
  };

  return (
    <div className="subirimagen">
      <div className="subirimagen_img">
        <img src={frontImage} />
      </div>
      <div className="subirimagen_buttons">
        <div className="subirimagen_buttons_select">
          <p>{fileName}</p>
          <input type="file" onChange={saveFile} />
        </div>
        <div className="subirimagen_buttons_btn">
          <input type="button" value={props.msgAction} onClick={uploadFile} />
          {errorImage && <div className="mensajeError">{errorImageMsg}</div>}
          {confirmationImage && <div className="mensajeError verde">Tu imagen se ha actualizado satisfactoriamente</div>}
        </div>
      </div>
    </div>
  )
}