import React, { Children, Component } from 'react';
import Slider from "react-slick";
import './estilos.css';

export const SliderHome = (props) => {

  const settings = {
    autoplay: true,
    autoplaySpeed: 5000,
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    variableWidth: false,
    className: 'slider-principal'
  };

  return (
    <Slider {...settings}>
      {props.children}
    </Slider>
  )
}