import React, { Fragment, useState, useEffect } from 'react'
import axios from 'axios';
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";

export const DesactivarCuentaPermanente = (props) => {
    const history = useHistory();
    const [textoInfo, setTextoInfo] = useState(false)
    let claveUsuario = window.location.search.split('code=')[1]

    const eliminarCuenta = async () => {
        let dataForm = {
            "claveUsuario": claveUsuario,
            "permanente": "true"
        }

        let axiosConfig = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-token`)}`
            },
        };
        axios.post(`${process.env.REACT_APP_URL_API}usuarios/DesactivarUsuario`, dataForm, axiosConfig)
            .then((res) => {
                console.log(res)
                localStorage.removeItem(`${process.env.REACT_APP_ID_STORAGE}-loginTime`);
                localStorage.removeItem(`${process.env.REACT_APP_ID_STORAGE}-limitTime`);
                localStorage.removeItem(`${process.env.REACT_APP_ID_STORAGE}-idu`);
                localStorage.removeItem(`${process.env.REACT_APP_ID_STORAGE}-nombre`);
                localStorage.removeItem(`${process.env.REACT_APP_ID_STORAGE}-token`);
                localStorage.removeItem(`${process.env.REACT_APP_ID_STORAGE}-id`);
                localStorage.removeItem(`${process.env.REACT_APP_ID_STORAGE}-email`);
                history.push('/')
                window.location.reload()
                return (console.log("cuenta borrada"))
            })
            .catch((err) => {
                localStorage.removeItem(`${process.env.REACT_APP_ID_STORAGE}-loginTime`);
                localStorage.removeItem(`${process.env.REACT_APP_ID_STORAGE}-limitTime`);
                localStorage.removeItem(`${process.env.REACT_APP_ID_STORAGE}-idu`);
                localStorage.removeItem(`${process.env.REACT_APP_ID_STORAGE}-nombre`);
                localStorage.removeItem(`${process.env.REACT_APP_ID_STORAGE}-token`);
                localStorage.removeItem(`${process.env.REACT_APP_ID_STORAGE}-id`);
                localStorage.removeItem(`${process.env.REACT_APP_ID_STORAGE}-email`);
                history.push('/')
                window.location.reload()
                setTextoInfo('No se ha podido eliminar la cuenta')
                console.log(err)
                return (console.log("error al borrar cuenta"))
            })
    }
    useEffect(() => {
        eliminarCuenta();
    }, [])

    if (!textoInfo) { return (<div></div>) }
    return (
        <div></div>
    )

}