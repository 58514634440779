import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './estilos.css';

export const MisInscripciones = (props) => {
    console.log('props::',props)
    const [inscripcionesUsuario, setInscripcionesUsuario] = useState(props.item.eventos);
    const [inscripcionesPasadas, setInscripcionesPasadas] = useState([]);
    const [inscripcionesFuturas, setInscripcionesFuturas] = useState([]);

    function limpiarFecha(fecha) {
        let year = fecha.split("-")[0];
        let month = fecha.split("-")[1];
        if (month.substr(0, 1) == "0") {
            month = month.substr(1);
        }
        month = month - 1;
        let day = fecha.split("-")[2].split("T")[0];
        let monthNames = ["Ene.", "Feb.", "Mar.", "Abr.", "May.", "Jun.", "Jul.", "Ago.", "Sep.", "Oct.", "Nov.", "Dic."];
        let monthName = monthNames[month];

        if (day < 10) {
            day = day.split('0')[1]
        }
        return (day + " " + monthName + " " + year)
    }

    function separarEventos(eventos) {
        let eventosFuturos = [];
        let eventosPasados = [];

        eventos.map(evento => {
            let dateToday = Date.parse(new Date());
            let eventDate = Date.parse(evento.fechaEvento);
            if (eventDate < dateToday) {
                eventosPasados.push(evento)
            }
            else {
                eventosFuturos.push(evento)
            }
        })
        eventosFuturos = eventosFuturos.sort((a, b) => (a.fechaEvento < b.fechaEvento) ? 1 : -1);
        eventosPasados = eventosPasados.sort((a, b) => (a.fechaEvento < b.fechaEvento) ? 1 : -1);
        setInscripcionesFuturas(eventosFuturos)
        setInscripcionesPasadas(eventosPasados)
    }
    useEffect(() => {
        separarEventos(inscripcionesUsuario);
    }, [inscripcionesUsuario])

    const [mostrarBuscador1, setMostrarBuscador1] = useState("none");
    const [mostrarBuscador2, setMostrarBuscador2] = useState("none");
    function toggleBuscador1() {
        if (mostrarBuscador1 == "none") {
            setMostrarBuscador1("flex")
        }
        else {
            setMostrarBuscador1("none")
        }
    }
    function toggleBuscador2() {
        if (mostrarBuscador2 == "none") {
            setMostrarBuscador2("flex")
        }
        else {
            setMostrarBuscador2("none")
        }
    }

    const [titleBuscador1, setTitleBuscador1] = useState("Orden Personalizado");
    const [titleBuscador2, setTitleBuscador2] = useState("Orden Personalizado");
    const [valorSelected1, setValorSelected1] = useState("Fecha de celebración");
    const [valorSelected2, setValorSelected2] = useState("Fecha de celebración");
    const itemsBuscador1 = ["Fecha de celebración", "Tipo de evento", "Temática", "Ciudad", "Título"];
    const itemsBuscador2 = ["Fecha de celebración", "Tipo de evento", "Temática", "Ciudad", "Título"];
    function selctItemBuscador1(item) {
        let ordenEventos = [];
        itemsBuscador1.map(i => {
            if (item === i) {
                setValorSelected1(item)
                setTitleBuscador1(item)
            }
            if (item === "Fecha de celebración") {
                ordenEventos = inscripcionesFuturas.sort((a, b) => (a.fechaEvento < b.fechaEvento) ? 1 : -1);
                setInscripcionesFuturas(ordenEventos)
            }
            if (item === "Tipo de evento") {
                ordenEventos = inscripcionesFuturas.sort((a, b) => (a.tipoEventoNombre > b.tipoEventoNombre) ? 1 : -1);
                setInscripcionesFuturas(ordenEventos)
            }
            if (item === "Temática") {
                ordenEventos = inscripcionesFuturas.sort((a, b) => (a.categorias > b.categorias) ? 1 : -1);
                setInscripcionesFuturas(ordenEventos)
            }
            if (item === "Ciudad") {
                ordenEventos = inscripcionesFuturas.sort((a, b) => (a.lugarEvento > b.lugarEvento) ? 1 : -1);
                setInscripcionesFuturas(ordenEventos)
            }
            if (item === "Título") {
                ordenEventos = inscripcionesFuturas.sort((a, b) => (a.nombre > b.nombre) ? 1 : -1);
                setInscripcionesFuturas(ordenEventos)
            }
        })
        setMostrarBuscador1("none")
    }
    function selctItemBuscador2(item) {
        let ordenEventos = [];
        itemsBuscador2.map(i => {
            if (item === i) {
                setValorSelected2(item)
                setTitleBuscador2(item)
            }
            if (item === "Fecha de celebración") {
                ordenEventos = inscripcionesPasadas.sort((a, b) => (a.fechaEvento < b.fechaEvento) ? 1 : -1);
                setInscripcionesPasadas(ordenEventos)
            }
            if (item === "Tipo de evento") {
                ordenEventos = inscripcionesPasadas.sort((a, b) => (a.tipoEventoNombre > b.tipoEventoNombre) ? 1 : -1);
                setInscripcionesPasadas(ordenEventos)
            }
            if (item === "Temática") {
                ordenEventos = inscripcionesPasadas.sort((a, b) => (a.categorias > b.categorias) ? 1 : -1);
                setInscripcionesPasadas(ordenEventos)
            }
            if (item === "Ciudad") {
                ordenEventos = inscripcionesPasadas.sort((a, b) => (a.lugarEvento > b.lugarEvento) ? 1 : -1);
                setInscripcionesPasadas(ordenEventos)
            }
            if (item === "Título") {
                ordenEventos = inscripcionesPasadas.sort((a, b) => (a.nombre > b.nombre) ? 1 : -1);
                setInscripcionesPasadas(ordenEventos)
            }
        })
        setMostrarBuscador2("none")
    }

    const [numeroPasados, setNumeroPasados] = useState(5);
    const [numeroFuturos, setNumeroFuturos] = useState(5);
    function cargarMasPasados() {
        setNumeroPasados(numeroPasados + 5)
    }
    function cargarMasFuturos() {
        setNumeroFuturos(numeroFuturos + 5)
    }

    return (
        <div class="section bg-gradientDark"><div class="container">
        <div className="misInscripciones">
            {
                (inscripcionesPasadas.length <= 0 && inscripcionesFuturas.length <= 0) ?
                    <p>Todavia no te has inscrito a ningún evento</p>
                    :
                    null
            }
            {
                inscripcionesFuturas.length <= 0 ?
                    null
                    :
                    <div className="misInscripciones_content">
                        <div className="misInscripciones_header">
                            <h1>Mis Inscripciones</h1>
                            <div className="misInscripciones_buscador">
                                <div className="buscador_default" onClick={() => toggleBuscador1()}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                        <path id="_Color" data-name=" ↳Color" d="M16.467,18h0l-5.146-5.134v-.813l-.278-.288a6.7,6.7,0,1,1,.721-.721l.288.278h.813L18,16.467,16.468,18ZM6.689,2.058a4.631,4.631,0,1,0,4.631,4.631A4.637,4.637,0,0,0,6.689,2.058Z" />
                                    </svg>
                                    <p>{titleBuscador1}</p>
                                    <svg id="Desplegable_más_" data-name="Desplegable &quot;más&quot;" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                                        <rect id="Boundary" width="28" height="28" transform="translate(28) rotate(90)" fill="none" />
                                        <path id="_Color" data-name=" ↳Color" d="M1.633,0,0,1.635,5.3,6.959,0,12.282l1.633,1.635L8.582,6.959Z" transform="translate(21.041 9.974) rotate(90)" />
                                    </svg>
                                </div>
                                <div className="buscador_deplegable" style={{ display: mostrarBuscador1 }}>
                                    {
                                        itemsBuscador1.map(item =>
                                            (valorSelected1 === item) ?
                                                <p onClick={() => selctItemBuscador1(item)} className="selected">{item}</p>
                                                :
                                                <p onClick={() => selctItemBuscador1(item)}>{item}</p>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <table>
                            <tr className="misInscripciones_head">
                                <th>Fecha/Hora</th>
                                <th>Tipo/Temática</th>
                                <th>Ciudad</th>
                                <th>Titulo</th>
                                <th></th>
                            </tr>

                            {
                                inscripcionesFuturas.slice(0, numeroFuturos).map(item =>
                                    <tr>
                                        <td className="misInscripciones_fecha">{limpiarFecha(item.fechaEvento)}<span>{item.horaInicio} a {item.horaFin}</span></td>
                                        <td className="misInscripciones_tipo">{item.tipoEventoNombre}<span>{item.categorias}</span></td>
                                        <td>{item.lugarEvento}</td>
                                        <td>{item.nombre}</td>
                                        <td><a href={item.enlace}>Ir al contenido</a></td>
                                    </tr>
                                )
                            }
                        </table>
                        {
                            inscripcionesFuturas.length > numeroFuturos ?
                                <div className="cargarMasEventos">
                                    <button onClick={() => cargarMasFuturos()}>Cargar Más</button>
                                </div>
                                : null
                        }
                    </div>
            }
            {
                inscripcionesPasadas.length <= 0 ?
                    null
                    :
                    <div className="misInscripciones_content">
                        <div className="misInscripciones_header">
                            <h1>Mis Inscripciones pasadas</h1>
                            <div className="misInscripciones_buscador">
                                <div className="buscador_default" onClick={() => toggleBuscador2()}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                        <path id="_Color" data-name=" ↳Color" d="M16.467,18h0l-5.146-5.134v-.813l-.278-.288a6.7,6.7,0,1,1,.721-.721l.288.278h.813L18,16.467,16.468,18ZM6.689,2.058a4.631,4.631,0,1,0,4.631,4.631A4.637,4.637,0,0,0,6.689,2.058Z" />
                                    </svg>
                                    <p>{titleBuscador2}</p>
                                    <svg id="Desplegable_más_" data-name="Desplegable &quot;más&quot;" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                                        <rect id="Boundary" width="28" height="28" transform="translate(28) rotate(90)" fill="none" />
                                        <path id="_Color" data-name=" ↳Color" d="M1.633,0,0,1.635,5.3,6.959,0,12.282l1.633,1.635L8.582,6.959Z" transform="translate(21.041 9.974) rotate(90)" />
                                    </svg>
                                </div>
                                <div className="buscador_deplegable" style={{ display: mostrarBuscador2 }}>
                                    {
                                        itemsBuscador2.map(item =>
                                            (valorSelected2 === item) ?
                                                <p onClick={() => selctItemBuscador2(item)} className="selected">{item}</p>
                                                :
                                                <p onClick={() => selctItemBuscador2(item)}>{item}</p>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <table>
                            <tr className="misInscripciones_head">
                                <th>Fecha/Hora</th>
                                <th>Tipo/Temática</th>
                                <th>Ciudad</th>
                                <th>Titulo</th>
                                <th></th>
                            </tr>
                            {
                                inscripcionesPasadas.slice(0, numeroPasados).map(item =>
                                    <tr>
                                        <td className="misInscripciones_fecha fechaPasada">{limpiarFecha(item.fechaEvento)}<span>{item.horaInicio} a {item.horaFin}</span></td>
                                        <td className="misInscripciones_tipo">{item.tipoEventoNombre}<span>{item.categorias}</span></td>
                                        <td>{item.lugarEvento}</td>
                                        <td>{item.nombre}</td>
                                        <td><a href={item.enlace}>Ir al contenido</a></td>
                                    </tr>
                                )
                            }
                        </table>
                        {
                            inscripcionesPasadas.length > numeroPasados ?
                                <div className="cargarMasEventos">
                                    <button onClick={() => cargarMasPasados()}>Cargar Más</button>
                                </div>
                                : null
                        }
                    </div>
            }
        </div></div></div>
    )
}