import React, { Fragment, useState, useEffect } from 'react'
import axios from 'axios';
import Swal from 'sweetalert2'
import { Botonbase } from "../../botonbase";
import { ModalCnae } from "../../modalCnae";
import { SelectorInput } from "../../selectorInput";
import './estilos.css';

export const ContenidoDatosProfesionales = (props) => {

    const [idCliente, setIdCliente] = useState(null);
    const [claveCliente, setClaveCliente] = useState(null);

    const [empresaError, setEmpresaError] = useState(false);
    const [empresaData, setEmpresaData] = useState('');
    const empresaChange = (val) => {
        setEmpresaData(val.target.value)
        setEmpresaError(false)
    }

    const [dniError, setDniError] = useState(false);
    const [dniData, setDniData] = useState('');
    const dniChange = (val) => {
        setDniData(val.target.value)
        setDniError(false)

        let dataFormDNI = {
            "Cif": val.target.value
        }

        let axiosConfig = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        };
        axios.post(`${process.env.REACT_APP_URL_API}plataformas/ObtenerClientePorCIF`, dataFormDNI, axiosConfig)
            .then((res) => {
                //console.log("usuario:: ObtenerClientePorCIF`", res.data.data)
                setEmpresaData(res.data.data.nombre)
                setCnaeCode(res.data.data.cnae)
                setFacturacionData(res.data.data.facturacion)
                setEmpleadosData(res.data.data.empleados)
                setIdCliente(res.data.data.idCliente)
                setClaveCliente(res.data.data.claveCliente)
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const [cnaeError, setCnaeError] = useState(false);
    const [cnaeCode, setCnaeCode] = useState("");
    const [cnaeDescription, setCnaeDescription] = useState("");
    const [showCnaeModal, setshowCnaeModal] = useState("none");
    const openModalCnae = () => {
        setshowCnaeModal("block")
    }
    const pickCnae = (cnae, cnaeD) => {
        setCnaeCode(cnae);
        setCnaeDescription(cnaeD);
        setshowCnaeModal("none");
    }

    const [empleadosError, setEmpleadosError] = useState(false);
    const [empleadosData, setEmpleadosData] = useState('- Elige una opción -');

    const [facturacionError, setFacturacionError] = useState(false);
    const [facturacionData, setFacturacionData] = useState('- Elige una opción -');

    const fetchSettings = async () => {
        try {
            let axiosConfig = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-token`)}`
                },
            };
            await axios.get(`${process.env.REACT_APP_URL_API}usuarios/${localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-idu`)}`, axiosConfig)
                .then(result => {
                    //console.log('usuario:: DatosUsuario', result.data.data)
                    result.data.data.idCliente !== null ? setIdCliente(result.data.data.idCliente) : setIdCliente('')
                    result.data.data.clienteAsociado.nombre !== null ? setEmpresaData(result.data.data.clienteAsociado.nombre) : setEmpresaData('')
                    result.data.data.clienteAsociado.cif !== null ? setDniData(result.data.data.clienteAsociado.cif) : setDniData('')
                    result.data.data.clienteAsociado.claveCliente !== null ? setClaveCliente(result.data.data.clienteAsociado.claveCliente) : setClaveCliente('')
                    result.data.data.clienteAsociado.cnae !== null ? setCnaeCode(result.data.data.clienteAsociado.cnae)  : setCnaeCode('')
                    result.data.data.clienteAsociado.empleados !== null ? setEmpleadosData(result.data.data.clienteAsociado.empleados) : setEmpleadosData('')
                    result.data.data.clienteAsociado.facturacion !== null ? setFacturacionData(result.data.data.clienteAsociado.facturacion) : setFacturacionData('')
                })
        } catch (error) {
            console.log('error', error)
        }
    };
    useEffect(() => {
        fetchSettings();
    }, []);

    const updateCliente = async () => {
        let formHasError = false;
        console.log('entra1',empresaData)
        if (empresaData.length == 0) {
            formHasError = true
            setEmpresaError(true)
        } else {
            setEmpresaError(false)
        }
        console.log('entra 2')
        if (dniData.length != 9) {
            formHasError = true
            setDniError(true)
        } else {
            setDniError(false)
        }
        console.log('entra 3')
        if (cnaeCode != null && cnaeCode.length == 0) {
            //formHasError = true
            //setCnaeError(true)
            setCnaeError(false) //ahora no es obligatorio
        } else {
            setCnaeError(false)
        }
        console.log('entra 4')
        if (empleadosData === '- Elige una opción -') {
            //formHasError = true
            //setEmpleadosError(true)
            setEmpleadosError(false) //ahora no es obligatorio
        } else {
            setEmpleadosError(false)
        }
        console.log('entra 5')
        if (facturacionData === '- Elige una opción -') {
           // formHasError = true
           // setFacturacionError(true)
           setFacturacionError(false) //ahora no es obligatorio
        } else {
            setFacturacionError(false)
        }
        console.log('entra 6')
        if (formHasError) return
        let axiosConfig = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-token`)}`
            },
        };
        let dataForm = {
            "idUsuario": localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-idu`),
            "idCliente": idCliente,
            "nombre": empresaData,
            "cif": dniData,
            "claveCliente": claveCliente,
            "cnae": cnaeCode,
            "descripcionCnae": cnaeDescription,
            "Facturacion": facturacionData,
            "Empleados": empleadosData,
        };
       //console.log('usuario:: PUT Clientes',dataForm)
        //console.log('usuario:: PUT Clientes',axiosConfig)
        await axios.put(`${process.env.REACT_APP_URL_API}Clientes`, dataForm, axiosConfig)
            .then((res) => {
                console.log("res: ", res);
                Swal.fire({
                    title: '',
                    text: `Datos Profesionales Actualizados`,
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#42A72C',
                    confirmButtonText: 'Aceptar',
                })
            })
            .catch((err) => {
                console.log("err: ", err);
            })
    }

    return (
        <div className="contenido_datosusuario">
            <div className="contenido_datosusuario_form">
                <div className="titulo">Datos Empresa</div>
                <div className="formulario-cuenta">
                    <div className="form-group">
                        <label className="small">CIF</label>
                        <input className={dniError ? "inputText hasError" : "inputText"} type="text" onChange={dniChange} value={dniData} ></input>
                        {dniError && <div className="mensajeError">El CIF debe ser un texto alfanumérico de 9 caracteres, sin guiones válido</div>}
                    </div>
                    <div className="form-group">
                        <label className="small">Nombre fiscal de empresa</label>
                        <input className={empresaError ? "inputText hasError" : "inputText"} type="text" onChange={empresaChange} value={empresaData}></input>
                        {empresaError && <div className="mensajeError">Introduce una empresa</div>}
                    </div>
                    <div className="form-group col-1 step3_col_ancho">
                        <label className="small">Nº de empleados</label>
                        <SelectorInput
                            options={["de 1 a 10", "de 11 a 50", "de 51 a 100", "más de 100"]}
                            id="empleadosToUpdate"
                            selectedValue={empleadosData}
                            setSelectedValue={setEmpleadosData}
                            error={empleadosError}
                        />
                        {empleadosError && <div className="mensajeError2">Elige una opción</div>}
                    </div>
                    <div className="form-group col-1 step3_col_ancho">
                        <label className="small">Facturación</label>
                        <SelectorInput
                            options={["De 0 a 1.5 Millones de Euros", "De 1.5 a 2.5 Millones de Euros", "De 2.5 a 5 Millones de Euros", "De 5 a 25 Millones de Euros", "Más de 50 Millones de Euros"]}
                            id="facturacionToUpdate"
                            selectedValue={facturacionData}
                            setSelectedValue={setFacturacionData}
                            error={facturacionError}
                        />
                        {facturacionError && <div className="mensajeError2">Elige una opción</div>}
                    </div>
                    <div className="form-group col-1 step3_col_ancho">
                        <label className="small">CNAE</label>
                        <p>Si no conoces tu CNAE, puedes usar nuestro buscador de actividades para localizarlo. Para activarlo pincha en la lupa</p>
                        <div className="step3_selector">
                            <div className="step3_selector_input">
                                <input className={cnaeError ? "inputText hasError" : "inputText"} type="text" value={cnaeCode} onClick={() => openModalCnae()}></input>
                                {cnaeError && <div className="mensajeError">Debes rellenar el CNAE</div>}
                            </div>
                            <div className="step3_selector_icon" onClick={() => openModalCnae()}>
                                <span class="material-icons-outlined">search</span>
                            </div>
                        </div>
                        <ModalCnae show={showCnaeModal} pickCnaeFunction={pickCnae} setshowCnaeModal={setshowCnaeModal} />
                    </div>

                    <button className="DatosProfesionales_btn" onClick={() => updateCliente()} >Guardar los cambios</button>
                </div>
            </div>
        </div>
    )
}