import React, { Component, useContext } from 'react';
import ReactHtmlParser from 'react-html-parser';
import Context from '../../Context';

import './estilos.css';

export const BannerPrincipal = (props) => {
  console.log('props banner::',props)
  return (
    <Context.Consumer>
      {({ modalRegistro, toggleModalRegisitro }) =>
        <div className={props.item.titulo !== null && props.item.titulo.toLowerCase() == 'programa' ?'bannerPrincipal tipo3' : 'bannerPrincipal'} >
          <div className="bannerPrincipal_background">
            <img src={props.item.imagen} />
            {
              !props.isAuth && props.item.id === 600 ?
                <div className="homepublica_repuesto_btn" onClick={() => toggleModalRegisitro()}>Crea tu cuenta gratis</div>
                : null
            }
          </div>
          <div class="bannerPrincipal_container">
            { 
              props.item.enlace !== null ?
              <a href={props.item.enlace} target={props.item.target}>
                {ReactHtmlParser(props.item.texto, {
                  transform: (node) => {
                    if (node.attribs && node.attribs.attrfuncion) {
                      return (
                        <div class={node.attribs.class} onClick={(e) => { e.preventDefault(); toggleModalRegisitro() }}>{node.children[0].data}</div>
                      )
                    }
                  }
                })}

              </a>
            : ReactHtmlParser(props.item.texto, {
              transform: (node) => {
                if (node.attribs && node.attribs.attrfuncion) {
                  return (
                    <div class={node.attribs.class} onClick={(e) => { e.preventDefault(); toggleModalRegisitro() }}>{node.children[0].data}</div>
                  )
                }
              }
            })}
             
          </div>
        </div>
      }
    </Context.Consumer>
  )
}