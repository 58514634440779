import React, { useState, useRef, Fragment } from 'react';
import axios from 'axios';
import './estilos.css';
import Context from '../../Context';
import { ModalTerminos } from "../modalTerminos";
import { ModalTerminosUsuarioRegistrado } from "../modalTerminosUsuarioRegistrado";
import { ModalRegistroPresencial } from "../modalRegistroPresencial";
export const RegistroOnline = (props) => {
    console.log('carga modal::',props)
    const terminosRef = useRef();
    const [errorAviso, setErrorAviso] = useState(false)
    const [mostrarModal, setmostrarModal] = useState(false)
    const [textoLegal, setTextoLegal] = useState('')
    const [showTextoLegal, setShowTextoLegal] = useState("none")
    const [mostrarModalTerminosU, setMostrarModalTerminosU] = useState(false)


    const HandleClick = () => {
        let formHasError = false
        console.log('clickregisto')
        setMostrarModalTerminosU(true)
        /* if (!terminosRef.current.checked) {
            formHasError = true
            setErrorAviso(true)
        } else {
            setErrorAviso(false)
        }
        !formHasError && inscribirseEventoPresencial()
        */
    }

    const inscribirseEventoPresencial = () => {
        let dataForm = {
            "claveUsuario": localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-id`),
            "idEvento": props.idEvento,
            "codInvitacion": localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-codeInvitacion`),
        }
        let axiosConfig = {
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-token`)}`
            },
        };
       
    
        axios.post(`${process.env.REACT_APP_URL_API}Eventos/InscribirEvento`, dataForm, axiosConfig)
            .then((res) => {
                if (localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-codeInvitacion`)) {
                    props.logEvento(props.idEvento, false, "inscritoPorInvitacion", localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-codeInvitacion`))
                    localStorage.removeItem(`${process.env.REACT_APP_ID_STORAGE}-codeInvitacion`);
                }
                console.log("respuesta: ", res); 
                if(!props.dataEvento.noMostrarEmailNiFecha){
                    setmostrarModal(true)
                    console.log("respuesta: muestramodal"); 
                    setMostrarModalTerminosU(false)
                    console.log("respuesta: oculta terminos"); 
                }else{
                    window.location.reload()
                }
                //props.setInscrito(true);
            
            })
            .catch((err) => {
                console.log(err.response.data.data)
                if(err.response.data.data == 'Se ha asociado el usuario al evento.'){
                    window.location.reload()
                }
                console.log(err.response.status)
               
            })
            
    }
    const openTextoLegal = () => {
        fetchTextoLegal();
        setShowTextoLegal("flex")
    }

    const fetchTextoLegal = async () => {
        let dataForm = {
            "idEvento": props.idEvento,
            "idPlataforma": "1",
        }

        let axiosConfig = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        };
        axios.post(`${process.env.REACT_APP_URL_API}formatos/ObtenerTextoLegal`, dataForm, axiosConfig)
            .then((res) => {
                setTextoLegal(res.data.data);
            })
            .catch((err) => {

            })
    }

    return (
        <Context.Consumer>{({ modalRegistro, toggleModalRegisitro, modalLogin, toggleModalLogin, activateAuth, isAut, updateEmailLogin, emailLogin }) => <>
            <modalLogin updateEmailLogin={updateEmailLogin} emailLogin={emailLogin}  mostrarModal={modalLogin} toggleModalLogin={toggleModalLogin} toggleModalRegisitro={toggleModalRegisitro} activateAuth={activateAuth} />
            <ModalTerminosUsuarioRegistrado inscribirseEventoPresencial={inscribirseEventoPresencial} setShowTextoLegal={openTextoLegal}  mostrarModalTerminosU={mostrarModalTerminosU}  closeModal={setMostrarModalTerminosU} idEvento={props.idEvento} dataEvento={props.dataEvento} />
            <ModalRegistroPresencial mostrarModal={mostrarModal} setmostrarModal={setmostrarModal} />
            <div className="registro-presencial">
                <ModalTerminos textoLegal={textoLegal} show={showTextoLegal} setShowTextoLegal={setShowTextoLegal} />
                {
                    !props.inscrito ?
                        <form onSubmit={(e) => { HandleClick(); e.preventDefault(); }} autocomplete="off">
                            <button className="btn principal btn_inscribirse" type="submit" >Quiero inscribirme</button>
                           {/*  <div className="form-aviso">
                                <input type="checkbox" className={errorAviso ? "hasErrorCheck" : ""} ref={terminosRef} />
                                <label className="small"><span className="form-aviso_span_acepto">Acepto</span> el uso de mis datos facilitados para inscribirme a este evento, tanto por el Organizador como por el/los Patrocinador/es, en caso de existir, para la recepción de newsletter, promociones, remisión de invitaciones, comunicaciones comerciales (incluso por medios electrónicos), conforme se establece en los <span className="form-aviso_span" onClick={() => openTextoLegal()}>términos legales y sobre tratamiento de datos.</span></label>
                                {errorAviso && <div className="mensajeError">Debes aceptar los términos legales</div>}
                            </div>
                            */}
                        </form>
                        :
                        null
                }
            </div>
        </>}
        </Context.Consumer>
    )
}