import React, { Fragment, useState, useEffect, useContext } from 'react'
import axios from 'axios';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import Slider from "react-slick";
import { RiArrowDropRightFill } from "react-icons/ri";
import { Header } from "../../componentes/header";
import { Helmet } from "react-helmet";
import {SliderHome} from "../../componentes/sliderHome";
import { BannerPrincipal } from "../../componentes/bannerPrincipal";
import { CargaContenido } from "../../componentes/cargaContenido";
import { SliderItems } from "../../componentes/sliderItems";
import { SliderCanales } from "../../componentes/sliderCanales";
import { GridEventosPresenciales } from "../../componentes/gridEventosPresenciales";
import { CajaItemEventoPresencial } from "../../componentes/cajaItemEventoPresencial";
import { CajaTexto } from "../../componentes/cajaTexto";
import { CajaInfo } from "../../componentes/cajaInfo";
import { CajaItem } from "../../componentes/cajaItem";
import { Footer } from "../../componentes/footer";
import { Botonbase } from "../../componentes/botonbase";
import { ScrollToTop } from '../../componentes/scrollToTop';

export const HomeEventos = (props) => {

  const handleClick = () => {
    console.log('click btn 2')
  }

  const settings = {
    dots: true,
    className: "slider-eventos",
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    infinite: false,
    centerMode: false,
    centerPadding: "0",
  };

  // const [listaEventos, setListaEventos] = useState(false)
  // const fetchListaEventos = async () => {
  //   let dataFormEventos = {
  //     "formato": "home_publica",

  //   }

  //   let axiosConfig = {
  //     headers: {
  //       'Accept': 'application/json',
  //       'Content-Type': 'application/json',

  //     },
  //   };
  //   axios.post(`${process.env.REACT_APP_URL_API}formatos/obtenerformatopublico`, dataFormEventos, axiosConfig)
  //     .then((res) => {

  //       console.log('FOTMATO PUBLICO::', res.data.data)
  //       setListaEventos(res.data.data)
  //       console.log('DATA HOME::', res.data.data)

  //     })
  //     .catch((err) => {
  //       console.log('ERROR:', err)
  //       // props.removeAuth()
  //     })
  // }

  // useEffect(() => {
  //   fetchListaEventos();
  // }, [])

  // if (!listaEventos) { return (<div></div>) }


  return (
    <>
      {
        props.seoContenido !== null &&
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content={props.seoContenido.seoDescription} />
          <title>{props.seoContenido.seoTitle}</title>
          <meta property="og:title" content={props.seoContenido.seoTitle} />
          <meta property="og:description" content={props.seoContenido.seoDescription} />
          <meta property="og:locale" content="es_ES" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:site_name" content="Business+"></meta>
          <meta property="article:modified_time" content={props.seoContenido.fechaModificacion}></meta>
          <meta property="og:image" content={props.seoContenido.seoImage} />
          <meta property="og:image:width" content={props.seoContenido.seoImageWidth} />
          <meta property="og:image:height" content={props.seoContenido.seoImageHeight} />
          <meta name="twitter:title" content={props.seoContenido.seoTitle} />
          <meta name="twitter:description" content={props.seoContenido.seoDescription} />
          <meta name="twitter:image" content={props.seoContenido.seoImage} />
        </Helmet>
      }

      <Header logo={props.zonasContenido[0].texto} data={props.zonasContenido} />
      <div className="bannerInterior"><SliderHome>{
      
      props.zonasContenido.map(item =>
        item.idZona === 3 &&
            <CargaContenido item={item} tipo={item.tipoZona} esHome={true} valoreExtra={props.valoreExtra} />
      )
    
    }</SliderHome></div>



      <div className="section bg-gradientDarkFlip">
        <div className="homeeventos_content">
          <h2>Eventos Business<span>+</span></h2>
          <div className="grid_homeeventos">
            {
              props.zonasContenido.map(item =>
                item.idZona === 4 && item.tipoZona == 'imagen' &&
                <CajaItemEventoPresencial imagen={item.imagen} enlace={item.enlace} />
              )
            }
          </div>
        </div>
      </div>



      <Footer logo={props.zonasContenido[0].texto} data={props.zonasContenido} />
      <ScrollToTop />
    </>

  )

}