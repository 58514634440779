import React, { Fragment, useState, useEffect, useContext } from 'react'
import axios from 'axios';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Redirect } from 'react-router'
import { RiArrowDropRightFill } from "react-icons/ri";
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Helmet } from "react-helmet";
import { CargaContenido } from "../../componentes/cargaContenido";
import { Header } from "../../componentes/header";
import { SliderItems } from "../../componentes/sliderItems";
import { CajaItem } from "../../componentes/cajaItem"
import { Footer } from "../../componentes/footer";
import { ScrollToTop } from '../../componentes/scrollToTop';

export const Mybusiness = (props) => {

    
  if (!props.isAuth) { return (<Redirect to='/'/>) }
  return (
    <>
      {
        props.seoContenido !== null &&
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content={props.seoContenido.seoDescription} />
          <title>{props.seoContenido.seoTitle}</title>
          <meta property="og:title" content={props.seoContenido.seoTitle} />
          <meta property="og:description" content={props.seoContenido.seoDescription} />
          <meta property="og:locale" content="es_ES" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:site_name" content="Business+"></meta>
          <meta property="article:modified_time" content={props.seoContenido.fechaModificacion}></meta>
          <meta property="og:image" content={props.seoContenido.seoImage} />
          <meta property="og:image:width" content={props.seoContenido.seoImageWidth} />
          <meta property="og:image:height" content={props.seoContenido.seoImageHeight} />
          <meta name="twitter:title" content={props.seoContenido.seoTitle} />
          <meta name="twitter:description" content={props.seoContenido.seoDescription} />
          <meta name="twitter:image" content={props.seoContenido.seoImage} />
        </Helmet>
      }
      <Header logo={props.zonasContenido[0].texto} data={props.zonasContenido} />
      <div className="section bg-gradientDark">
        <div className="container">
          <div className="margin-tb-30">
            {

              props.zonasContenido.map(item =>
                <Fragment>
                  {item.idZona === 3 &&
                    <CargaContenido item={item} tipo={item.tipoZona} isAuth={props.isAuth} />
                  }
                </Fragment>
              )

            }

          </div>
        </div>
      </div>
      {

        props.zonasContenido.map(item =>
          <Fragment>
            {item.idZona === 4 && 
              
                  <CargaContenido item={item} tipo={item.tipoZona} isAuth={props.isAuth} />
             
            }
          </Fragment>
        )
      }

      <Fragment>

        {

          props.zonasContenido.map(item =>
            <Fragment>

              {item.idZona === 5 && 
                <Fragment>
                  <CargaContenido item={item} tipo={item.tipoZona} isAuth={props.isAuth} />
                </Fragment>
              }

            </Fragment>
          )

        }

        {

        props.zonasContenido.map(item =>
        <Fragment>

            {item.idZona === 6 &&
            <Fragment>
                <CargaContenido item={item} tipo={item.tipoZona} isAuth={props.isAuth} />
            </Fragment>
            }

        </Fragment>
        )

        }

      </Fragment>

      <Footer logo={props.zonasContenido[0].texto} data={props.zonasContenido} />
      <ScrollToTop />
    </>

  )

}