import React, { Fragment, useState, useEffect } from 'react'
import axios from 'axios';
import Swal from 'sweetalert2';


import { SubirImagen } from "../subirImagen"
import { Botonbase } from "../../botonbase";
import './estilos.css';

export const ContenidoCuentaUsuario = (props) => {

  const [errorinform, setErrorinform] = useState(false);

  let regexEmail = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;

  const [errorEmail, setErrorEmail] = useState(false);
  const [emailData, setEmailData] = useState("");
  const emailChange = (val) => {
    setEmailData(val.target.value)
    setErrorEmail(false)
  }

  const [errorNombre, setErrorNombre] = useState(false);
  const [nombreData, setNombreData] = useState("");
  const nombreChange = (val) => {
    setNombreData(val.target.value)
    setErrorNombre(false)
  }

  const [errorApellidos, setErrorApellidos] = useState(false);
  const [apellidosData, setApellidosData] = useState("");
  const apellidosChange = (val) => {
    setApellidosData(val.target.value)
    setErrorApellidos(false)
  }

  const [cpError, setCpError] = useState(false);
  const [cpData, setCpData] = useState("");
  const cpChange = (val) => {
    setCpData(val.target.value)
    setCpError(false)
  }

  const [telefonoError, setTelefonoError] = useState(false);
  const [telefonoData, setTelefonoData] = useState("");
  const telefonoChange = (val) => {
    setTelefonoData(val.target.value)
    setTelefonoError(false)
  }
  const [movilError, setMovilError] = useState(false)
  const [movilData, setMovilData] = useState('');
  const movilChange = (val) => {
    setMovilData(val.target.value)
    setMovilError(false)
  }

  const [errorCargo, setErrorCargo] = useState(false);
  const [cargoData, setCargoData] = useState("");
  const cargoChange = (val) => {
    setCargoData(val.target.value)
    setErrorCargo(false)
  }
  const updatedatosUsuario = async () => {
    let formHasError = false;

    if (!regexEmail.test(emailData)) {
      setErrorEmail(true)
    }

    if (nombreData.length == 0) {
      formHasError = true
      setErrorNombre(true)
    } else {
      setErrorNombre(false)
    }
 
    if (apellidosData.length == 0) {
      formHasError = true
      setErrorApellidos(true)
    } else {
      setErrorApellidos(false)
    }

    if (cpData.length != 5) {
      formHasError = true
      setCpError(true)
    } else {
      setCpError(false)
    }
 
    if (telefonoData.length <= 7) {
      formHasError = true
      setTelefonoError(true)
    } else {
      setTelefonoError(false)
    }

    if (movilData.length < 9 && movilData.length > 0) {
      formHasError = true
      setMovilError(true)
    } else {
      setMovilError(false)
    }
 
    if (cargoData.length == 0) {
      formHasError = true
      setErrorCargo(true)
    } else {
      setErrorCargo(false)
    }
    
    if (formHasError){
      
      setErrorinform(true)
      return
    }else{
      setErrorinform(false)
    }
   
    try {
      var dataForm = {
        "idUsuario": localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-idu`),
        "nombre": nombreData,
        "apellidos": apellidosData,
        "cp": cpData,
        "telefono": telefonoData,
        "movil": movilData,
        "email": emailData,
        "puesto": cargoData,
        "usuario": localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-nombre`),
      }
      let axiosConfig = {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-token`)}`
        },
      };
      await axios.put(`${process.env.REACT_APP_URL_API}usuarios/InscribirseModif`, dataForm, axiosConfig)
        .then(resLogin => {
          if (resLogin.status === 200) {
            if (resLogin.data === 'error') {
              //messageLoginChange('El formulario contiene errores')
            } else {
              Swal.fire({
                title: '',
                text: `Perfil de usuario actualizado`,
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#42A72C',
                confirmButtonText: 'Aceptar',
              }).then((result) => {
                if (result.value) {

                }
              })
            }
          } else {
            //messageLoginChange('El formulario contiene errores')
          }

        })

    } catch (error) {
      //setloaddata(false);
      //messageLoginChange('El formulario contiene errores') 
      //formIsInvalidChange(true)
    }
  }

  const [datosUsuario, setDatosUsuario] = useState(false);
  const fetchSettings = async () => {
    try {

      let axiosConfig = {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-token`)}`
        },
      };
      await axios.get(`${process.env.REACT_APP_URL_API}usuarios/${localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-idu`)}`, axiosConfig)
        .then(result => {
          console.log('usuario::', result.data.data)
          result.data.data.nombre !== null ? setNombreData(result.data.data.nombre) : setNombreData('')
          result.data.data.apellidos !== null ? setApellidosData(result.data.data.apellidos) : setApellidosData('')
          result.data.data.email !== null ? setEmailData(result.data.data.email) : setEmailData('')
          result.data.data.telefono !== null ? setTelefonoData(result.data.data.telefono) : setTelefonoData('')
          result.data.data.movil !== null ? setMovilData(result.data.data.movil) : setMovilData('')
          result.data.data.cp !== null ? setCpData(result.data.data.cp) : setCpData('')
          result.data.data.data !== null ? setDatosUsuario(result.data.data) : setDatosUsuario('')
          result.data.data.puesto !== null ? setCargoData(result.data.data.puesto) :  setCargoData('')
        })

    } catch (error) {
      console.log('error', error)
    }
  };
  useEffect(() => {
    fetchSettings();
  }, []);

  const HandleClick = () => {
    updatedatosUsuario()
  }

  if (!datosUsuario) return (<div>loading</div>)
  return (
    <div className="contenido_datosusuario">
      <div className="contenido_datosusuario_form">
        <div className="titulo">Datos Usuario</div>
        <div className="formulario-cuenta">
          <div className="form-group">
            <label className="small">Email profesional*</label>
            <input className={errorEmail ? "inputText hasError" : "inputText"} type="email" onChange={emailChange} value={emailData}></input>
            {errorEmail && <div className="mensajeError">Email incorrecto</div>}
          </div>
          <div className="form-group">
            <label className="small">Nombre*</label>
            <input className={errorNombre ? "inputText hasError" : "inputText"} type="text" onChange={nombreChange} value={nombreData}></input>
            {errorNombre && <div className="mensajeError">Introduce un nombre válido</div>}
          </div>
          <div className="form-group">
            <label className="small">Apellidos*</label>
            <input className={errorApellidos ? "inputText hasError" : "inputText"} type="text" onChange={apellidosChange} value={apellidosData}></input>
            {errorApellidos && <div className="mensajeError">Introduce un apellido válido</div>}
          </div>
          <div className="form-group">
            <label className="small">Código postal*</label>
            <input className={cpError ? "inputText hasError" : "inputText"} type="text" onChange={cpChange} value={cpData} ></input>
            {cpError && <div className="mensajeError">Introduce un código postal de 5 dígitos válido</div>}
          </div>
          <div className="form-group">
            <label className="small">Teléfono profesional*</label>
            <input className={telefonoError ? "inputText hasError" : "inputText"} type="text" onChange={telefonoChange} value={telefonoData} ></input>
            {telefonoError && <div className="mensajeError">Introduce un número de teléfono válido</div>}
          </div>
          <div className="form-group">
            <label className="small">Teléfono móvil</label>
            <input className={movilError ? "inputText hasError" : "inputText"} type="text" onChange={movilChange} value={movilData} ></input>
            {movilError && <div className="mensajeError">Introduce un número de teléfono móvil válido</div>}
          </div>
          <div className="form-group">
            <label className="small">Cargo*</label>
            <input className={errorCargo ? "inputText hasError" : "inputText"} type="text" onChange={cargoChange} value={cargoData}></input>
            {errorCargo && <div className="mensajeError">Introduce un cargo</div>}
          </div>
          <Botonbase accion={HandleClick} clase='formulario' texto={'Guardar los cambios'} />
          <div class="leyendaForm">*Campos obligatorios</div>
          {errorinform && <div className="mensajeError">Revisa todos los campos</div>}
        </div>
      </div>
      {
        datosUsuario.imagenUsuario != null ?
          <SubirImagen imagenUsuario={datosUsuario.imagenUsuario} msgAction="Cambiar imagen" />
          :
          <SubirImagen imagenUsuario="https://businessplus.es/assets/imgpruebas/avatar_default.png" msgAction="Cambiar imagen" />
      }
    </div>
  )
}