import React, { Fragment, useState, useEffect, useContext } from 'react'
import axios from 'axios';
import { Header } from "../../../componentes/header";
import { ContenidoDatosProfesionales } from "../../../componentes/usuario/contenidoDatosProfesionales";
import { MenuCuentaUsuario } from "../../../componentes/menuCuentaUsuario"
import { Footer } from "../../../componentes/footer";
import { ScrollToTop } from '../../../componentes/scrollToTop';

export const DatosProfesionales = (props) => {

  const [listaEventos, setListaEventos] = useState(false)
  const fetchListaEventos = async () => {
    let urlamigable = props.isAuth ? urlamigable = '/home-privada/' : urlamigable = '/home-publica/'
        let dataFormEventos = {}
        let axiosConfig = {}
        let url = `${process.env.REACT_APP_URL_API}formatos/UrlAmbigablePublica`
        if (props.isAuth) {
       
            url = `${process.env.REACT_APP_URL_API}formatos/UrlAmbigable`
            dataFormEventos = {
                "urlAmigable": urlamigable,
                "idUsuario": localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-idu`),
                "idPlataforma": 1
            }
            axiosConfig = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-token`)}`
                },
            };
        } else {
         
            dataFormEventos = {
                "urlAmigable": urlamigable,
                "idPlataforma": 1

            }

            axiosConfig = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            };
        }
        

        axios.post(url, dataFormEventos, axiosConfig)
            .then((res) => {
        
                setListaEventos(res.data.data)

            })
            .catch((err) => {
                console.log('DATA HOME:: ERROR:2:', err)

            })
    }

    useEffect(() => {
        fetchListaEventos();
    }, [])

    if (!listaEventos) { return (<div></div>) }

  return (
    <>
       <Header logo={listaEventos.zonasContenido[0].texto} data={listaEventos.zonasContenido} />
      <div className="cuenta-usuario  bg-gradientDark">
        <div className="container">
          <div className="cabecera-cuenta-usuario">
            <div className="titulo">Hola <span>{localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-nombre`)}</span></div>
            <div className="subtitulo">Bienvenido a tu perfil</div>
          </div>
        </div>
      </div>
      <div className="section cuenta-usuario_contentbox">
        <MenuCuentaUsuario removeAuth={props.removeAuth} />
        <ContenidoDatosProfesionales />
      </div>

      <Footer data={props.zonasContenido} />
      <ScrollToTop />
    </>

  )

}