import React, { useState } from 'react';

import './estilos.css';

export const ModalCookie = (props) => {
    const [cookieStyleStatus, setcookieStyleStatus] = useState("flex");

    const getCookieValue = (name) => (
        document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || ''
    )
    const consentValue = getCookieValue("cookieConsent");
    function checkCookie(consentValue) {
        if (consentValue === "accepted" && cookieStyleStatus === "flex") {
            setcookieStyleStatus("none")
        }
    }
    checkCookie(consentValue);

    function acceptCookies(c_name, value, exdays) {
        setcookieStyleStatus("none");

        var exdate = new Date();
        exdate.setDate(exdate.getDate() + exdays);
        var c_value = escape(value) + ((exdays == null) ? "" : "; expires=" + exdate.toUTCString());
        var c_valuePath = ";path=/";
        document.cookie = c_name + "=" + c_value + c_valuePath;
    }
    function rejectCookies() {
        window.location.href = 'http://www.google.com';
    }

    return (
        <div className="cookiemodal" style={{ display: cookieStyleStatus }}>
            <div className="cookiemodal_msg">
                <p>Utilizamos cookies para darte la mejor experiencia en nuestra web.</p>
                <p>Puedes informarte más sobre qué cookies estamos utilizando <a href="/politica-de-cookies/">aquí.</a></p>
            </div>

            <div className="cookiemodal_buttons">
                <button className="aceptar" onClick={() => acceptCookies("cookieConsent", "accepted", 365)}>aceptar todo</button>
                <button onClick={() => rejectCookies()}>rechazar todas</button>
            </div>
        </div>
    )
}