import React, { Fragment, useState, useEffect, useContext } from 'react'
import axios from 'axios';
import { Helmet } from "react-helmet";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import Slider from "react-slick";
import { RiArrowDropRightFill } from "react-icons/ri";
import { Header } from "../../componentes/header";
import { BannerPrincipal } from "../../componentes/bannerPrincipal";
import { CargaContenido } from "../../componentes/cargaContenido";
import { SliderItems } from "../../componentes/sliderItems";
import { SliderCanales } from "../../componentes/sliderCanales";
import { GridEventosCiudades } from "../../componentes/gridEventosCiudades";
import { CajaTexto } from "../../componentes/cajaTexto";
import { CajaInfo } from "../../componentes/cajaInfo";
import { CajaItem } from "../../componentes/cajaItem";
import { Footer } from "../../componentes/footer";
import { Botonbase } from "../../componentes/botonbase";
import { ScrollToTop } from '../../componentes/scrollToTop';

export const EventosCiudades = (props) => {

  const handleClick = () => {
    console.log('click btn 2')
  }

  const settings = {
    dots: true,
    className: "slider-eventos",
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    infinite: false,
    centerMode: false,
    centerPadding: "0",
  };

  // const [listaEventos, setListaEventos] = useState(false)
  // const fetchListaEventos = async () => {
  //   let dataFormEventos = {
  //     "formato": "home_publica",

  //   }

  //   let axiosConfig = {
  //     headers: {
  //       'Accept': 'application/json',
  //       'Content-Type': 'application/json',

  //     },
  //   };
  //   axios.post(`${process.env.REACT_APP_URL_API}formatos/obtenerformatopublico`, dataFormEventos, axiosConfig)
  //     .then((res) => {

  //       console.log('FOTMATO PUBLICO::', res.data.data)
  //       setListaEventos(res.data.data)
  //       console.log('DATA HOME::', res.data.data)
  //       console.log('props', props.zonasContenido);

  //     })
  //     .catch((err) => {
  //       console.log('ERROR:', err)
  //       // props.removeAuth()
  //     })
  // }

  // useEffect(() => {
  //   fetchListaEventos();
  // }, [])

  // if (!listaEventos) { return (<div></div>) }


  return (
    <>
      {
        props.seoContenido !== null &&
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content={props.seoContenido.seoDescription} />
          <title>{props.seoContenido.seoTitle}</title>
          <meta property="og:title" content={props.seoContenido.seoTitle} />
          <meta property="og:description" content={props.seoContenido.seoDescription} />
          <meta property="og:locale" content="es_ES" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:site_name" content="Business+"></meta>
          <meta property="article:modified_time" content={props.seoContenido.fechaModificacion}></meta>
          <meta property="og:image" content={props.seoContenido.seoImage} />
          <meta property="og:image:width" content={props.seoContenido.seoImageWidth} />
          <meta property="og:image:height" content={props.seoContenido.seoImageHeight} />
          <meta name="twitter:title" content={props.seoContenido.seoTitle} />
          <meta name="twitter:description" content={props.seoContenido.seoDescription} />
          <meta name="twitter:image" content={props.seoContenido.seoImage} />
        </Helmet>
      }
      <Header logo={props.zonasContenido[0].texto} data={props.zonasContenido} />
      <div className="section bg-none">
        <div className="bannerInterior">
          {

            props.zonasContenido.map(item =>
              <Fragment>
                {item.idZona === 3 &&
                  <CargaContenido item={item} tipo={item.tipoZona} />
                }
              </Fragment>
            )

          }
        </div>
      </div>


      <div className="section bg-gradientDarkFlip">
        <div className="container">
          <div class="margin-tb-30">
            <div>
              <div class="titulo txt-centrado">Elige tu ciudad e inscríbete gratis</div>
            </div>
          </div>
          {

            props.zonasContenido.map(item =>
              <Fragment>
                {item.idZona === 4 &&
                  <GridEventosCiudades eventosCiudades={item.eventos} />
                }
              </Fragment>
            )

          }
        </div>
      </div>

      <div className="section bg-none">
        <div className="bannerInterior">
          <div className="card-background">
            <img src="https://webinars.spinmedia.esassets/imgpruebas/slider-home_registrado.png" />
          </div>
          <div class="container">
            <div className="card-content txt-centrado">
              <div className="titulo">“Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantiu”</div>
            </div>
          </div>
        </div>
      </div>

      <div className="section bg-dark">
        <div className="container">
          <div className="row v-center">
            <div className="col-2">
              <CajaTexto>
                <div class="titulo">Lorem ipsum dolor sit amet</div>
                <div class="subtitulo">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. <br /> <br /> Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</div>


              </CajaTexto>
            </div>
            <div className="col-2">
              <CajaTexto>
                <img class="img-responsive" src="https://webinars.spinmedia.esassets/imgpruebas/registrate_home.png" />

              </CajaTexto>
            </div>
          </div>
        </div>
      </div>


      <div className="section bg-black">
        <div className="container">
          <div className="row">
            <div className="col-3">
              <CajaInfo>
                <div><img className="img-responsive" src="https://webinars.spinmedia.esassets/imgpruebas/icon_dispositivos.svg" /></div>
                <div className="titulo">Dispositivos</div>
                <div className="subtitulo">disfruta de tu Business+ donde quieras, desde tus dispositivos</div>
              </CajaInfo>
            </div>
            <div className="col-3">
              <CajaInfo>
                <div><img className="img-responsive" src="https://webinars.spinmedia.esassets/imgpruebas/icon_dispositivos.svg" /></div>
                <div className="titulo">Dispositivos</div>
                <div className="subtitulo">disfruta de tu Business+ donde quieras, desde tus dispositivos</div>
              </CajaInfo>
            </div>
            <div className="col-3">
              <CajaInfo>
                <div><img className="img-responsive" src="https://webinars.spinmedia.esassets/imgpruebas/icon_dispositivos.svg" /></div>
                <div className="titulo">Dispositivos</div>
                <div className="subtitulo">disfruta de tu Business+ donde quieras, desde tus dispositivos</div>
              </CajaInfo>
            </div>
            <div className="col-3">
              <CajaInfo>
                <div><img className="img-responsive" src="https://webinars.spinmedia.esassets/imgpruebas/icon_dispositivos.svg" /></div>
                <div className="titulo">Dispositivos</div>
                <div className="subtitulo">disfruta de tu Business+ donde quieras, desde tus dispositivos</div>
              </CajaInfo>
            </div>
          </div>
        </div>
      </div>


      <div className="section bg-dark">
        <div className="container">
          <div className="row v-center">
            <div className="col-2">
              <CajaTexto>
                <img class="img-responsive" src="https://webinars.spinmedia.esassets/imgpruebas/registrate_home.png" />

              </CajaTexto>
            </div>
            <div className="col-2">

              <CajaTexto>
                <div class="titulo">Lorem ipsum dolor sit amet</div>
                <div class="subtitulo">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. <br /> <br /> Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</div>
              </CajaTexto>
            </div>
          </div>
        </div>
      </div>

      <div className="section bg-none">
        <div className="bannerInterior">
          <div className="card-background">
            <img src="https://webinars.spinmedia.esassets/imgpruebas/slider-home_registrado.png" />
          </div>
          <div class="container">
            <div className="card-content txt-centrado">
              <div className="titulo">“Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantiu”</div>
            </div>
          </div>
        </div>
      </div>


      <div className="section bg-white">
        <div className="container">
          <div className="cajaSponsors destacado">
            <div className="tituloSponsor">Sponsors</div>
            <div className="gridsponsors">
              <div className="itemSponsor"><a href="#"><img src="https://eventosonline.spinmedia.es/almacen/cms/spinmedia_crm_350x148.png" /></a></div>
              <div className="itemSponsor"><a href="#"><img src="https://eventosonline.spinmedia.es/almacen/cms/spinmedia_crm_350x148.png" /></a></div>
              <div className="itemSponsor"><a href="#"><img src="https://eventosonline.spinmedia.es/almacen/cms/spinmedia_crm_350x148.png" /></a></div>
              <div className="itemSponsor"><a href="#"><img src="https://eventosonline.spinmedia.es/almacen/cms/spinmedia_crm_350x148.png" /></a></div>
              <div className="itemSponsor"><a href="#"><img src="https://eventosonline.spinmedia.es/almacen/cms/spinmedia_crm_350x148.png" /></a></div>
            </div>
          </div>

          <div className="cajaSponsors">
            <div className="tituloSponsor">Colaboradores</div>
            <div className="gridsponsors">
              <div className="itemSponsor"><a href="#"><img src="https://eventosonline.spinmedia.es/almacen/cms/spinmedia_crm_350x148.png" /></a></div>
              <div className="itemSponsor"><a href="#"><img src="https://eventosonline.spinmedia.es/almacen/cms/spinmedia_crm_350x148.png" /></a></div>
              <div className="itemSponsor"><a href="#"><img src="https://eventosonline.spinmedia.es/almacen/cms/spinmedia_crm_350x148.png" /></a></div>
              <div className="itemSponsor"><a href="#"><img src="https://eventosonline.spinmedia.es/almacen/cms/spinmedia_crm_350x148.png" /></a></div>
              <div className="itemSponsor"><a href="#"><img src="https://eventosonline.spinmedia.es/almacen/cms/spinmedia_crm_350x148.png" /></a></div>
              <div className="itemSponsor"><a href="#"><img src="https://eventosonline.spinmedia.es/almacen/cms/spinmedia_crm_350x148.png" /></a></div>
              <div className="itemSponsor"><a href="#"><img src="https://eventosonline.spinmedia.es/almacen/cms/spinmedia_crm_350x148.png" /></a></div>
              <div className="itemSponsor"><a href="#"><img src="https://eventosonline.spinmedia.es/almacen/cms/spinmedia_crm_350x148.png" /></a></div>
              <div className="itemSponsor"><a href="#"><img src="https://eventosonline.spinmedia.es/almacen/cms/spinmedia_crm_350x148.png" /></a></div>

              <div className="itemSponsor"><a href="#"><img src="https://eventosonline.spinmedia.es/almacen/cms/spinmedia_crm_350x148.png" /></a></div>
              <div className="itemSponsor"><a href="#"><img src="https://eventosonline.spinmedia.es/almacen/cms/spinmedia_crm_350x148.png" /></a></div>
              <div className="itemSponsor"><a href="#"><img src="https://eventosonline.spinmedia.es/almacen/cms/spinmedia_crm_350x148.png" /></a></div>
              <div className="itemSponsor"><a href="#"><img src="https://eventosonline.spinmedia.es/almacen/cms/spinmedia_crm_350x148.png" /></a></div>
              <div className="itemSponsor"><a href="#"><img src="https://eventosonline.spinmedia.es/almacen/cms/spinmedia_crm_350x148.png" /></a></div>
              <div className="itemSponsor"><a href="#"><img src="https://eventosonline.spinmedia.es/almacen/cms/spinmedia_crm_350x148.png" /></a></div>
              <div className="itemSponsor"><a href="#"><img src="https://eventosonline.spinmedia.es/almacen/cms/spinmedia_crm_350x148.png" /></a></div>
              <div className="itemSponsor"><a href="#"><img src="https://eventosonline.spinmedia.es/almacen/cms/spinmedia_crm_350x148.png" /></a></div>
              <div className="itemSponsor"><a href="#"><img src="https://eventosonline.spinmedia.es/almacen/cms/spinmedia_crm_350x148.png" /></a></div>
            </div>
          </div>

          <div className="cajaSponsors">
            <div className="tituloSponsor">Media Partners</div>
            <div className="gridsponsors">
              <div className="itemSponsor"><a href="#"><img src="https://eventosonline.spinmedia.es/almacen/cms/spinmedia_crm_350x148.png" /></a></div>
              <div className="itemSponsor"><a href="#"><img src="https://eventosonline.spinmedia.es/almacen/cms/spinmedia_crm_350x148.png" /></a></div>
              <div className="itemSponsor"><a href="#"><img src="https://eventosonline.spinmedia.es/almacen/cms/spinmedia_crm_350x148.png" /></a></div>
              <div className="itemSponsor"><a href="#"><img src="https://eventosonline.spinmedia.es/almacen/cms/spinmedia_crm_350x148.png" /></a></div>
              <div className="itemSponsor"><a href="#"><img src="https://eventosonline.spinmedia.es/almacen/cms/spinmedia_crm_350x148.png" /></a></div>
              <div className="itemSponsor"><a href="#"><img src="https://eventosonline.spinmedia.es/almacen/cms/spinmedia_crm_350x148.png" /></a></div>
            </div>
          </div>

        </div>
      </div>



      <Footer logo={props.zonasContenido[0].texto} data={props.zonasContenido} />
      <ScrollToTop />
    </>

  )

}