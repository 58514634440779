import React, { Component, useContext } from 'react';
import GuardarLogContext from '../../ContextLog'
import { Botonbase } from "../botonbase";
import { CajaItemPonente } from "../cajaItemPonente";
import './estilos.css'; // Tell webpack that Button.js uses these styles

export const GridPonentes = (props) => {
  const log = useContext(GuardarLogContext)
  const HandleClick = () => {
    log.guardarLog('click btn 1')
  }
  return (
    <>
      <div className="grid-container-ponentes">
        {

          props.ponentes.map(ponente =>
            <div className="grid-item"><CajaItemPonente nombre={ponente.nombre + " " + ponente.apellidos} cargo={ponente.cargo} empresa={ponente.nombreEmpresa} imagen={ponente.imagenPonente} /></div>
          )

        }
      </div>
    </>
  )
}