import React, { Fragment, useState, useEffect, useContext } from 'react'
import axios from 'axios';
import { Helmet } from "react-helmet";

import { Header } from "../../componentes/header";
import { CargaContenido } from "../../componentes/cargaContenido";
import { SliderItems } from "../../componentes/sliderItems";
import { CajaItem } from "../../componentes/cajaItem"
import { Footer } from "../../componentes/footer";
import { RegistroPresencial } from '../../componentes/registroPresencial';
import { GridPonentes } from "../../componentes/gridPonentes";
import { ScrollToTop } from '../../componentes/scrollToTop';
import GuardarLogContext from '../../ContextLog'


export const EventoPresencial = (props) => {
    const log = useContext(GuardarLogContext)
    let inscrito = props.eventoSeleccionado.inscrito;
    let idEvento = props.eventoSeleccionado.idEvento;
    let esPresencial = true;
    //console.log('zonas::', props.zonasContenido)
    //console.log('seo::', props.seoContenido)

    // const [listaEventos, setListaEventos] = useState(false)
    // const fetchListaEventos = async () => {
    //     let dataFormEventos = {
    //         "formato": "evento_presencial",

    //     }

    //     let axiosConfig = {
    //         headers: {
    //             'Accept': 'application/json',
    //             'Content-Type': 'application/json',

    //         },
    //     };
    //     axios.post(`${process.env.REACT_APP_URL_API}formatos/obtenerformatopublico`, dataFormEventos, axiosConfig)
    //         .then((res) => {

    //             console.log('FOTMATO PUBLICO::', res.data.data)
    //             setListaEventos(res.data.data)
    //             console.log('DATA HOME::', res.data.data)

    //         })
    //         .catch((err) => {
    //             console.log('ERROR:', err)
    //             // props.removeAuth()
    //         })
    // }

    // useEffect(() => {
    //     fetchListaEventos();
    // }, [])
    // if (!listaEventos) { return (<div></div>) }


    return (
        <>
            {
                props.seoContenido !== null &&
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="description" content={props.seoContenido.seoDescription} />
                    <title>{props.seoContenido.seoTitle}</title>
                    <meta property="og:title" content={props.seoContenido.seoTitle} />
                    <meta property="og:description" content={props.seoContenido.seoDescription} />
                    <meta property="og:locale" content="es_ES" />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:site_name" content="Business+"></meta>
                    <meta property="article:modified_time" content={props.seoContenido.fechaModificacion}></meta>
                    <meta property="og:image" content={props.seoContenido.seoImage} />
                    <meta property="og:image:width" content={props.seoContenido.seoImageWidth} />
                    <meta property="og:image:height" content={props.seoContenido.seoImageHeight} />
                    <meta name="twitter:title" content={props.seoContenido.seoTitle} />
                    <meta name="twitter:description" content={props.seoContenido.seoDescription} />
                    <meta name="twitter:image" content={props.seoContenido.seoImage} />
                </Helmet>
            }
            <Header logo={props.zonasContenido[0].texto} data={props.zonasContenido} />


            <div className="section evento bg-gradientDark">
                <div className="evento_container">
                    <div className="evento_online_main_content">
                        <div className="evento_online_main_content_img">
                            {

                                props.zonasContenido.map(item =>
                                    <Fragment>
                                        {item.idZona === 3 &&
                                            <CargaContenido item={item} tipo={item.tipoZona} />
                                        }
                                    </Fragment>
                                )

                            }
                        </div>

                        {
                            props.eventoSeleccionado.precio === "gratuito" ?
                                <div className="evento-etiqueta">
                                    <span>Invitación gratuita</span>
                                </div>
                                : null
                        }

                        <div class="evento-fecha">
                            {

                                props.zonasContenido.map(item =>
                                    <Fragment>
                                        {item.idZona === 4 &&
                                            <CargaContenido item={item} tipo={item.tipoZona} />
                                        }
                                    </Fragment>
                                )

                            }
                        </div>
                        {
                            (props.zonasContenido.filter(e => e.tipoZona === "boton_inscripcion_externo").length > 0) ?
                                props.zonasContenido.map(item =>
                                    <Fragment>
                                        {item.tipoZona === "boton_inscripcion_externo" &&
                                            <div className="boton_inscripcion_externo">
                                                <a href={item.enlace} target={item.target} className="btn principal btn_inscribirse btn_narrow">{item.titulo}</a>
                                            </div>
                                        }
                                    </Fragment>
                                )
                                :
                                props.eventoSeleccionado.fechaEvento.split('T')[0]+'T24:00:00' > new Date(Date.now()).toISOString() &&
                                <RegistroPresencial isAuth={props.isAuth} dataEvento={props.eventoSeleccionado} idEvento={idEvento} inscrito={inscrito} esPresencial={esPresencial} logEvento={log.logEvento}></RegistroPresencial>

                        }
                        <div className="agenda-evento">
                            <h2>Agenda evento</h2>
                            {
                                props.zonasContenido.map(item =>
                                    <Fragment>
                                        {item.idZona === 6 &&
                                            <div className="item-agenda">
                                                <CargaContenido item={item} tipo={item.tipoZona} />
                                            </div>
                                        }
                                    </Fragment>
                                )
                            }
                        </div>


                    </div>
                    <div className="evento_online_lateral_content">

                        <div className="caja-lateral">
                            {

                                props.zonasContenido.map(item =>
                                    <Fragment>
                                        {item.idZona === 5 &&
                                            <CargaContenido item={item} tipo={item.tipoZona} />
                                        }
                                    </Fragment>
                                )

                            }
                            {
                                (props.zonasContenido.filter(e => e.tipoZona === "boton_inscripcion_externo").length > 0) ?
                                    props.zonasContenido.map(item =>
                                        <Fragment>
                                            {item.tipoZona === "boton_inscripcion_externo" &&
                                                <div className="boton_inscripcion_externo">
                                                    <a href={item.enlace} target={item.target} className="btn principal btn_inscribirse">{item.titulo}</a>
                                                </div>
                                            }
                                        </Fragment>
                                    )
                                    :
                                    <Fragment>
                                    {
                                        props.eventoSeleccionado.fechaEvento.split('T')[0]+'T24:00:00' > new Date(Date.now()).toISOString() &&
                                        <RegistroPresencial isAuth={props.isAuth} dataEvento={props.eventoSeleccionado} idEvento={idEvento} inscrito={inscrito} esPresencial={esPresencial} logEvento={log.logEvento}></RegistroPresencial>

                                    }
                                    </Fragment>
                            }
                            {console.log("HHHHH::", props.zonasContenido.filter(e => e.tipoZona === "boton_inscripcion_externo"))}
                        </div>


                        {
                            (props.eventoSeleccionado.idTipoEvento === 3) &&
                            props.zonasContenido.map(item =>
                                <Fragment>
                                    {
                                        item.idZona === 13 &&
                                        <div className="caja-lateral caja-stands">
                                            
                                            {
                                                
                                                    <CargaContenido item={item} tipo={item.tipoZona} />
                                               
                                            }
                                        </div>
                                    }
                                </Fragment>
                            )
                        }

                    </div>
                </div>
            </div>

            <div className="section bg-gradientDarkFlip section_ponentes">
                <div className="container_ponentes">
                    <div class="titulo">Ponentes</div>
                    {
                        props.zonasContenido.map(item =>
                            item.idZona === 7 &&
                            <GridPonentes ponentes={item.ponentes} />

                        )
                    }
                </div>
            </div>

            {
                props.zonasContenido.map(item =>
                    item.idZona ===11 &&
                    <Fragment>
                    <CargaContenido item={item} tipo={item.tipoZona} isAuth={props.isAuth} />
                    </Fragment>
                )
            }

            {
                props.zonasContenido.map(item =>
                    item.idZona === 8 && item.orden === 1 ?
                        <div className="section bg-white section_sponsors">
                            {
                                <div className="cajaSponsors destacado">
                                    <div className="tituloSponsor">{item.titulo}</div>
                                    <div className="gridsponsors">
                                        {
                                            props.zonasContenido.map(item =>
                                                item.idZona === 8 && item.orden === 1 &&
                                                item.logotipos.map(logotipoSponsor =>
                                                    <div className="itemSponsor"><a href={logotipoSponsor.enlace} target={logotipoSponsor.target}><img src={logotipoSponsor.urlLogotipo} alt={logotipoSponsor.alt} /></a></div>
                                                )
                                            )
                                        }
                                    </div>
                                </div>
                            }

                            {
                                props.zonasContenido.map(item =>
                                    item.idZona === 8 && item.orden != 1 ?

                                        <div className="cajaSponsors">
                                            <div className="tituloSponsor">{item.titulo}</div>
                                            <div className="gridsponsors">
                                                {
                                                    item.logotipos.map(logotipoSponsor =>
                                                        <div className="itemSponsor"><a href={logotipoSponsor.enlace} target={logotipoSponsor.target}><img src={logotipoSponsor.urlLogotipo} alt={logotipoSponsor.alt} /></a></div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        : null
                                )
                            }
                        </div>
                        : null
                )
            }

            <Footer logo={props.zonasContenido[0].texto} data={props.zonasContenido} />
            <ScrollToTop />
        </>

    )

}