import React, { Children, Component } from 'react';
import Slider from "react-slick";
import './estilos.css';

export const SliderItems = (props) => {

  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: false,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1580,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,

        }
      },
      {
        breakpoint: 1220,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,

        }
      },
      {
        breakpoint: 865,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,

        }
      }
    ]
  };

  return (
    <Slider {...settings}>
      {props.children}
    </Slider>
  )
}