import React, { Fragment, useState, useEffect, useContext } from 'react'
import axios from 'axios';
import { RiArrowDropRightFill } from "react-icons/ri";
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Helmet } from "react-helmet";
import { CargaContenido } from "../../componentes/cargaContenido";
import { Header } from "../../componentes/header";
import { SliderItems } from "../../componentes/sliderItems";
import { CajaItem } from "../../componentes/cajaItem"
import { Footer } from "../../componentes/footer";
import { ScrollToTop } from '../../componentes/scrollToTop';

export const ProgramasHome = (props) => {
  console.log('props.zonasContenido',props.zonasContenido)
  // const [datos, setdatos] = useState(false)
  // let dataFormEventos = {}
  // let axiosConfig = {}
  // let url = `${process.env.REACT_APP_URL_API}formatos/obtenerformatopublico`
  // const fetchdatos = async () => {
  //   if (props.isAuth) {
  //     url = `${process.env.REACT_APP_URL_API}formatos/obtenerformato`
  //     dataFormEventos = {
  //       "formato": "canal",
  //       "idUsuario": localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-idu`),
  //       "idEvento": props.idCanal
  //     }
  //     axiosConfig = {
  //       headers: {
  //         'Accept': 'application/json',
  //         'Content-Type': 'application/json',
  //         'Authorization': `Bearer ${localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-token`)}`

  //       },
  //     };
  //   } else {
  //     dataFormEventos = {
  //       "formato": "canal",
  //       "idEvento": props.idCanal
  //     }
  //     axiosConfig = {
  //       headers: {
  //         'Accept': 'application/json',
  //         'Content-Type': 'application/json',
  //       },
  //     };
  //   }
  //   axios.post(url, dataFormEventos, axiosConfig)
  //     .then((res) => {

  //       setdatos(res.data.data)
  //       console.log('DATA CANAL::', res.data.data)

  //     })
  //     .catch((err) => {
  //       console.log('ERROR:', err)
  //       // props.removeAuth()
  //     })
  // }

  // useEffect(() => {
  //   fetchdatos();
  // }, [])

  // if (!props.zonasContenido) { return (<div></div>) }

  return (
    <>
      {
        props.seoContenido !== null &&
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content={props.seoContenido.seoDescription} />
          <title>{props.seoContenido.seoTitle}</title>
          <meta property="og:title" content={props.seoContenido.seoTitle} />
          <meta property="og:description" content={props.seoContenido.seoDescription} />
          <meta property="og:locale" content="es_ES" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:site_name" content="Business+"></meta>
          <meta property="article:modified_time" content={props.seoContenido.fechaModificacion}></meta>
          <meta property="og:image" content={props.seoContenido.seoImage} />
          <meta property="og:image:width" content={props.seoContenido.seoImageWidth} />
          <meta property="og:image:height" content={props.seoContenido.seoImageHeight} />
          <meta name="twitter:title" content={props.seoContenido.seoTitle} />
          <meta name="twitter:description" content={props.seoContenido.seoDescription} />
          <meta name="twitter:image" content={props.seoContenido.seoImage} />
        </Helmet>
      }
      <Header logo={props.zonasContenido[0].texto} data={props.zonasContenido} />
      <div className="section bg-gradientDark">
        <div className="container">
          <div className="margin-tb-30">
            {

              props.zonasContenido.map(item =>
                <Fragment>
                  {item.idZona === 3 &&
                    <CargaContenido item={item} tipo={item.tipoZona} />
                  }
                </Fragment>
              )

            }

          </div>
        </div>
      </div>
      <div className="section">
                <div className="container">
                <div className="grid-canales programa">

      {

        props.zonasContenido.map(item =>
          <Fragment>
            {item.idZona === 4 && item.tipoZona == 'imagen' &&
              


                     <div className="grid-item">
                      <a className="item-canal" href={item.enlace} title={item.nombre}>
                            <img src={item.imagen} alt={item.nombre} />
                        </a>
                     </div>




                 
            }
          </Fragment>
        )
      }
       </div>
                </div>
              </div>
      <Fragment>

        {

          props.zonasContenido.map(item =>
            <Fragment>

              {item.idZona === 5 && item.tipoZona == 'carrusel' &&
                <Fragment>
                  <CargaContenido item={item} tipo={item.tipoZona} isAuth={props.isAuth} />
                </Fragment>
              }

            </Fragment>
          )

        }

      </Fragment>

      <Footer logo={props.zonasContenido[0].texto} data={props.zonasContenido} />
      <ScrollToTop />
    </>

  )

}