import React, { useState } from 'react'

import './estilos.css';

export const SelectorInput = (props) => {
    const [toggleElement, settoggleElement] = useState(false);
    
    function clickDropdown() {
       
        toggleElement ? settoggleElement(false) : settoggleElement(true)
    }
    function clickOption(option,inputId) {
        props.setSelectedValue(option);
        settoggleElement(false)
    }
    return (
        <div className="selector">
            <div className="selectorInput">
                <div className={props.error ? "selectorInputBtn selectorInputError" : "selectorInputBtn"} onClick={() => clickDropdown()}>{props.selectedValue}</div>
                <div className={!toggleElement? "selectorInputDropdown" : "selectorInputDropdown toggle"} id={props.id}>
                    {
                        props.options.map(option => {
                            return (
                                <div className="selectorInputOption" onClick={() => clickOption(option,props.id)}>{option}</div>
                            )
                        })
                    }
                </div>

            </div>
            <div className="step3_selector_icon icon_downarrow" onClick={() => clickDropdown()}>
                <span className="material-icons-outlined">arrow_drop_down</span>
            </div>
        </div>
    )
}