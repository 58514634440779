import React, { Fragment, useState, useEffect, useContext } from 'react'
import { BannerPrincipal } from "../bannerPrincipal";
import { BannerInterior } from "../bannerInterior";
import { MisInscripciones } from "../misInscripciones";
import { BannerRegistrado } from "../bannerRegistrado";
import { CajaItemEventoPresencial } from "../../componentes/cajaItemEventoPresencial";
import { SliderCanales } from "../sliderCanales";
import { GridCanales } from "../gridCanales";
import { SliderItems } from "../sliderItems";
import { CajaItem } from "../cajaItem";
import { Listadopreguntas } from '../Listadopreguntas'


/* para funcion texto dotar string con acciones js


ReactHtmlParser(item.texto, {
                          transform: (node) => {
                              if (node.attribs && node.attribs.attrfuncion) {
                                console.log('node',node.attribs)
                                return <div
                                              class={node.attribs.class}
                                              onClick={(e) => {                                                            
                                                e.preventDefault();                                                            
                                                //toggleModalRegisitro()
                                              }}
                                              >{node.children[0].data}</div>
                              }
                          } 
                       })

*/

export const CargaContenido = (props) => {
  //console.log('carga conteni',props)
  switch (props.tipo) {

    case 'texto':
      let texto =  props.item.texto
      texto = texto.replace('Contenido con recarga','Congreso online')
      texto = texto.replace('<span class="label_categoria"></span>','')
      return (
        <div dangerouslySetInnerHTML={{ __html: texto }} />
      )
    case 'imagen':
      return (
        <a href={props.item.enlace}>
          {props.item.titulo ?
            <h2 class="titulo">{props.item.titulo}</h2>
            : null
          }
          <img className="img-responsive" src={props.item.imagen} />
        </a>
      )
    case 'banner_texto':

      return (
        props.isAuth ?
          
          <BannerPrincipal item={props.item} isAuth={props.isAuth}  />
          :
          <BannerPrincipal item={props.item} isAuth={props.isAuth}  />
      )
    case 'banner_slider':

      return (
        props.isAuth ?
          
          <BannerInterior item={props.item} isAuth={props.isAuth}  />
          :
          <BannerInterior item={props.item} isAuth={props.isAuth}  />
      )
    case 'listado_canales':

      return (
        <SliderCanales canales={props.item.canales} />
      )

    case 'grid-canales':

        return (
          <GridCanales canales={props.item.canales} />
        )

    case 'carrusel':
      //console.log('en carrusel',props.isAuth)
      if (props.item.eventos.length >= 1) {
        return (
          <div className="container-carrusel">
            <div className="titulo">{props.item.titulo}</div>
            <SliderItems>
              {
                props.item.eventos.map(itemevento =>
                  
                  itemevento.logo !== null  ? 
                        <CajaItemEventoPresencial imagen={itemevento.logo} enlace={itemevento.enlace} /> 
                      :
                        <CajaItem isAuth={props.isAuth} dataEvento={itemevento} />
                )
              }
            </SliderItems>
          </div>
        )
      } else {
        return (null)
      }

    case 'descarga_documentos':
      return (
        <Fragment>
          {props.item.titulo !== null && <h3 className="descargardocumentos_titulo">{props.item.titulo}</h3>}
          <div className="descargardocumentos_items">
            {props.item.documentos.map(documento =>
              <a href={documento.rutaWeb}>{documento.titulo}</a>
            )}
          </div>
        </Fragment>
      )
      break

    case 'listado_etiquetas':
      return (
        <Fragment>
          {props.item.titulo !== null && <div className="titulo">{props.item.titulo}</div>}

          <div className="caja-etiquetas">
            {
              props.item.etiquetas.map(itemetiqueta =>
                <div className="caja-etiqueta">{itemetiqueta.descripcion}</div>
              )}
          </div>
        </Fragment>
      )
      break

    case 'chat_preguntas':
      return (
        props.eventoSeleccionado.idTipoEvento == 1 || props.eventoSeleccionado.idTipoEvento == 4 ?
          ((props.isAuth && props.inscrito) || (props.isAuth && props.eventoSeleccionado.privado === true)) ?
            <div id="chatDirecto">
              <Listadopreguntas idEvento={props.eventoSeleccionado.idEvento} item={props.item} />
            </div>
            : null
          : null
      )
      break
    case 'suscripciones_usuario':
      return (
        <MisInscripciones  item={props.item} isAuth={props.isAuth}  />
      )
      break
      

    default:
      return (<div></div>)

  }

}