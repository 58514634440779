import React, { Fragment, useState } from 'react';

import './estilos.css';
import CnaeList from './cnae.json';

export const ModalCnae = (props) => {

    const [cnaeName, setCnaeName] = useState('');
    const [cnaeList, setCnaeList] = useState(CnaeList.clasificacion.filter(cnae => cnae.COD_CNAE2009.length === 4));
    const [foundCnae, setFoundCnae] = useState(CnaeList.clasificacion.filter(cnae => cnae.COD_CNAE2009.length === 4));

    function quitarAcentos(cadena) {
        const acentos = { 'á': 'a', 'é': 'e', 'í': 'i', 'ó': 'o', 'ú': 'u', 'Á': 'A', 'É': 'E', 'Í': 'I', 'Ó': 'O', 'Ú': 'U' };
        return cadena.split('').map(letra => acentos[letra] || letra).join('').toString();
    }
    const filterCnae = (e) => {
        const keyword = e.target.value;

        if (keyword !== '') {
            const results = cnaeList.filter((cnae) => {
                let itemDataCode = cnae.CODINTEGR.toUpperCase()
                itemDataCode = quitarAcentos(itemDataCode)
                let itemDataDescp = cnae.TITULO_CNAE2009.toUpperCase()
                itemDataDescp = quitarAcentos(itemDataDescp)
                const campo = itemDataCode + " " + itemDataDescp
                const textData = keyword.toUpperCase()
                return campo.indexOf(textData) > -1
            });
            setFoundCnae(results);
        } else {
            setFoundCnae(CnaeList.clasificacion.filter(cnae => cnae.COD_CNAE2009.length === 4));
        }

        setCnaeName(keyword);
    };
    const CerrarModal = () => {
        props.setshowCnaeModal("none");
    }

    return (
        <div className="modalcnae" style={{ display: props.show }}>
            <div className="modalcnae_box">
                <p className="modalcnae_cerrar" onClick={() => CerrarModal()}>cerrar x</p>
                <div className="modalcnae_buscador">
                    <label htmlFor="">Buscador de CNAE</label>
                    <input
                        type="search"
                        value={cnaeName}
                        onChange={filterCnae}
                        placeholder="Filtrar por nombre"
                    />
                </div>
                <div className="modalcnae_cnaelist">
                    {
                        foundCnae.map(item => {
                            return (
                                <div onClick={() => props.pickCnaeFunction(item.COD_CNAE2009, item.TITULO_CNAE2009)} className="modalcnae_cnaelist_item">
                                    <p>{item.COD_CNAE2009}</p>
                                    <p>{item.TITULO_CNAE2009}</p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}