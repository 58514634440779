import React, { Fragment, useState, useEffect, useContext } from 'react'
import axios from 'axios';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { RiArrowDropRightFill } from "react-icons/ri";
import { Helmet } from "react-helmet";

import { CargaContenido } from "../../componentes/cargaContenido";
import { Header } from "../../componentes/header";
import { SliderItems } from "../../componentes/sliderItems";
import { CajaItem } from "../../componentes/cajaItem";
import { GridCanales } from "../../componentes/gridCanales";
import { CajaTexto } from "../../componentes/cajaTexto";
import { Footer } from "../../componentes/footer";
import { ScrollToTop } from '../../componentes/scrollToTop';

export const Contenidos = (props) => {


  console.log('DATOS::', props.zonasContenido)

  if (!props.zonasContenido) { return (<div></div>) }

  return (
    <>
      {
        props.seoContenido !== null &&
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content={props.seoContenido.seoDescription} />
          <title>{props.seoContenido.seoTitle}</title>
          <meta property="og:title" content={props.seoContenido.seoTitle} />
          <meta property="og:description" content={props.seoContenido.seoDescription} />
          <meta property="og:locale" content="es_ES" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:site_name" content="Business+"></meta>
          <meta property="article:modified_time" content={props.seoContenido.fechaModificacion}></meta>
          <meta property="og:image" content={props.seoContenido.seoImage} />
          <meta property="og:image:width" content={props.seoContenido.seoImageWidth} />
          <meta property="og:image:height" content={props.seoContenido.seoImageHeight} />
          <meta name="twitter:title" content={props.seoContenido.seoTitle} />
          <meta name="twitter:description" content={props.seoContenido.seoDescription} />
          <meta name="twitter:image" content={props.seoContenido.seoImage} />
        </Helmet>
      }
      <Header logo={props.zonasContenido[0].texto} data={props.zonasContenido} />

      <div className="section bg-gradientDark">
        <div className="container">
          <div className="margin-tb-30">
            {

              props.zonasContenido.map(item =>
                <Fragment>
                  {item.idZona === 3 &&
                    <CargaContenido item={item} tipo={item.tipoZona} />
                  }
                </Fragment>
              )

            }

          </div>
        </div>
      </div>
      <Fragment>

        {

          props.zonasContenido.map(item =>
            <Fragment>

              { //carrusel
                item.idZona === 4 &&
                <Fragment>
                  <CargaContenido item={item} tipo={item.tipoZona} isAuth={props.isAuth} />
                </Fragment>
              }

            </Fragment>
          )

        }

      </Fragment>


      {/* <div className="section bg-gradientDarkFlip">
        <div className="container">
          <div className="row homepublica_iconrow">
            {

              props.zonasContenido.map(item =>
                <Fragment>
                  {item.idZona === 5 &&
                    <CargaContenido item={item} tipo={item.tipoZona} />
                  }
                </Fragment>
              )
            }
          </div>
        </div>
      </div> */}

      <Footer logo={props.zonasContenido[0].texto} data={props.zonasContenido} />
      <ScrollToTop />
    </>

  )

}