import React, { useState } from 'react'
import { Link } from "react-router-dom";
import axios from 'axios';
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";

import Context from '../../Context';
import { ModalLogin } from "../modalLogin";
import './estilos.css';

export const CajaItemEventoSesion = (props) => {
    let fechaEvento = props.evento.fechaEvento;
    let year = fechaEvento.split("-")[0];
    let month = fechaEvento.split("-")[1];
    if (month.substr(0, 1) == "0") {
        month = month.substr(1);
    }
    month = month - 1;
    let day = fechaEvento.split("-")[2].split("T")[0];
    let monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    let monthName = monthNames[month];

    if (day < 10) {
        day = day.split('0')[1]
    }

    const [fav, setfav] = useState(typeof (props.evento) == 'undefined' ? false : props.evento.esFavorito)
    const tooglefavorito = async (id) => {

        try {
            var dataForm = {
                "idEvento": id,
                "idUsuario": localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-idu`),
                "esAlta": !fav
            }
            let axiosConfig = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-token`)}`
                },
            };
            await axios.post(`${process.env.REACT_APP_URL_API}eventos/EsFavorito`, dataForm, axiosConfig)
                .then(dataFavorito => {
                    if (dataFavorito.status === 200) {
                        if (dataFavorito.data === 'error') {
                            console.log('no se ha podido agregar a favoritos ', dataForm)
                        } else {
                            //toggle icon favorito
                            console.log("TOGGLE FAVORITO", dataFavorito.data)
                            setfav(!fav)
                        }
                    }

                })
        } catch (error) {
            console.log('no se ha podido agregar a favoritos ', dataForm)
        }

    }

    if (!props.evento) { return (<div></div>) }

    return (
        <Context.Consumer>{({ toggleModalRegisitro, modalLogin, toggleModalLogin, activateAuth, updateEmailLogin, emailLogin }) => <>
            <ModalLogin updateEmailLogin={updateEmailLogin} emailLogin={emailLogin} mostrarModal={modalLogin} toggleModalLogin={toggleModalLogin} toggleModalRegisitro={toggleModalRegisitro} activateAuth={activateAuth} />
            <div className={`cardEvento itemeventoSesion base ${props.clase}`}  >
                <div className="cardEvento-contendor-imagen">
                    <div className="cardEvento-imagen">
                    <Link to={props.evento.enlace}><img src={props.evento.imagenFicha} /></Link>
                    </div>
                    {
                        props.evento.categorias ?
                            <div className="cardEvento-etiqueta dark">{props.evento.categorias}</div>
                            : null
                    }

                </div>
                <div className="cardEvento-body">
                    <div className="cardEvento-titulo"><Link to={props.evento.enlace}>{props.evento.nombre}</Link></div>
                    <div className="cardEvento-etiquetas">
                        {
                            props.evento.plataformas ?
                                <div className="cardEvento-etiqueta">{props.evento.plataformas}</div>
                                : null
                        }
                        <div className="cardEvento-etiqueta etiqueta-grey">{props.evento.tipoEventoNombre}</div>
                    </div>
                </div>
                <div className="cardEvento-footer">
                   
                        {
                            props.evento.ciudad !== null ?
                            <div className="cardEvento-fecha destacado">{props.evento.ciudad}: {day} de {monthName}, {year}  </div>
                            :
                            fechaEvento.split('T')[0]+'T24:00:00' > new Date(Date.now()).toISOString() ?
                                <div className="cardEvento-fecha destacado">{day} de {monthName}, {year}</div>
                            :
                                <div className="cardEvento-fecha disponible">Contenido disponible</div>

                        }
                          
                   
                    <div className="cardEvento-like">
                        {props.isAuth &&
                            <>
                                {fav ?
                                    <AiFillHeart size="24" onClick={(e) => { tooglefavorito(props.evento.idEvento) }} />
                                    :
                                    <AiOutlineHeart size="24" onClick={(e) => { tooglefavorito(props.evento.idEvento) }} />
                                }
                            </>
                        }
                        {!props.isAuth &&
                            <>
                                {fav ?
                                    <AiFillHeart size="24" onClick={() => toggleModalLogin()} />
                                    :
                                    <AiOutlineHeart size="24" onClick={() => toggleModalLogin()} />
                                }
                            </>
                        }
                    </div>
                </div>
            </div>
        </>}
        </Context.Consumer>
    )
}