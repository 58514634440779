import React, { useState, useRef, useEffect, Fragment } from 'react';
import axios from 'axios';
import { AiFillEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";
import { Botonbase } from "../botonbase";
import { SelectorInput } from "../selectorInput";
import { ModalCnae } from "../modalCnae";
import './estilos.css';

export const ModalRegistroInscripcionPresencial = (props) => {
  console.log('props.dataEvento 1',props)
  const [valorSteep, setValorsteep] = useState(1);

  const terminosRef = useRef();
  const terminosRef2 = useRef();
  const nombreFiscalRef = useRef();
  const residenteEspanaRef = useRef();
  const [usuarioRegistrado, setusuarioRegistrado] = useState(false);
  const [formIsInvalid, setformIsInvalid] = useState(false);
  const [quitarPaso, setQuitarPaso] = useState(false);
  const [errorPaso3, setErrorPaso3] = useState(false);

  const [isclick , setisclick ] = useState(false);

  const [messageLogin, setmessageLogin] = useState('');
  const messageLoginChange = (val) => {
    setmessageLogin(val)
  }
  const [showPass, setshowPass] = useState(false);
  function showPassword() {
    if (showPass) {
      setshowPass(false)
    } else {
      setshowPass(true)
    }
  }

  const [sectores, setSectores] = useState(false)
  const [intereses, setIntereses] = useState([])
  const [cleanIntereses, setCleanIntereses] = useState([])
  const [errorIntereses, setErrorIntereses] = useState(false)
  const marcarIntereses = async (action, idEtiqueta) => {
    console.log('ETIQUETAS ACTION:::', action)
    let string = []
    if (intereses.length >= 1) { string = intereses.split(',') }

    if (action == 'add') {
      cleanIntereses.push(idEtiqueta)
      console.log(cleanIntereses);
      string.push(idEtiqueta)
      string = string.join()
    }
    if (action == 'del') {
      const index = string.indexOf("" + idEtiqueta);
      let i = cleanIntereses.indexOf(idEtiqueta);
      if (i !== -1) {
        cleanIntereses.splice(i, 1);
        console.log(cleanIntereses);
      }
      if (index > -1) {
        string.splice(index, 1);
        string = string.join()
      }
    }
    setIntereses(string)
    console.log('ETIQUETAS:::', string)
  }
  const fetchSectores = async () => {
    let dataFormEventos = {
      "tipoCategoria": "intereses"
    }

    let axiosConfig = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    };
    axios.post(`${process.env.REACT_APP_URL_API}formatos/ObtenerCategorias`, dataFormEventos, axiosConfig)
      .then((res) => {
        let cleanSectores = [];
        console.log("sectores", res.data.data)
        setSectores(res.data.data)
      })
      .catch((err) => {

      })
  }
  useEffect(() => {
    fetchSectores();
  }, [])

  const limpiaFormulario = () => {
    setValorsteep(1)
    //setValorEmail('')
    setErrorMail(false)
    setCnaeCode('')
    setshowCnaeModal("none")
    setErrorExisteMail(false)
    setValornombre('')
    setValorApellidos('')
    setValorDNI('')
    setValorEmpresa('')
    setValorTelefono('')
    setValorMovil('')
    setValorCp('')
    setValorPuesto('')
    setErrorNombre(false)
    setErrorDni(false)
    setErrorCnae(false)
    setErrorapellidos(false)
    setErrorTelefono(false)
    setErrorMovil(false)
    setErrorCP(false)
    setErrorPuesto(false)
    setErrorFacturacion(false)
    setErrorEmpleados(false)
    setErrorEmpresa(false)
    setIntereses([])
    setCleanIntereses([])
    setErrorIntereses(false)
    setErrorPaso3(false)
    setmessageLogin('')
  }

  const CerrarModal = () => {
    limpiaFormulario()
    setusuarioRegistrado(false)
    props.setMostrarModal(false)
  }

  const [errorMail, setErrorMail] = useState(false);
  const [errorExisteMail, setErrorExisteMail] = useState(false);
  const [existeMail, setExisteMail] = useState();
  const [valorEmail, setValorEmail] = useState('');
  const emailChange = (val) => {
    setValorEmail(val.target.value)
    setErrorMail(false)
    setErrorExisteMail(false)
  }
  const [errorPass, setErrorPass] = useState(false)
  const [valorPass, setValorPass] = useState('');
  const passChange = (val) => {
    setValorPass(val.target.value)
    setErrorPass(false)
  }
  const [errorAviso, setErrorAviso] = useState(false)
  const [errorAviso2, setErrorAviso2] = useState(false)

  const [errorNombre, setErrorNombre] = useState(false)
  const [valorNombre, setValornombre] = useState('');
  const nombreChange = (val) => {
    if (nombreFiscalRef.current.checked) {
      setValornombre(val.target.value)
      setValorEmpresa(val.target.value + ' ' + valorApellidos)
      setErrorNombre(false)
    } else {
      setValornombre(val.target.value)
      setErrorNombre(false)
    }
  }

  const [errorApellidos, setErrorapellidos] = useState(false)
  const [valorApellidos, setValorApellidos] = useState('');
  const apellidosChange = (val) => {
    if (nombreFiscalRef.current.checked) {
      setValorApellidos(val.target.value)
      setValorEmpresa(valorNombre + ' ' + val.target.value)
      setErrorapellidos(false)
    } else {
      setValorApellidos(val.target.value)
      setErrorapellidos(false)
    }
  }

  const [errorDni, setErrorDni] = useState(false)
  const [valorDNI, setValorDNI] = useState('');
  const dniChange = (val) => {
    console.log('dni change')
    setValorDNI(val.target.value)
    setErrorDni(false)

    let dataFormDNI = {
      "Cif": val.target.value
    }

    let axiosConfig = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    };
    axios.post(`${process.env.REACT_APP_URL_API}plataformas/ObtenerClientePorCIF`, dataFormDNI, axiosConfig)
      .then((res) => {
        console.log("datos POR NIF", res.data.data)
        setValorEmpresa(res.data.data.nombre)
        setCnaeCode(res.data.data.cnae)
        setValorFacturacion(res.data.data.facturacion)
        setValorEmpleados(res.data.data.empleados)
      })
      .catch((err) => {
        console.log(err);
      })

  }

  const [errorEmpresa, setErrorEmpresa] = useState(false)
  const [valorEmpresa, setValorEmpresa] = useState('');
  const empresaChange = (val) => {
    if (nombreFiscalRef.current.checked) {
      setValorEmpresa(valorNombre + ' ' + valorApellidos)
    } else {
      setValorEmpresa(val.target.value)
      setErrorEmpresa(false)
      setErrorPaso3(false)
    }
  }
  function handleAutonomo() {
    if (nombreFiscalRef.current.checked) {
      setValorEmpresa(valorNombre + ' ' + valorApellidos)
      setErrorEmpresa(false)
      setErrorPaso3(false)
    }
    if (!nombreFiscalRef.current.checked) {
      setValorEmpresa('')
      setErrorEmpresa(false)
      setErrorPaso3(false)
    }
  }

  const [errorCnae, setErrorCnae] = useState(false)
  const [cnaeCode, setCnaeCode] = useState('');
  const [cnaeDescription, setCnaeDescription] = useState('');
  const [showCnaeModal, setshowCnaeModal] = useState("none");
  const cnaeChange = (val) => {
    setErrorCnae(false)
    setErrorPaso3(false)
  }
  const openModalCnae = () => {
    setshowCnaeModal("block")
  }
  const pickCnae = (cnae, cnaeD) => {
    setCnaeCode(cnae);
    setCnaeDescription(cnaeD);
    setshowCnaeModal("none");
  }
  const [errorFacturacion, setErrorFacturacion] = useState(false)
  const [valorFacturacion, setValorFacturacion] = useState('- Elige una opción -');

  const [errorEmpleados, setErrorEmpleados] = useState(false)
  const [valorEmpleados, setValorEmpleados] = useState('- Elige una opción -');

  const [errorPuesto, setErrorPuesto] = useState(false)
  const [valorPuesto, setValorPuesto] = useState('');
  const puestoChange = (val) => {
    setValorPuesto(val.target.value)
    setErrorPuesto(false)
    setErrorPaso3(false)
  }
  const [errorTelefono, setErrorTelefono] = useState(false)
  const [valorTelefono, setValorTelefono] = useState('');
  const telefonoChange = (val) => {
    setValorTelefono(val.target.value)
    setErrorTelefono(false)
    setErrorPaso3(false)
  }
  const [errorMovil, setErrorMovil] = useState(false)
  const [valorMovil, setValorMovil] = useState('');
  const movilChange = (val) => {
    setValorMovil(val.target.value)
    setErrorMovil(false)
    setErrorPaso3(false)
  }

  const [errorCP, setErrorCP] = useState(false)
  const [valorCp, setValorCp] = useState('');
  const cpChange = (val) => {
    if (residenteEspanaRef.current.checked) {
      setValorCp('00000')
      setErrorCP(false)
      setErrorPaso3(false)
    }
    if (!residenteEspanaRef.current.checked) {
      setValorCp(val.target.value)
      setErrorCP(false)
      setErrorPaso3(false)
    }
  }
  function handleResidente() {
    if (residenteEspanaRef.current.checked) {
      setValorCp('00000')
      setErrorCP(false)
      setErrorPaso3(false)
    }
    if (!residenteEspanaRef.current.checked) {
      setValorCp('')
      setErrorCP(false)
      setErrorPaso3(false)
    }
  }

  const validaPasoUno = async () => {
    var regex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
    let dataForm = {
      "email": props.valorEmail.toLowerCase(),
    }
    let axiosConfig = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }
    await axios.post(`${process.env.REACT_APP_URL_API}auth/ExisteUsuarioRegistro`, dataForm, axiosConfig)
      .then((res) => {
        console.log(res)
        setExisteMail(false)
        console.log("existeMailFalse:", existeMail);
        if (!regex.test(props.valorEmail)) {
          setErrorMail(true)
        }
        if (!existeMail && regex.test(props.valorEmail)) {
          setValorsteep(valorSteep + 1)
        }
      })
      .catch((err) => {
        console.log(err)
        setExisteMail(true)
        setErrorExisteMail(true)
        console.log("existeMailTrue:", existeMail);
      })
  }

  const validaPasoDos = () => {
    let formHasError = false
    const telefonoRegex = /^[0-9]*$/;

    if (valorNombre.length == 0) {
      formHasError = true
      setErrorNombre(true)
      setErrorPaso3(true)
    } else {
      setErrorNombre(false)
    }
    if (valorApellidos.length == 0) {
      formHasError = true
      setErrorapellidos(true)
      setErrorPaso3(true)
    } else {
      setErrorapellidos(false)
    }
    if (valorDNI.length != 9) {
      formHasError = true
      setErrorDni(true)
      setErrorPaso3(true)
    } else {
      setErrorDni(false)
    }
    if (valorEmpresa.length == 0) {
      formHasError = true
      setErrorEmpresa(true)
      setErrorPaso3(true)
    } else {
      setErrorEmpresa(false)
    }
    if (cnaeCode != null && cnaeCode.length == 0) {
      //formHasError = true
      //setErrorCnae(true)
      //setErrorPaso3(true)
      setErrorCnae(false)
    } else {
      setErrorCnae(false)
    }
   
    if (valorFacturacion === '- Elige una opción -') {
      //formHasError = true
      //setErrorFacturacion(true)
      //setErrorPaso3(true)
      setErrorFacturacion(false)
    } else {
      setErrorFacturacion(false)
    }
    if (valorEmpleados === '- Elige una opción -') {
      //formHasError = true
      //setErrorEmpleados(true)
      //setErrorPaso3(true)
      setErrorEmpleados(false)
    } else {
      setErrorEmpleados(false)
    }
   /* if (valorFacturacion === 'sin determinar') {
      formHasError = true
      setErrorFacturacion(true)
      setErrorPaso3(true)
    } else {
      setErrorFacturacion(false)
    }
    if (valorEmpleados === 'sin determinar') {
      formHasError = true
      setErrorEmpleados(true)
      setErrorPaso3(true)
    } else {
      setErrorEmpleados(false)
    }*/
    if (valorTelefono.length <= 7 || valorTelefono.length >= 14 || !telefonoRegex.test(valorTelefono)) {
      formHasError = true
      setErrorTelefono(true)
      setErrorPaso3(true)
    } else {
      setErrorTelefono(false)
    }
    if (valorMovil.length >= 14 || !telefonoRegex.test(valorMovil)) {
      formHasError = true
      setErrorMovil(true)
      setErrorPaso3(true)
    } else {
      setErrorMovil(false)
    }
    if (valorPuesto.length == 0) {
      formHasError = true
      setErrorPuesto(true)
      setErrorPaso3(true)
    } else {
      setErrorPuesto(false)
    }
    if (valorCp.length != 5) {
      formHasError = true
      setErrorCP(true)
      setErrorPaso3(true)
    } else {
      setErrorCP(false)
    }
    console.log('intereses::', intereses)
    console.log('intereses::', intereses.length)

    if (intereses.length <= 0) {
      formHasError = true
      setErrorIntereses(true)
      setErrorPaso3(true)
    } else {
      setErrorIntereses(false)
    }


    if (formHasError) return
    !formHasError && setValorsteep(valorSteep + 1)
  }

  function titleCase(str) {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
  }
  function findWordInCargo(str) {
    let cleanCargo = [];
    var words = str.split(' ');
    words.map(word => {
      if (word === 'De') {
        word = 'de';
      }
      if (word === 'Y') {
        word = 'y';
      }
      cleanCargo.push(word)
    })
    cleanCargo = cleanCargo.join(' ')
    return cleanCargo;
  }

  const FinalizaInscripcion = async () => {
    setisclick(true)
    let errorcheckterminos = false
    if (terminosRef.current.checked !== true) {
      errorcheckterminos = true
      setErrorAviso(true)
    }else{
      setErrorAviso(false)
    }

    if (terminosRef2.current.checked !== true) {
      errorcheckterminos = true
      setErrorAviso2(true)
    }else{
      setErrorAviso2(false)
      
    }

    if (valorPass.length <= 5) {
      errorcheckterminos = true
      setErrorPass(true)
    } else {
      setErrorPass(false)
    }

    if( errorcheckterminos == true){
      return
    }
    
    let formHasError = false;
    const telefonoRegex = /^[0-9]*$/;

    if (valorNombre.length == 0) {
      formHasError = true
      setErrorNombre(true)
      setErrorPaso3(true)
    } else {
      setErrorNombre(false)
    }
    if (valorApellidos.length == 0) {
      formHasError = true
      setErrorapellidos(true)
      setErrorPaso3(true)
    } else {
      setErrorapellidos(false)
    }
    if (valorDNI.length != 9) {
      formHasError = true
      setErrorDni(true)
      setErrorPaso3(true)
    } else {
      setErrorDni(false)
    }
    if (valorEmpresa.length == 0) {
      formHasError = true
      setErrorEmpresa(true)
      setErrorPaso3(true)
    } else {
      setErrorEmpresa(false)
    }
    if (cnaeCode != null && cnaeCode.length == 0) {
      //formHasError = true
      //setErrorCnae(true)
      //setErrorPaso3(true)
      setErrorCnae(false) //ahora no es obligatorio
    } else {
      setErrorCnae(false)
    }
    if (valorFacturacion === '- Elige una opción -') {
      //formHasError = true
      //setErrorFacturacion(true)
      //setErrorPaso3(true)
      setErrorFacturacion(false) //ahora no es obligatorio
    } else {
      setErrorFacturacion(false)
    }
    if (valorEmpleados === '- Elige una opción -') {
      //formHasError = true
      //setErrorEmpleados(true)
      //setErrorPaso3(true)
      setErrorEmpleados(false) //ahora no es obligatorio
    } else {
      setErrorEmpleados(false)
    }
    if (valorFacturacion === 'sin determinar') {
      formHasError = true
      setErrorFacturacion(true)
      setErrorPaso3(true)
    } else {
      setErrorFacturacion(false)
    }
    if (valorEmpleados === 'sin determinar') {
      formHasError = true
      setErrorEmpleados(true)
      setErrorPaso3(true)
    } else {
      setErrorEmpleados(false)
    }
    if (valorTelefono.length <= 7 || valorTelefono.length >= 14 || !telefonoRegex.test(valorTelefono)) {
      formHasError = true
      setErrorTelefono(true)
      setErrorPaso3(true)
    } else {
      setErrorTelefono(false)
    }
    if (valorMovil.length >= 14 || !telefonoRegex.test(valorMovil)) {
      formHasError = true
      setErrorMovil(true)
      setErrorPaso3(true)
    } else {
      setErrorMovil(false)
    }
    if (valorPuesto.length == 0) {
      formHasError = true
      setErrorPuesto(true)
      setErrorPaso3(true)
    } else {
      setErrorPuesto(false)
    }
    if (valorCp.length != 5) {
      formHasError = true
      setErrorCP(true)
      setErrorPaso3(true)
    } else {
      setErrorCP(false)
    }
    console.log('intereses::', intereses)
    console.log('intereses::', intereses.length)

    if (intereses.length <= 0) {
      formHasError = true
      setErrorIntereses(true)
      setErrorPaso3(true)
    } else {
      setErrorIntereses(false)
    }


    if (formHasError){
      setisclick(false)
      return
    } 
    try {
      var dataForm = {
        "Email": props.valorEmail.toLowerCase(),
        "usuario": titleCase(valorNombre),
        "Password": valorPass,
        "Nombre": titleCase(valorNombre),
        "Apellidos": titleCase(valorApellidos),
        "CifEmpresa": valorDNI,
        "NombreEmpresa": valorEmpresa.toUpperCase(),
        "Puesto": findWordInCargo(titleCase(valorPuesto)),
        "Intereses": intereses,
        "TelefonoEmpresa": valorTelefono.split(" ").join(""),
        "movil": valorMovil.split(" ").join(""),
        "CPEmpresa": valorCp,
        "Cnae": cnaeCode,
        "DescripcionCnae": cnaeDescription,
        "Facturacion": valorFacturacion,
        "Empleados": valorEmpleados,
        "enlaceReturn": window.location.pathname,
        "CodigoEvento": props.idEvento
      }
      let axiosConfig = {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      };
 
      await axios.post(`${process.env.REACT_APP_URL_API}auth/RegistroWebBotonInscribirse`, dataForm, axiosConfig)
        .then(resLogin => {
          if (resLogin.status === 200) {
            if (resLogin.data === 'error') {
              console.log(resLogin.data)
              setformIsInvalid(true)
              messageLoginChange('Email ya existe')
              setisclick(false)
              setValorsteep(1)
            } else {
              console.log('registrado:: form',dataForm)
              console.log('registrado:: 02',resLogin.data)
              setErrorPaso3(false)
              setformIsInvalid(false)
              setusuarioRegistrado(true)
              setQuitarPaso(true)
              setValorsteep(3)
            }
          } else {
            console.log(resLogin)
            setformIsInvalid(true)
            messageLoginChange('Email ya existe')
            setisclick(false)
            setValorsteep(1)
          }

        })
      

    } catch (error) {
      messageLoginChange('Ha habido un error, intentelo de nuevo más tarde')
      setisclick(false)
      setformIsInvalid(true)
    }
  }

  if (!sectores) { return (<div></div>) }

  return (
    <div className={`baseModal ${props.mostrarModal ? 'open' : ''}`}>
      <div className="modalBody modalRegistro modalRegistroPresencial">
        <p className="modalRegistro_cerrar" onClick={() => CerrarModal()}> cerrar x</p>
        <div className='modalHeader'>
        {props.noMostrarFecha === false &&
            <div className='fecha'>
              <div className="mes">{props.month}</div>
              <div className="dia">{props.day}</div>
              <div className="anyo">{props.year}</div>
            </div>
          }
            <div className={props.noMostrarFecha === true ? 'info no-fecha' :'info' }>
              <div className="titulo">{props.nombree}</div>
              <div className="subtitulo">{props.lugare}</div>
            </div>
          </div>
        <div className="modalLogin_logo">
          <img src="https://businessplus.es/assets/imgpruebas/logotipo.svg" />
        </div>
        {
          !quitarPaso ?
            <div className="small">PASO {valorSteep} DE 2</div>
            : null
        }
        
         {
          valorSteep == 3 &&
          <div>
            <div className="titulo">Activa tu cuenta en Business+ y finaliza tu inscripción</div>
            <p>Te hemos <b>enviado un email</b> para <b>confirmar tu inscripción</b> a este evento y <b>activar tu cuenta</b> de usuario en nuestra plataforma. Por favor, revisa tu bandeja de entrada, correo no deseado o spam.</p>
            <br/>
            <p>Si tienes alguna duda o problema con la recepción de nuestros correos, puedes ponerte en contacto con nosotros escribiendo a incidencias@businessplus.es</p>
            <button className="boton_registrarse" onClick={() => CerrarModal()} >Cerrar</button>
          </div>
        }

        {
          valorSteep == 2 &&
          <div>
            <div className="titulo">Estás a punto de inscribirte</div>
            <div className="step3_form_title"><span>Para completar tu inscripción,</span> completa tu registro en Business+</div>
            <label>Crea tu contraseña</label>
            <input className={errorPass ? "inputText hasError" : "inputText"} type={showPass ? 'text' : 'password'} onChange={passChange}></input>
            {
              showPass ?
                <span toggle="#password-field" class="icono-input"><AiFillEye onClick={showPassword} /></span>
                :
                <span toggle="#password-field" class="icono-input"><AiFillEyeInvisible onClick={showPassword} /></span>
            }
            <div className="small no-margin">Utiliza un mínimo de 6 caracteres (distingue entre mayúsculas y minúsculas). Incluye algún número o carácter especial.</div>
            {errorPass && <div className="mensajeError mt-10">La contraseña no cumple el patron de seguridad</div>}
            <div className="step3_form_title mt-20 mb-20"><span>Para confirmar tu inscripción,</span> y que sea de forma gratuita, acepta las siguientes condiciones:</div>
            <div className="form-aviso">
              <input type="checkbox" className={errorAviso ? "hasErrorCheck" : ""} ref={terminosRef} />
              <label className="small"> He leído y acepto la información sobre el tratamiento de datos personales en la <a  onClick={() => props.setShowTextoLegal("flex")}>política de privacidad</a> para mi alta en la plataforma y mi inscripción a este evento/contenido.</label>
              {errorAviso && <div className="mensajeError">Debe aceptar los términos y condiciones de la política de privacidad</div>}
            </div>
            <div className="form-aviso">
              <input type="checkbox" className={errorAviso2 ? "hasErrorCheck" : ""} ref={terminosRef2} />
              <label className="small"> Deseo recibir comunicaciones de carácter informativo y comercial, incluso por medios electrónicos, por parte de cada uno de los patrocinadores/colaboradores conforme figuran en la <a  onClick={() => props.setShowTextoLegal("flex")}>política de privacidad</a>  de este evento/contenido.</label>
              {errorAviso2 && <div className="mensajeError">Debe aceptar esta casilla</div>}
            </div>
            <button className="boton_registrarse" disabled={isclick ? true : false} onClick={() => FinalizaInscripcion()} >Finalizar</button>
          </div>
        }
        {
          valorSteep == 1 &&
          <div className="step3 step3Inscripcion">
          
              <div>
                <ModalCnae show={showCnaeModal} pickCnaeFunction={pickCnae} setshowCnaeModal={setshowCnaeModal} />
                <div className="titulo">Formulario de inscripción</div>
                <label className="step3_form_title"><span>Rellena</span> el siguiente formulario con tus datos profesionales:</label>

                <div className="row row1_form_separacion">
                  <div className="form-group col-2">
                    <label className="small">Nombre*</label>
                    <input className={errorNombre ? "inputText hasError" : "inputText"} type="text" onChange={nombreChange}></input>
                    {errorNombre && <div className="mensajeError">Campo obligatorio</div>}
                  </div>
                  <div className="form-group col-2">
                    <label className="small">Apellidos*</label>
                    <input className={errorApellidos ? "inputText hasError" : "inputText"} type="text" onChange={apellidosChange}></input>
                    {errorApellidos && <div className="mensajeError">Campo obligatorio</div>}
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-1 step3_col_ancho">
                    <label className="small">Cargo*</label>
                    <input className={errorPuesto ? "inputText hasError" : "inputText"} type="text" onChange={puestoChange}></input>
                    {errorPuesto && <div className="mensajeError">Campo obligatorio</div>}
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-1 step3_col_ancho">
                    <label className="small">CIF*</label>
                    <input className={errorDni ? "inputText hasError" : "inputText"} type="text" onChange={dniChange}></input>
                    {errorDni && <div className="mensajeError">El CIF debe ser un texto alfanumérico de 9 caracteres, sin guiones</div>}
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-1 step3_checkbox">
                    <input type="checkbox" className={errorAviso ? "hasErrorCheck" : ""} ref={nombreFiscalRef} onClick={() => handleAutonomo()} />
                    <label className="small checkbox_text ">Marca esta casilla si eres autónomo y no dispones de nombre fiscal de empresa</label>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-1 step3_col_ancho">
                    <label className="small">Nombre fiscal de empresa*</label>
                    <input className={errorEmpresa ? "inputText inputEmpresa hasError" : "inputText inputEmpresa"} type="text" onChange={empresaChange} value={valorEmpresa}></input>
                    {errorEmpresa && <div className="mensajeError">Campo obligatorio</div>}
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-1 step3_col_ancho">
                    <label className="small">CNAE</label>
                    <p>Si no conoces tu CNAE, puedes usar nuestro buscador de actividades para localizarlo. Para activarlo pincha en la lupa</p>
                    <div className="step3_selector">
                      <div className="step3_selector_input">
                        <input className={errorCnae ? "inputText hasError" : "inputText"} type="text" value={cnaeCode} onClick={() => openModalCnae()} onChange={cnaeChange}></input>
                        {errorCnae && <div className="mensajeError">Campo obligatorio</div>}
                      </div>
                      <div className="step3_selector_icon" onClick={() => openModalCnae()}>
                        <span class="material-icons-outlined">search</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-1 step3_col_ancho">
                    <label className="small">Facturación</label>
                    <SelectorInput
                      options={["De 0 a 1.5 Millones de Euros", "De 1.5 a 2.5 Millones de Euros", "De 2.5 a 5 Millones de Euros", "De 5 a 25 Millones de Euros", "Más de 50 Millones de Euros"]}
                      id="facturacionRegistroPresencial"
                      selectedValue={valorFacturacion}
                      setSelectedValue={setValorFacturacion}
                      error={errorFacturacion}
                    />
                    {errorFacturacion && <div className="mensajeError2">Elige una opción</div>}
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-1 step3_col_ancho">
                    <label className="small">Nº de empleados</label>
                    <SelectorInput
                      options={["de 1 a 10", "de 11 a 50", "de 51 a 100", "más de 100"]}
                      id="empleadosRegistroPresencial"
                      selectedValue={valorEmpleados}
                      setSelectedValue={setValorEmpleados}
                      error={errorEmpleados}
                    />
                    {errorEmpleados && <div className="mensajeError2">Elige una opción</div>}
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-2">
                    <label className="small">Teléfono profesional*</label>
                    <input className={errorTelefono ? "inputText hasError" : "inputText"} type="text" onChange={telefonoChange} value={valorTelefono}></input>
                    {errorTelefono && <div className="mensajeError">Campo obligatorio</div>}
                  </div>
                  <div className="form-group col-2">
                    <label className="small">Teléfono móvil (Opcional)</label>
                    <input className={errorMovil ? "inputText hasError" : "inputText"} type="text" onChange={movilChange} value={valorMovil}></input>
                    {errorMovil && <div className="mensajeError">Campo obligatorio</div>}
                  </div>
                  <div className="form-group col-1 step3_checkbox">
                    <input type="checkbox" className={errorAviso ? "hasErrorCheck" : ""} ref={residenteEspanaRef} onClick={() => handleResidente()} />
                    <label className="small checkbox_text ">Marca esta casilla si NO resides en España</label>
                  </div>
                  <div className="form-group col-2">
                    <label className="small">Código Postal*</label>
                    <input className={errorCP ? "inputText hasError" : "inputText"} type="text" onChange={cpChange} value={valorCp}></input>
                    {errorCP && <div className="mensajeError">Introduce un código postal de 5 dígitos válido</div>}
                  </div>
                </div>
                <div className="row step3_etiquetas">
                  <div className="form-group col-1">
                    <label className="small">Intereses (elige al menos una)*</label>
                  </div>
                  <div className="step3_etiquetas_items">
                    {sectores.map(item =>
                      cleanIntereses.includes(item.idCategoria) ?
                        <div className="small labelckeck selected" onClick={(e) => { marcarIntereses('del', item.idCategoria) }} id={`etiqueta-${item.idCategoria}`} > {item.descripcion}</div>
                        :
                        <div className="small labelckeck" onClick={(e) => { marcarIntereses('add', item.idCategoria) }} id={`etiqueta-${item.idCategoria}`} >{item.descripcion}</div>
                    )}
                  </div>

                  {errorIntereses && <div className="mensajeError">Selecciona al menos un interés</div>}
                </div>
                <button className="boton_registrarse" onClick={() => validaPasoDos()}>INSCRIBIRME</button>
                <div className='leyendaForm'>*Campos obligatorios</div>
                {errorPaso3 && <div className="mensajeError">Revisa todos los campos</div>}
                {formIsInvalid && <div className="mensajeError" dangerouslySetInnerHTML={{ __html: messageLogin }} />}
              </div>
          
          </div>

        }
      </div>
    </div>
  )
}