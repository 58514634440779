import React, { Fragment, useState, useEffect } from 'react'
import axios from 'axios';
import { useHistory } from "react-router-dom";
export const Activarcuenta = (props) => {

    const history = useHistory();
    const [textoInfo, setTextoInfo] = useState(false)
    let claveUsuario = window.location.search.split('code=')[1].split('&')[0]
    let url = window.location.search.split('enlaceReturn=')[1]
    console.log('url::',url)
    

    const fetchActivarCuenta = async () => {
        let dataFormEventos = {
            "claveUsuario": claveUsuario
        }

        let axiosConfig = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        };
        if(url == '' || url == undefined || url == null){url = '/'}
        axios.post(`${process.env.REACT_APP_URL_API}auth/VerificarRegistro`, dataFormEventos, axiosConfig)
            .then((res) => {
                history.push(url)
                console.log(res)
                setTextoInfo('cuenta activada')
                return loginMail()
            })
            .catch((err) => {
                history.push(url)
                setTextoInfo('Cod invalido no se ha podido activar la cuenta')
                console.log(err)
            })
    }
    const loginMail = async () => {
        let dataFormEventos = {
            "claveUsuario": claveUsuario
        }

        let axiosConfig = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        };
        await axios.post(`${process.env.REACT_APP_URL_API}auth/LoginSF`, dataFormEventos, axiosConfig)
            .then((resLogin) => {
                console.log(resLogin);
                let loginTime = new Date().getTime();
                let limitTime = new Date().getTime() + 86400000;
                localStorage.setItem(`${process.env.REACT_APP_ID_STORAGE}-loginTime`, loginTime)
                localStorage.setItem(`${process.env.REACT_APP_ID_STORAGE}-limitTime`, limitTime)
                localStorage.setItem(`${process.env.REACT_APP_ID_STORAGE}-idu`, resLogin.data.data.idUsuario)
                localStorage.setItem(`${process.env.REACT_APP_ID_STORAGE}-nombre`, resLogin.data.data.usuario)
                localStorage.setItem(`${process.env.REACT_APP_ID_STORAGE}-token`, resLogin.data.data.token)
                localStorage.setItem(`${process.env.REACT_APP_ID_STORAGE}-id`, resLogin.data.data.claveUsuario)
                localStorage.setItem(`${process.env.REACT_APP_ID_STORAGE}-email`, resLogin.data.data.email)
            })
            .then((res) => {
                props.setMostrarModalActivacion(true)
            })
            .catch((err) => {
                console.log(err)
            })
    }



    useEffect(() => {
        fetchActivarCuenta();
    }, [])

    if (!textoInfo) { return (<div></div>) }
    return (
        <div></div>
    )

}