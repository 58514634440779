import React, { useState, useRef, useEffect, Fragment } from 'react';

import './estilos.css';

export const ModalTerminosUsuarioRegistrado = (props) => {

    const terminosRef = useRef();
    const terminosRef2 = useRef();

    let fechaEvento = props.dataEvento.fechaEvento;
    let year = fechaEvento.split("-")[0];
    let month = fechaEvento.split("-")[1];
    if (month.substr(0, 1) == "0") {
        month = month.substr(1);
    }
    month = month - 1;
    let day = fechaEvento.split("-")[2].split("T")[0];
    let monthNames = ["En", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ag", "Sept", "Oct", "Nov", "Dic"];
    let monthName = monthNames[month];

    if (day < 10) {
        day = day.split('0')[1]
    }


    const [errorAviso, setErrorAviso] = useState(false)
    const [errorAviso2, setErrorAviso2] = useState(false)

    const FinalizaInscripcion = async () => {
        let errorcheckterminos = false
        if (terminosRef.current.checked !== true) {
          errorcheckterminos = true
          setErrorAviso(true)
        }else{
          setErrorAviso(false)
        }
    
        if (terminosRef2.current.checked !== true) {
          errorcheckterminos = true
          setErrorAviso2(true)
        }else{
          setErrorAviso2(false)
          
        }
    
        if( errorcheckterminos == true){
          return
        }else{
           
            props.inscribirseEventoPresencial()
           
        }
    }

    return (
        <div className={`baseModal modalRegistroInscripcion ${props.mostrarModalTerminosU ? 'open' : ''}`}>
            
          <div className="modalBody modalXbody">
            <p className="modalLoginInscripcion_cerrar" onClick={() => props.closeModal(false)}>cerrar x</p>
            <div className='modalHeader'>
              {props.dataEvento.noMostrarEmailNiFecha === false &&
              <div className='fecha'>
                <div className="mes">{monthName}</div>
                <div className="dia">{day}</div>
                <div className="anyo">{year}</div>
              </div>
              }
              <div className={props.dataEvento.noMostrarEmailNiFecha === true ? 'info no-fecha' :'info' }>
                <div className="titulo">{props.dataEvento.nombre}</div>
                <div className="subtitulo">{props.dataEvento.lugarEvento}</div>
              </div>
            </div>
            <div className="modalXbody_logo">
              <img src="https://businessplus.es/assets/imgpruebas/logotipo.svg" />
            </div>
            <div className="row modalX" >
                <div className="titulo">Estás a punto de inscribirte</div>
                <div className="step3_form_title"><span>Para confirmar tu inscripción,</span> y que sea de forma gratuita, acepta las siguientes condiciones:</div>
                
                <div className="form-aviso">
                <input type="checkbox" className={errorAviso ? "hasErrorCheck" : ""} ref={terminosRef} />
                <label className="small"> He leído y acepto la información sobre el tratamiento de datos personales en la <a  onClick={() => props.setShowTextoLegal("flex")}>política de privacidad</a> para mi alta en la plataforma y mi inscripción a este evento/contenido.</label>
                {errorAviso && <div className="mensajeError">Debe aceptar los términos y condiciones de la política de privacidad</div>}
                </div>
                <div className="form-aviso">
                <input type="checkbox" className={errorAviso2 ? "hasErrorCheck" : ""} ref={terminosRef2} /> 
                <label className="small"> Deseo recibir comunicaciones de carácter informativo y comercial, incluso por medios electrónicos, por parte de cada uno de los patrocinadores/colaboradores conforme figuran en la <a  onClick={() => props.setShowTextoLegal("flex")}>política de privacidad</a>  de este evento/contenido.</label>
                {errorAviso2 && <div className="mensajeError">Debe aceptar esta casilla</div>}
                </div>
                <button className="boton_registrarse" onClick={() => FinalizaInscripcion()} >Finalizar</button>
            </div >
          </div>
        </div>
    )
}