import React, { useState, useRef } from 'react';
import axios from 'axios';

import './estilos.css';

export const ModalSettingsConfirmation = (props) => {
    return (
        <div className="modalsettings" style={{ display: props.show }}>
            <div className="modalsettings_body">
                <p className="modalsettings_cerrar" onClick={() => props.closeFunction()}>cerrar x</p>
                <div className="modalsettings_body_logo">
                    <h1>Business<span>+</span> </h1>
                </div>
                <div className="modalsettings_confirmation">
                    <h2>{props.mainMsg}</h2>
                    <p>{props.scndMsg}</p>
                    <p>{props.thrdMsg}</p>
                    <p>{props.frthMsg}</p>
                    {
                        props.isDeleted === true ?
                            <button onClick={() => props.closeFunction()}>Cerrar</button>
                            :
                            <button onClick={() => props.closeFunction()}>Cerrar</button>
                    }
                </div>
            </div>
        </div>
    )
}