import React, { Fragment, useState, useEffect, useContext } from 'react'
import axios from 'axios';
import { useHistory } from "react-router-dom";

export const LoginEvento = (props) => {
    const history = useHistory();
    const [textoInfo, setTextoInfo] = useState(false);
    const [code, setCode] = useState();
    let enlaceWebinar = window.location.search.split('enlaceWebinar=')[1];

    function checkUrl() {
        if (window.location.href.includes('code=')) {
            setCode(window.location.search.split('code=')[1].split('&')[0])
            localStorage.setItem(`${process.env.REACT_APP_ID_STORAGE}-recordatorio`, true)
        }
        if (window.location.href.includes('codInvitacion=')) {
            localStorage.setItem(`${process.env.REACT_APP_ID_STORAGE}-codeInvitacion`, window.location.search.split('codInvitacion=')[1].split('&')[0])
        }
    }

    const loginMail = async () => {
        let dataFormEventos = {
            "claveUsuario": code
        }

        let axiosConfig = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        };
        await axios.post(`${process.env.REACT_APP_URL_API}auth/LoginSF`, dataFormEventos, axiosConfig)
            .then((resLogin) => {
                console.log(resLogin);
                let loginTime = new Date().getTime();
                let limitTime = new Date().getTime() + 86400000;
                localStorage.setItem(`${process.env.REACT_APP_ID_STORAGE}-loginTime`, loginTime)
                localStorage.setItem(`${process.env.REACT_APP_ID_STORAGE}-limitTime`, limitTime)
                localStorage.setItem(`${process.env.REACT_APP_ID_STORAGE}-idu`, resLogin.data.data.idUsuario)
                localStorage.setItem(`${process.env.REACT_APP_ID_STORAGE}-nombre`, resLogin.data.data.usuario)
                localStorage.setItem(`${process.env.REACT_APP_ID_STORAGE}-token`, resLogin.data.data.token)
                localStorage.setItem(`${process.env.REACT_APP_ID_STORAGE}-id`, resLogin.data.data.claveUsuario)
                localStorage.setItem(`${process.env.REACT_APP_ID_STORAGE}-email`, resLogin.data.data.email)

                props.setLogEmail(true)
                history.push(enlaceWebinar)
                window.location.reload()
            })
            .catch((err) => {
                history.push(enlaceWebinar)
                console.log(err)
            })
    }

    useEffect(() => {
        checkUrl();
    }, loginMail())

    if (!textoInfo) { return (<div></div>) }
    return (
        <div></div>
    )

}