import React, { Component, useRef, useState } from 'react';
import { AiFillEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";
import axios from 'axios';
import Swal from 'sweetalert2';

import { Botonbase } from "../../botonbase";
import { ModalSettingsCom } from "../../modalSettingsCom";
import { ModalSettingsCuenta } from "../../modalSettingsCuenta";
import './estilos.css';

export const ContenidoCambiarContrasena = (props) => {
  const [newPass, setNewPass] = useState(null);
  const [checkPass, setCheckPass] = useState(null);
  const [PassError, setPassError] = useState(false);
  const passChange = (val) => {
    setNewPass(val.target.value)
    setPassError(false)
  }
  const checkChange = (val) => {
    setCheckPass(val.target.value)
    setPassError(false)
  }

  const [showPass, setshowPass] = useState(false);
  function showPassword() {
    if (showPass) {
      setshowPass(false)
    } else {
      setshowPass(true)
    }
  }
  const [showCheck, setShowCheck] = useState(false);
  function showCheckPassword() {
    if (showCheck) {
      setShowCheck(false)
    } else {
      setShowCheck(true)
    }
  }

  const [showModalSettingsCom, setShowModalSettingsCom] = useState("none");
  const [showModalSettingsCuenta, setShowModalSettingsCuenta] = useState("none");

  function closeModalSettingsCom() {
    setShowModalSettingsCom("none")
  }
  function closeModalSettingsCuenta() {
    setShowModalSettingsCuenta("none")
  }

  const changePassword = async () => {
    if (newPass == checkPass) {
      try {
        var dataForm = {
          "claveUsuario": localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-id`),
          "password": newPass
        }
        let axiosConfig = {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        };
        await axios.post(`${process.env.REACT_APP_URL_API}auth/ModificarPassword`, dataForm, axiosConfig)
          .then(resLogin => {
            if (resLogin.status === 200) {
              if (resLogin.data === 'error') {
                console.log(resLogin.data);
              } else {
                Swal.fire({
                  title: '',
                  text: `Contraseña actualizada`,
                  icon: 'success',
                  showCancelButton: false,
                  confirmButtonColor: '#42A72C',
                  confirmButtonText: 'Aceptar',
                })
              }
            }
          })

      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("NONONO");
      setPassError(true);
    }
  }

  return (
    <>
      <ModalSettingsCom show={showModalSettingsCom} closeFunction={closeModalSettingsCom}></ModalSettingsCom>
      <ModalSettingsCuenta show={showModalSettingsCuenta} closeFunction={closeModalSettingsCuenta} removeAuth={props.removeAuth}></ModalSettingsCuenta>
      <div className="contenido_datosusuario2">
        <div className="contenido_datosusuario_form">
          <div className="titulo titulo_seguridad">¿Quieres modicar tu contraseña?</div>
          <div className="formulario-cuenta">
            <div className="form-group">
              <label className="small">Crear contraseña nueva</label>
              <input className={PassError ? "inputText hasError" : "inputText"} type={showPass ? 'text' : 'password'} onChange={passChange} ></input>
              {
                showPass ?
                  <span toggle="#password-field" class="icono-input"><AiFillEye onClick={showPassword} /></span>
                  :
                  <span toggle="#password-field" class="icono-input"><AiFillEyeInvisible onClick={showPassword} /></span>
              }
              <div className="small">Utiliza un mínimo de 6 caracteres (distingue entre mayúsculas y minúsculas). Incluye algún número o carácter especial.</div>
            </div>
            <div className="form-group">
              <label className="small">Repetir contraseña nueva</label>
              <input className={PassError ? "inputText hasError" : "inputText"} type={showCheck ? 'text' : 'password'} onChange={checkChange}></input>
              {
                showCheck ?
                  <span toggle="#password-field" class="icono-input"><AiFillEye onClick={showCheckPassword} /></span>
                  :
                  <span toggle="#password-field" class="icono-input"><AiFillEyeInvisible onClick={showCheckPassword} /></span>
              }
              {PassError && <div className="mensajeError">La contraseña no coincide en ambos campos</div>}
            </div>
            <Botonbase accion={changePassword} clase='formulario' texto={'Guardar los cambios'} />
          </div>
        </div>
        <div className="cuenta_settings">
           <div className="cuenta_settings_item">
            <h3>Preferencias de comunicación</h3>
            <div className="cuenta_settings_item_selector">
              <p>Gestiona las diferentes comunicaciones de Business+ que recibes en tu email.</p>
              <div className="cuenta_settings_item_selector_icon">
                <span class="material-icons-outlined" onClick={() => setShowModalSettingsCom("flex")}>settings</span>
              </div>
            </div>
          </div> 
          <div className="cuenta_settings_item">
            <h3>Configuración de cuenta</h3>
            <div className="cuenta_settings_item_selector">
              <p>Si quieres dejar de usar Business+ durante un tiempo. Puedes desactivar tu cuenta. Si quieres eliminarla definitivamente, avisanos</p>
              <div className="cuenta_settings_item_selector_icon">
                <span class="material-icons-outlined" onClick={() => setShowModalSettingsCuenta("flex")}>settings</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}