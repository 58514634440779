import React, { Component, useContext } from 'react';
import GuardarLogContext from '../../ContextLog'
import { Botonbase } from "../botonbase";
import { CajaItemEventoCiudad } from "../cajaItemEventoCiudad";
import './estilos.css'; // Tell webpack that Button.js uses these styles

export const GridEventosCiudades = (props) => {
  const log = useContext(GuardarLogContext)
  const HandleClick = () => {
    log.guardarLog('click btn 1')
  }
  return (
    <>
      <div className="grid-container">
        {

          props.eventosCiudades.map(evento =>
            <div className="grid-item"><CajaItemEventoCiudad ciudad={evento.ciudad} fecha={evento.fechaEvento} lugar={evento.lugarEvento} link={evento.enlace} /></div>
          )
        }
      </div>
    </>
  )
}