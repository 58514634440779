import React, { Fragment } from 'react';
import { Helmet } from "react-helmet";
import StackGrid from "react-stack-grid";

import { Header } from "../../componentes/header/index";
import { Footer } from "../../componentes/footer";
import { CargaContenido } from "../../componentes/cargaContenido";
import { ScrollToTop } from '../../componentes/scrollToTop';
import './estilos.css';

export const StandVirtual = (props) => {
    return (
        <>
            {
                props.seoContenido !== null &&
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="description" content={props.seoContenido.seoDescription} />
                    <title>{props.seoContenido.seoTitle}</title>
                    <meta property="og:title" content={props.seoContenido.seoTitle} />
                    <meta property="og:description" content={props.seoContenido.seoDescription} />
                    <meta property="og:locale" content="es_ES" />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:site_name" content="Business+"></meta>
                    <meta property="article:modified_time" content={props.seoContenido.fechaModificacion}></meta>
                    <meta property="og:image" content={props.seoContenido.seoImage} />
                    <meta property="og:image:width" content={props.seoContenido.seoImageWidth} />
                    <meta property="og:image:height" content={props.seoContenido.seoImageHeight} />
                    <meta name="twitter:title" content={props.seoContenido.seoTitle} />
                    <meta name="twitter:description" content={props.seoContenido.seoDescription} />
                    <meta name="twitter:image" content={props.seoContenido.seoImage} />
                </Helmet>
            }

            <Header logo={props.zonasContenido[0].texto} data={props.zonasContenido} />
            <div className="stand">
                <div className="stand_main">

                    <div className="stand_lateral_caja stand_lateral_logo stand_responsive">
                        {

                            props.zonasContenido.map(item =>
                                <Fragment>
                                    {item.idZona === 4 &&
                                        <CargaContenido item={item} tipo={item.tipoZona} />
                                    }
                                </Fragment>
                            )

                        }
                    </div>

                    <div className="stand_main_banner">
                        {

                            props.zonasContenido.map(item =>
                                <Fragment>
                                    {item.idZona === 3 &&
                                        <CargaContenido item={item} tipo={item.tipoZona} />
                                    }
                                </Fragment>
                            )

                        }
                    </div>
                    <div className="stand_main_texto">
                        {

                            props.zonasContenido.map(item =>
                                <Fragment>
                                    {item.idZona === 5 &&
                                        <CargaContenido item={item} tipo={item.tipoZona} />
                                    }
                                </Fragment>
                            )

                        }
                    </div>


                    {
                        props.zonasContenido.map(item =>
                            <Fragment>
                                {item.idZona === 6 && item.tipoZona === 'video' &&
                                    <div className="stand_lateral_caja stand_video stand_responsive">
                                        <h3>Video Presentación</h3>
                                        <div className="stand_lateral_imagen">
                                            <div dangerouslySetInnerHTML={{ __html: item.texto }} />
                                        </div>
                                    </div>
                                }
                            </Fragment>
                        )
                    }

                    <div className="stand_main_next">
                        {
                            props.zonasContenido.map(item =>
                                item.idZona === 7 &&
                                <div className="stand_main_next_item">
                                    <h4>{item.titulo}</h4>
                                    <a href={item.enlace} target={item.target}>
                                        <img src={item.imagen} alt="" />
                                    </a>
                                </div>
                            )
                        }
                    </div>
                </div>
                <div className="stand_lateral">
                    <div className="stand_lateral_caja stand_lateral_logo">
                        {

                            props.zonasContenido.map(item =>
                                <Fragment>
                                    {item.idZona === 4 &&
                                        <CargaContenido item={item} tipo={item.tipoZona} />
                                    }
                                </Fragment>
                            )

                        }
                    </div>

                    {

                        props.zonasContenido.map(item =>
                            <Fragment>
                                {item.idZona === 6 && item.tipoZona === 'video' &&
                                    <div className="stand_lateral_caja stand_video">
                                        <h3>Video Presentación</h3>
                                        <div className="stand_lateral_imagen">
                                            <div dangerouslySetInnerHTML={{ __html: item.texto }} />
                                        </div>
                                    </div>
                                }
                            </Fragment>
                        )

                    }

                    <div className="stand_lateral_caja">
                        <h3>Contacto</h3>
                        <div className="stand_lateral_caja_contacto">
                            {

                                props.zonasContenido.map(item =>
                                    <Fragment>
                                        {item.idZona === 8 &&
                                            <CargaContenido item={item} tipo={item.tipoZona} />
                                        }
                                    </Fragment>
                                )

                            }
                        </div>
                    </div>
                </div>
            </div>
            <Footer logo={props.zonasContenido[0].texto} data={props.zonasContenido} />
            <ScrollToTop />
        </>
    )
}