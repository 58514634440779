import React, { useState, useRef, Fragment } from 'react';
import axios from 'axios';

import { ModalSettingsConfirmation } from "../modalSettingsConfirmation";

export const ModalSettingsCuenta = (props) => {
    const desactivarRef = useRef();
    const deleteRef = useRef();
    const [showModalSettingsConfirmation, setShowModalSettingsConfirmation] = useState("none");
    const [mainMsg, setMainMsg] = useState("No ha escogido ninguna opción");
    const [scndMsg, setScndMsg] = useState("Escoge alguna opción");
    const [thrdMsg, setThrdMsg] = useState('');
    const [frthMsg, setFrthMsg] = useState('');
    const [isDeleted, setIsDeleted] = useState(false);
    function closeModalSettingsConfirmation() {
        setShowModalSettingsConfirmation("none");
    }
    const changeModalSettingsCuenta = async () => {
        if (deleteRef.current.checked === false && desactivarRef.current.checked === true) {
            let dataForm = {
                "claveUsuario": localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-id`),
                "permanente": false
            }
            let axiosConfig = {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-token`)}`
                },
            };
            axios.post(`${process.env.REACT_APP_URL_API}usuarios/PeticionDesactivarUsuario`, dataForm, axiosConfig)
                .then((res) => {
                    console.log("res: ", res);
                    console.log("DESACTIVATED ACCOUNT");
                    setMainMsg("Desactivando cuenta...");
                    setScndMsg("Hola, por seguridad es necesario confirmar la desactivacion temporal de tu cuenta en el enlace que te hemos enviado por email. Recuerda que puedes volver a activar tu cuenta siempre que lo desees.");
                    setThrdMsg('Recuerda que puedes volver a activar tu cuenta siempre que lo desees.');
                    setFrthMsg('¡Esperamos verte otra vez por aqui pronto!');
                    setIsDeleted(true);
                    props.closeFunction();
                    setShowModalSettingsConfirmation("flex");
                })
                .catch((err) => {
                    console.log("err: ", err);
                    console.log("ERROR DELETING ACCOUNT");
                    setMainMsg("Ha habido un error");
                    setScndMsg("No se ha podido desactivar su cuenta. Intentelo de nuevo más tarde");
                    setThrdMsg('');
                    setFrthMsg('');
                    props.closeFunction();
                    setShowModalSettingsConfirmation("flex");
                })
        }
        if (deleteRef.current.checked === true) {
            let dataForm = {
                "claveUsuario": localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-id`),
                "permanente": true
            }
            let axiosConfig = {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-token`)}`
                },
            };
            axios.post(`${process.env.REACT_APP_URL_API}usuarios/PeticionDesactivarUsuario`, dataForm, axiosConfig)
                .then((res) => {
                    console.log("res: ", res);
                    console.log("DELETED ACCOUNT");
                    setMainMsg("Eliminando cuenta...");
                    setScndMsg("Hola, por seguridad es necesario confirmar la eliminación tu cuenta en el enlace que te hemos enviado por email.");
                    setThrdMsg('Recuerda que si eliminas tu cuenta, no podrás recuperar el contenido ni la información de tu perfil');
                    setFrthMsg('Nos gustaria volver a verte y por eso podrás crear una cuenta nueva y empezar de cero en Business+ siempre que lo desees.');
                    setIsDeleted(true);
                    props.closeFunction();
                    setShowModalSettingsConfirmation("flex");
                })
                .catch((err) => {
                    console.log("err: ", err);
                    console.log("ERROR DELETING ACCOUNT");
                    setMainMsg("Ha habido un error");
                    setScndMsg("No se ha podido eliminar su cuenta. Intentelo de nuevo más tarde");
                    setThrdMsg('');
                    setFrthMsg('');
                    props.closeFunction();
                    setShowModalSettingsConfirmation("flex");
                })
        }
        if (deleteRef.current.checked === false && desactivarRef.current.checked === false) {
            setMainMsg("Mantenemos sus preferencias");
            setScndMsg("Recuerde que puede cambiar sus preferencias siempre que quiera");
            setThrdMsg('');
            setFrthMsg('');
            props.closeFunction();
            setShowModalSettingsConfirmation("flex");
        }
    }
    function selectDesactivar() {
        deleteRef.current.checked = false;
    }
    function selectDelete() {
        desactivarRef.current.checked = false;
    }
    return (
        <Fragment>
            <ModalSettingsConfirmation
                show={showModalSettingsConfirmation}
                closeFunction={closeModalSettingsConfirmation}
                mainMsg={mainMsg}
                scndMsg={scndMsg}
                thrdMsg={thrdMsg}
                frthMsg={frthMsg}
                isDeleted={isDeleted}
                removeAuth={props.removeAuth}
            />
            <div className="modalsettings" style={{ display: props.show }}>
                <div className="modalsettings_body">
                    <p className="modalsettings_cerrar" onClick={() => props.closeFunction()}>cerrar x</p>
                    <div className="modalsettings_body_logo">
                        <h1>Business<span>+</span> </h1>
                    </div>
                    <div className="modalsettings_form">
                        <h2>Preferencias de cuenta</h2>
                        <div className="modalsettings_form_item">
                            <h3>Desactivar cuenta</h3>
                            <p>Esto puede ser temporal</p>
                            <input type="checkbox" id="check2" ref={desactivarRef} onClick={() => selectDesactivar()} /><label for="check2">Quiero conservar mi cuenta y actividad sin recibir comunicaciones por email.</label>
                        </div>
                        <div className="modalsettings_form_item">
                            <h3>Eliminar mi cuenta</h3>
                            <p>Esto es permanente</p>
                            <input type="checkbox" id="check2" ref={deleteRef} onClick={() => selectDelete()} /><label for="check2">Al eliminar tu cuenta, se desactivará tu pefil y se eliminará toda tu actividad. Si eliminas tu cuenta, no podrás recuperar el contenido ni la información.</label>
                        </div>
                        <button onClick={() => changeModalSettingsCuenta()} > Guardar los cambios</button>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}