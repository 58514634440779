import React from 'react';

import './estilos.css';

export const ModalTerminos = (props) => {
    return (
        <div className="modalterminos" style={{ display: props.show }} onClick={() => props.setShowTextoLegal("none")}>
            <div className="modalterminos_body">
                <div className='btn-cerrar-modal'  onClick={() => props.setShowTextoLegal("none")}>Cerrar x</div>
                <div className='modal-terminos-content'>
                    <div className="modalterminos-textbox" dangerouslySetInnerHTML={{ __html: props.textoLegal }} ></div>
                </div>
            </div>
        </div>
    )
}