import React, { useState, useRef, Fragment,useEffect } from 'react';
import axios from 'axios';

import { ModalSettingsConfirmation } from "../modalSettingsConfirmation";
import './estilos.css';

export const ModalSettingsCom = (props) => {

    const primerosRef = useRef();
    const tercerosRef = useRef();
    const [showModalSettingsConfirmation, setShowModalSettingsConfirmation] = useState("none");
    const [mainMsg, setMainMsg] = useState("No ha escogido ninguna opción");
    const [scndMsg, setScndMsg] = useState("Escoge alguna opción");

    const [datosUsuario, setDatosUsuario] = useState(false);
    const fetchSettings = async () => {
        try {

        let axiosConfig = {
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-token`)}`
            },
        };

        await axios.get(`${process.env.REACT_APP_URL_API}usuarios/${localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-idu`)}`, axiosConfig)
            .then(result => {
            result.data.data.data !== null ? setDatosUsuario(result.data.data) : setDatosUsuario('')
            primerosRef.current.checked =  result.data.data.particular
            tercerosRef.current.checked =  result.data.data.noEnviarComunicaciones

            })

        } catch (error) { 
            console.log('error Datos usuario', error)
        }
    };
    useEffect(() => {
        fetchSettings();
    }, []);


    
    function closeModalSettingsConfirmation() {
        setShowModalSettingsConfirmation("none")
    }
    function changeModalSettingsCom() {
        let dataForm = {
            "claveUsuario": localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-id`),
            "no_recibir_notificaciones": tercerosRef.current.checked,  
            "no_recibir_business": primerosRef.current.checked
        }
        let axiosConfig = {
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-token`)}`
            },
        };
        console.log('data rescom: ',dataForm)
        axios.post(`${process.env.REACT_APP_URL_API}usuarios/PreferenciasComunicacion`, dataForm, axiosConfig)
            .then((res) => {
                if (tercerosRef.current.checked === false && primerosRef.current.checked === true) {
                    setMainMsg("Preferencias modificadas");
                    setScndMsg("Hola, hemos modificado tus preferencias de comunicación y no recibirás más Business+ News. Recuerda que puedes volver a modificarlas siempre que lo desees");
                    props.closeFunction();
                    setShowModalSettingsConfirmation("flex");
                }
                if (primerosRef.current.checked === false && tercerosRef.current.checked === true) {
                    setMainMsg("Preferencias modificadas");
                    setScndMsg("Hola, hemos modificado tus preferencias de comunicación y no recibirás más comunicaciones de terceros. Recuerda que puedes volver a modificarlas siempre que lo desees");
                    props.closeFunction();
                    setShowModalSettingsConfirmation("flex");
                }
                if (primerosRef.current.checked === true && tercerosRef.current.checked === true) {
                    setMainMsg("Preferencias modificadas");
                    setScndMsg("Hola, hemos modificado tus preferencias de comunicación y no recibirás más Business+ News ni comunicaciones de terceros. Recuerda que puedes volver a modificarlas siempre que lo desees");
                    props.closeFunction();
                    setShowModalSettingsConfirmation("flex");
                }
                if (primerosRef.current.checked === false && tercerosRef.current.checked === false) {
                    setMainMsg("Mantenemos sus preferencias");
                    setScndMsg("Recuerde que puede cambiar sus preferencias siempre que quiera");
                    props.closeFunction();
                    setShowModalSettingsConfirmation("flex");
                }
            })
            .catch((err) => {
                console.log("err: ", err);
                console.log("ERROR DELETING ACCOUNT");
                
            })


        
    }
    return (
        <Fragment>
            <ModalSettingsConfirmation
                show={showModalSettingsConfirmation}
                closeFunction={closeModalSettingsConfirmation}
                mainMsg={mainMsg}
                scndMsg={scndMsg}
            />
            <div className="modalsettings" style={{ display: props.show }}>
                <div className="modalsettings_body">
                    <p className="modalsettings_cerrar" onClick={() => props.closeFunction()}>cerrar x</p>
                    <div className="modalsettings_body_logo">
                        <h1>Business<span>+</span> </h1>
                    </div>
                    <div className="modalsettings_form">
                        <h2>Preferencias de comunicación.</h2>
                        <div className="modalsettings_form_item">
                            <h3>No recibir Business+ News</h3>
                            <p>Esto puede ser temporal</p>
                            <input type="checkbox" id="check1" ref={primerosRef} /><label for="check1">No recibir comunicaciones de Business+ News</label>
                        </div>
                        <div className="modalsettings_form_item">
                            <h3>No recibir comunicaciones de terceros</h3>
                            <p>Esto puede ser temporal</p>
                            <input type="checkbox" id="check2" ref={tercerosRef} /><label for="check2">No recibir comunicaciones de publicidad de terceros externos a Business+</label>
                        </div>
                        <button onClick={() => changeModalSettingsCom()}>Guardar los cambios</button>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}