import React, { Fragment, useState, useEffect } from 'react'
import axios from 'axios';
import { useHistory } from "react-router-dom";
export const Activarcuenta_btnIncribirse = (props) => {

    const history = useHistory();
    const [textoInfo, setTextoInfo] = useState(false)
    let url = window.location.search.split('enlaceReturn=')[1].split('&')[0]
    let idEvento = window.location.search.split('codigoEvento=')[1].split('&')[0]
    let esPresencial = window.location.search.split('Presencial=')[1]
    let claveUsuario = window.location.search.split('code=')[1].split('&')[0]

    if (esPresencial === "true") {
        esPresencial = true
    } else {
        esPresencial = false
    }


    const fetchActivarCuenta = async () => {
        let dataFormEventos = {
            "claveUsuario": claveUsuario
        }

        let axiosConfig = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        };
        await axios.post(`${process.env.REACT_APP_URL_API}auth/VerificarRegistro`, dataFormEventos, axiosConfig)
            .then((res) => {
                console.log(res)
                history.push(url)
                return loginMail()
            })
            .catch((err) => {
                history.push(url)
                setTextoInfo('Cod invalido no se ha podido activar la cuenta')
                console.log(err)
            })
    }

    const loginMail = async () => {
        let dataFormEventos = {
            "claveUsuario": claveUsuario
        }

        let axiosConfig = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        };
        await axios.post(`${process.env.REACT_APP_URL_API}auth/LoginSF`, dataFormEventos, axiosConfig)
            .then((resLogin) => {
                console.log(resLogin);
                let loginTime = new Date().getTime();
                let limitTime = new Date().getTime() + 86400000;
                localStorage.setItem(`${process.env.REACT_APP_ID_STORAGE}-loginTime`, loginTime)
                localStorage.setItem(`${process.env.REACT_APP_ID_STORAGE}-limitTime`, limitTime)
                localStorage.setItem(`${process.env.REACT_APP_ID_STORAGE}-idu`, resLogin.data.data.idUsuario)
                localStorage.setItem(`${process.env.REACT_APP_ID_STORAGE}-nombre`, resLogin.data.data.usuario)
                localStorage.setItem(`${process.env.REACT_APP_ID_STORAGE}-token`, resLogin.data.data.token)
                localStorage.setItem(`${process.env.REACT_APP_ID_STORAGE}-id`, resLogin.data.data.claveUsuario)
                localStorage.setItem(`${process.env.REACT_APP_ID_STORAGE}-email`, resLogin.data.data.email)
                props.setMostrarModalActivacion(true)

                return inscribirseEvento(resLogin.data.data.token)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const inscribirseEvento = (token) => {
        let dataForm = {
            "claveUsuario": claveUsuario,
            "idEvento": idEvento,
            "esPresencial": esPresencial
        }
        let axiosConfig = {
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        };
        axios.post(`${process.env.REACT_APP_URL_API}Eventos/InscribirEvento`, dataForm, axiosConfig)
            .then((res) => {
                console.log(res);
                props.setMsgActivacion1('Tu inscripción y registro en Business+ se han realizado correctamente.')
                props.setMsgActivacion2('Revisa tu bandeja de entrada, donde encontrarás la confirmación de inscripción al evento. ')
                props.setMsgActivacion3('¡Bienvenido!  Ya puedes disfrutar de todos nuestros eventos, webinars y contenido en exclusiva.')
                props.setMostrarModalActivacion(true)
            })
            .catch((err) => {
                console.log("err: ", err);
            })
    }

    useEffect(() => {
        fetchActivarCuenta();
    }, [])

    if (!textoInfo) { return (<div></div>) }
    return (
        <div></div>
    )

}