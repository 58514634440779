import React, { Fragment, useState, useEffect } from 'react'
import axios from 'axios';
import { Botonbase } from "../../botonbase";
import './estilos.css';

export const ContenidoIntereses = (props) => {
  let etiquetasusuario = '';
  let etiquetasusuarioArr = ''
  let nameArr = [1, 56]

  const [datosUsuario, setDatosUsuario] = useState(false);
  const fetchSettings = async () => {

    try {

      let axiosConfig = {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-token`)}`
        },
      };
      await axios.get(`${process.env.REACT_APP_URL_API}usuarios/${localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-idu`)}`, axiosConfig)
        .then(result => {
          console.log('usuario::', result.data.data)

          setDatosUsuario(result.data.data)
        })

    } catch (error) {
      console.log('error', error)
    }
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  const updatedatosUsuario = async () => {

    try {
      var dataForm = {
        "idUsuario": localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-idu`),
        "nombre": datosUsuario.nombre,
        "apellidos": datosUsuario.apellidos,
        "email": localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-email`),
        "usuario": localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-nombre`),
        "Intereses": "44"
      }
      let axiosConfig = {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-token`)}`
        },
      };
      await axios.put(`${process.env.REACT_APP_URL_API}usuarios/InscribirseModif`, dataForm, axiosConfig)
        .then(resLogin => {
          if (resLogin.status === 200) {
            if (resLogin.data === 'error') {
              //messageLoginChange('El formulario contiene errores')
            } else {
              console.log("sectores", resLogin.data.data)
            }
          } else {
            //messageLoginChange('El formulario contiene errores')
          }

        })

    } catch (error) {
      //setloaddata(false);
      //messageLoginChange('El formulario contiene errores') 
      //formIsInvalidChange(true)
    }
  }


  const marcarEtiqueta = async (action, idEtiqueta) => {
    let string = []
    if (datosUsuario.intereses.length >= 1) { string = datosUsuario.intereses.split(',') }

    if (action == 'add') {

      string.push(idEtiqueta)
      string = string.join()
    }
    if (action == 'del') {

      const index = string.indexOf("" + idEtiqueta);
      console.log('buscando ' + idEtiqueta + " en: ", string)
      console.log('index', index)
      if (index > -1) {
        string.splice(index, 1);
        string = string.join()
      }
    }
    var dataForm = {
      "idUsuario": localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-idu`),
      "nombre": datosUsuario.nombre,
      "apellidos": datosUsuario.apellidos,
      "email": localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-email`),
      "usuario": localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-nombre`),
      "Intereses": string
    }
    let axiosConfig = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-token`)}`
      },
    };
    console.log(dataForm)
    await axios.put(`${process.env.REACT_APP_URL_API}usuarios/InscribirseModif`, dataForm, axiosConfig)
      .then(resLogin => {
        if (resLogin.status === 200) {
          if (resLogin.data === 'error') {
            //messageLoginChange('El formulario contiene errores')
          } else {
            console.log("sectores", resLogin.data.data)
            fetchSettings();
          }
        } else {
          //messageLoginChange('El formulario contiene errores')
        }

      })

  }

  const [sectores, setSectores] = useState(false)
  const fetchSectores = async () => {
    let dataFormEventos = {
      "tipoCategoria": "intereses"
    }

    let axiosConfig = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    };
    axios.post(`${process.env.REACT_APP_URL_API}formatos/ObtenerCategorias`, dataFormEventos, axiosConfig)
      .then((res) => {
        console.log("sectores", res.data.data)
        setSectores(res.data.data)
      })
      .catch((err) => {

      })
  }



  useEffect(() => {
    fetchSectores();
  }, [])
  if (!datosUsuario) return (<div>loading</div>)
  if (!sectores) { return (<div></div>) }
  return (
    <>
      <div className="contenido_datosusuario2">
        <div className="contenido_datosusuario_form">
          <div className="titulo titulo_intereses">Intereses</div>
          <div className="subtitulo">Intereses (elige al menos uno)</div>
          <div className="formulario-cuenta">

          </div>
          <div className="formulario-cuenta">
            <div className="form-group col-12 content-labels">
              {sectores.map(item =>
                datosUsuario.intereses.split(',').includes(`${item.idCategoria}`) ?
                  <div className="small labelckeck intereses_label selected" onClick={(e) => { marcarEtiqueta('del', item.idCategoria) }} id={`etiqueta-${item.idCategoria}`} > {item.descripcion}</div>
                  :
                  <div className="small labelckeck intereses_label" onClick={(e) => { marcarEtiqueta('add', item.idCategoria) }} id={`etiqueta-${item.idCategoria}`} >{item.descripcion}</div>
                //
              )
              }
            </div>
          </div>
        </div>
      </div>
      <div className="col-4">

      </div>
    </>
  )
}