import React, { useState } from 'react'
import axios from 'axios';
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import './estilos.css';

export const CajaItemEventoCiudad = (props) => {



    return (
        <div className={`caja-item-ciudad base ${props.clase}`}  >
            <div className="caja-item-contendor-imagen">
                <div className="caja-item-imagen">
                    <img src='https://businessplus.spinmedia.es/almacen/evento_6/caja_evento_6.png' />
                </div>
            </div>
            <div className="caja-item-body">
                <div className="titulo-ciudad">{props.ciudad}</div>
                <div className="titulo-fecha">{props.fecha}</div>
                <div className="titulo-lugar">{props.lugar}</div>
            </div>
            <div className="caja-item-footer">
                <a href={`${props.link}`} className="btn transparente">ver evento</a>
            </div>
        </div>
    )
}