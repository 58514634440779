import React, { Component, useContext } from 'react';
import GuardarLogContext from '../../ContextLog'
import { Botonbase } from "../botonbase";
import { CajaItemCanal } from "../cajaItemCanal";
import './estilos.css'; // Tell webpack that Button.js uses these styles

export const GridCanales = (props) => {
 
  const log = useContext(GuardarLogContext)
  const HandleClick = () => {
    log.guardarLog('click btn 1')
  }
  return (
    <>
      <div className="grid-canales">

        {
          props.canales.map(item =>

            <div className="grid-item"> <CajaItemCanal imagen={item.logo} nombre={item.nombreReducido} link={item.idEvento} enlace={item.enlace} /></div>

          )
        }


      </div>
    </>
  )
}