import { createContext } from 'react';
import axios from 'axios';

const GuardarLogContext = createContext({
  logEvento: (idEvento, esBajoDemanda, accion, valorAccion) => {
    //console.log('LOG:: ##### grabar')
    //console.log('LOG:: usuario:: ', localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-idu`))
    //console.log('LOG:: idevento:: ', idEvento)
    //console.log('LOG:: esbajodemanda:: ', esBajoDemanda)
    //console.log('LOG:: accion:: ', accion)
    //console.log('LOG:: AccionValor:: ', valorAccion)
    //console.log('LOG:: ##### end grabar')


    axios.get(`https://webinars.spinmedia.es/ip.php`)
      .then((dataip) => {

        //console.log('ip::', dataip.data.ip)
        let dataFormlog = {
          "claveUsuario": localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-id`),
          "idUsuario": localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-idu`),
          "idEvento": idEvento,
          "ip": dataip.data.ip,
          "BajoDemanda": esBajoDemanda,
          "Navegador": `${window.navigator.userAgent}`,
          "accion": accion,
          "accionValor": valorAccion
        }
        let axiosConfig = {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-token`)}`
          },
        };
        //console.log('LOG:: INICIO', dataFormlog)
        axios.post(`${process.env.REACT_APP_URL_API}eventos/GrabarEventoLogs`, dataFormlog, axiosConfig)
          .then((res) => {

            console.log('LOG:: OK', res.data)


          })
          .catch((err) => {
            console.log('LOG:: ERROR::', err)
            console.log('LOG:: ERROR::', err.response)
            console.log('LOG:: ERROR::', err.message)
          })


      })
      .catch((err) => {
        //console.log('LOG:: ERROR IP::', err)
      })
  }
});

export default GuardarLogContext;