import React, { Fragment, useState, useEffect, useContext } from 'react'
import { Helmet } from "react-helmet";

import { Header } from "../../componentes/header";
import { Footer } from "../../componentes/footer";
import { ScrollToTop } from '../../componentes/scrollToTop';
import './estilos.css';

export const Notfound = (props) => {
  return (
    <>
      {
        props.seoContenido !== null &&
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content="Plataforma de webinars, congresos online y videos bajo demanda" />
          <title>Business+</title>
        </Helmet>
      }
      <div className="notfound">
        <div className="notfound_content">
          <h2><span>Página</span> 404</h2>
          <p>Lo sentimos, la página que estás buscando ya no existe; puede haber sido movida o eliminada por completo.</p>
          <p><a href="/">Pincha aquí</a> y visita nuestra página principal.</p>
        </div>
      </div>
    </>
  )
}