import React, { Fragment, useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import axios from 'axios';

import './estilos.css';


export const Recuperarpass = (props) => {
    const history = useHistory();
    const [usuarioRegistrado, setusuarioRegistrado] = useState(false);
    const [passData, setPassData] = useState(null);
    const passChange = (val) => {
        setPassData(val.target.value)
    }

    const doRecupera = async () => {
        try {
            var dataForm = {
                "claveUsuario": window.location.search.split('code=')[1],
                "password": passData
            }
            let axiosConfig = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            };
            await axios.post(`${process.env.REACT_APP_URL_API}auth/ModificarPassword`, dataForm, axiosConfig)
                .then(resLogin => {
                    if (resLogin.status === 200) {
                        if (resLogin.data === 'error') {
                            console.log(resLogin.data);
                        } else {
                            setusuarioRegistrado(true)
                            history.push(`/`)
                        }
                    }
                })

        } catch (error) {
            console.log(error);
        }
    }
    return (
        <div className="recuperarPassword">
            <div className="recuperarPasswordModal">
                <p className="recuperarPassword_cerrar" onClick={() => history.push(`/`)}>cerrar x</p>
                <div className="recuperarPasswordModal_logo">
                    <h1>Business<span>+</span> </h1>
                </div>
                <h2>Cambio de Contraseña</h2>
                <p>Introduce tu nueva contraseña</p>
                <form className="recuperarPassword_form" onSubmit={(e) => { e.preventDefault(); doRecupera() }}>
                    <div>
                        <input type="password" placeholder="Contraseña" onChange={passChange} />
                    </div>

                    <button type="submit" id="resetpass">RESTABLECER CONTRASEÑA</button>
                </form>
            </div>
        </div>
    )

}