import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from 'react';
// import ReactDOM from 'react-dom';
import { hydrate, render } from "react-dom";
import './index.css';
import { App } from './App';
import Context from './Context'
//import reportWebVitals from './reportWebVitals';

const APPSTART = (
  <Context.Provider>
    <App />
  </Context.Provider>
);

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(APPSTART, rootElement);
} else {
  render(APPSTART, rootElement);
}

// ReactDOM.render(
//   <Context.Provider>
//     <App />
//   </Context.Provider>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
