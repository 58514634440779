import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'
import './estilos.css'; // Tell webpack that Button.js uses these styles

export const MenuCuentaUsuario = (props) => {
  return (
    <div className="menu-cuenta">
      <NavLink exact className="menu-item-cuenta" activeClassName="active" to='/perfil'>Datos usuario</NavLink>
      <NavLink exact className="menu-item-cuenta" activeClassName="active" to='/perfil/datosprofesionales'>Datos Empresa</NavLink>
      <NavLink exact className="menu-item-cuenta" activeClassName="active" to='/perfil/intereses'>Intereses</NavLink>
      <NavLink exact className="menu-item-cuenta" activeClassName="active" to='/perfil/misinscripciones'>Mis Inscripciones</NavLink>
      <NavLink exact className="menu-item-cuenta" activeClassName="active" to='/perfil/cambiarcontrasena'>Seguridad</NavLink>
      <div className="menu-item-cuenta"><a onClick={(e) => { props.removeAuth() }}>cerrar sesión</a></div>
    </div>
  )
}