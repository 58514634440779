import React, { useState, useRef, useEffect, Fragment } from 'react';
import axios from 'axios';
import { AiFillEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";
import { Botonbase } from "../botonbase";
import { SelectorInput } from "../selectorInput";
import { ModalCnae } from "../modalCnae";
import './estilos.css';

export const ModalRegistro = (props) => {
  const [valorSteep, setValorsteep] = useState(1);

  const terminosRef = useRef();
  const nombreFiscalRef = useRef();
  const residenteEspanaRef = useRef();
  const [usuarioRegistrado, setusuarioRegistrado] = useState(false);
  const [formIsInvalid, setformIsInvalid] = useState(false);
  const [quitarPaso, setQuitarPaso] = useState(false);
  const [errorPaso3, setErrorPaso3] = useState(false);

  const [errorMail, setErrorMail] = useState(false);
  const [errorExisteMail, setErrorExisteMail] = useState(false);
  const [existeMail, setExisteMail] = useState();
  const [errorMailInactivo, setErrorMailInactivo] = useState(false);
  const [enviadoMailVerifica, setEnviadoMailVerifica] = useState(false);

  const [isclick , setisclick ] = useState(false);
  
  

  const [messageLogin, setmessageLogin] = useState('');
  const messageLoginChange = (val) => {
    setmessageLogin(val)
  }
  const [showPass, setshowPass] = useState(false);
  function showPassword() {
    if (showPass) {
      setshowPass(false)
    } else {
      setshowPass(true)
    }
  }

  const [sectores, setSectores] = useState(false)
  const [intereses, setIntereses] = useState([])
  const [cleanIntereses, setCleanIntereses] = useState([])
  const [errorIntereses, setErrorIntereses] = useState(false)
  const marcarIntereses = async (action, idEtiqueta) => {
    console.log('ETIQUETAS ACTION:::', action)
    let string = []
    if (intereses.length >= 1) { string = intereses.split(',') }

    if (action == 'add') {
      cleanIntereses.push(idEtiqueta)
      console.log(cleanIntereses);
      string.push(idEtiqueta)
      string = string.join()
    }
    if (action == 'del') {
      const index = string.indexOf("" + idEtiqueta);
      let i = cleanIntereses.indexOf(idEtiqueta);
      if (i !== -1) {
        cleanIntereses.splice(i, 1);
        console.log(cleanIntereses);
      }
      if (index > -1) {
        string.splice(index, 1);
        string = string.join()
      }
    }
    setIntereses(string)
    console.log('ETIQUETAS:::', string)
  }
  const fetchSectores = async () => {
    let dataFormEventos = {
      "tipoCategoria": "intereses"
    }

    let axiosConfig = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    };
    axios.post(`${process.env.REACT_APP_URL_API}formatos/ObtenerCategorias`, dataFormEventos, axiosConfig)
      .then((res) => {
        let cleanSectores = [];
        console.log("sectores", res.data.data)
        setSectores(res.data.data)
      })
      .catch((err) => {

      })
  }
  useEffect(() => {
    fetchSectores();
  }, [])

  const limpiaFormulario = () => {
    setValorsteep(1)
    setValorEmail('')
    setErrorMail(false)
    setCnaeCode('')
    setshowCnaeModal("none")
    setErrorExisteMail(false)
    setValornombre('')
    setValorApellidos('')
    setValorDNI('')
    setValorEmpresa('')
    setValorTelefono('')
    setValorMovil('')
    setValorCp('')
    setValorPuesto('')
    setErrorNombre(false)
    setErrorDni(false)
    setErrorCnae(false)
    setErrorapellidos(false)
    setErrorTelefono(false)
    setErrorMovil(false)
    setErrorCP(false)
    setErrorPuesto(false)
    setErrorFacturacion(false)
    setErrorEmpleados(false)
    setErrorEmpresa(false)
    setIntereses([])
    setCleanIntereses([])
    setErrorIntereses(false)
    setErrorPaso3(false)
    setmessageLogin('')
  }

  const CerrarModal = () => {
    limpiaFormulario()
    setusuarioRegistrado(false)
    props.toggleModalRegisitro()
    setErrorMailInactivo(false)
  }

  const reenviarVerifica = () => {
    let dataVerifica = {
      "email":valorEmail,
      "enlaceReturn": window.location.pathname
    }

    let axiosConfig = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    };
    axios.post(`${process.env.REACT_APP_URL_API}auth/EnviarMailVerificacion`, dataVerifica, axiosConfig)
      .then((res) => {
        setErrorMailInactivo(false)
        setEnviadoMailVerifica(true)
      })
      .catch((err) => {
        
        if(err.response.data.success){
          setErrorMailInactivo(false)
          setEnviadoMailVerifica(true)
        }else{
          console.log('valida:: ERROR:2:', err)
        }
        
      })
}

 
  const [valorEmail, setValorEmail] = useState('');
  const emailChange = (val) => {
    setValorEmail(val.target.value)
    setErrorMail(false)
    setErrorExisteMail(false)
    setErrorMailInactivo(false)
    setEnviadoMailVerifica(false)
  }
  const [errorPass, setErrorPass] = useState(false)
  const [valorPass, setValorPass] = useState('');
  const passChange = (val) => {
    setValorPass(val.target.value)
    setErrorPass(false)
  }
  const [errorAviso, setErrorAviso] = useState(false)

  const [errorNombre, setErrorNombre] = useState(false)
  const [valorNombre, setValornombre] = useState('');
  const nombreChange = (val) => {
    if (nombreFiscalRef.current.checked) {
      setValornombre(val.target.value)
      setValorEmpresa(val.target.value + ' ' + valorApellidos)
      setErrorNombre(false)
    } else {
      setValornombre(val.target.value)
      setErrorNombre(false)
    }
  }

  const [errorApellidos, setErrorapellidos] = useState(false)
  const [valorApellidos, setValorApellidos] = useState('');
  const apellidosChange = (val) => {
    if (nombreFiscalRef.current.checked) {
      setValorApellidos(val.target.value)
      setValorEmpresa(valorNombre + ' ' + val.target.value)
      setErrorapellidos(false)
    } else {
      setValorApellidos(val.target.value)
      setErrorapellidos(false)
    }
  }

  const [errorDni, setErrorDni] = useState(false)
  const [valorDNI, setValorDNI] = useState('');
  const dniChange = (val) => {
    console.log('dni change')
    setValorDNI(val.target.value)
    setErrorDni(false)

    let dataFormDNI = {
      "Cif": val.target.value
    }

    let axiosConfig = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    };
    axios.post(`${process.env.REACT_APP_URL_API}plataformas/ObtenerClientePorCIF`, dataFormDNI, axiosConfig)
      .then((res) => {
        console.log("datos POR NIF", res.data.data)
        setValorEmpresa(res.data.data.nombre)
        setCnaeCode(res.data.data.cnae)
        setValorFacturacion(res.data.data.facturacion)
        setValorEmpleados(res.data.data.empleados)
      })
      .catch((err) => {
        console.log(err);
      })

  }

  const [errorEmpresa, setErrorEmpresa] = useState(false)
  const [valorEmpresa, setValorEmpresa] = useState('');
  const empresaChange = (val) => {
    if (nombreFiscalRef.current.checked) {
      setValorEmpresa(valorNombre + ' ' + valorApellidos)
      setErrorEmpresa(false)
      setErrorPaso3(false)
    } else {
      setValorEmpresa(val.target.value)
      setErrorEmpresa(false)
      setErrorPaso3(false)
    }
  }
  function handleAutonomo() {
    if (nombreFiscalRef.current.checked) {
      setValorEmpresa(valorNombre + ' ' + valorApellidos)
      setErrorEmpresa(false)
      setErrorPaso3(false)
    }
    if (!nombreFiscalRef.current.checked) {
      setValorEmpresa('')
      setErrorEmpresa(false)
      setErrorPaso3(false)
    }
  }

  const [errorCnae, setErrorCnae] = useState(false)
  const [cnaeCode, setCnaeCode] = useState('');
  const [cnaeDescription, setCnaeDescription] = useState('');
  const [showCnaeModal, setshowCnaeModal] = useState("none");
  const cnaeChange = (val) => {
    setErrorCnae(false)
    setErrorPaso3(false)
  }
  const openModalCnae = () => {
    setshowCnaeModal("block")
  }
  const pickCnae = (cnae, cnaeD) => {
    setCnaeCode(cnae);
    setCnaeDescription(cnaeD);
    setshowCnaeModal("none");
  }
  const [errorFacturacion, setErrorFacturacion] = useState(false)
  const [valorFacturacion, setValorFacturacion] = useState('- Elige una opción -');

  const [errorEmpleados, setErrorEmpleados] = useState(false)
  const [valorEmpleados, setValorEmpleados] = useState('- Elige una opción -');

  const [errorPuesto, setErrorPuesto] = useState(false)
  const [valorPuesto, setValorPuesto] = useState('');
  const puestoChange = (val) => {
    setValorPuesto(val.target.value)
    setErrorPuesto(false)
    setErrorPaso3(false)
  }
  const [errorTelefono, setErrorTelefono] = useState(false)
  const [valorTelefono, setValorTelefono] = useState('');
  const telefonoChange = (val) => {
    setValorTelefono(val.target.value)
    setErrorTelefono(false)
    setErrorPaso3(false)
  }
  const [errorMovil, setErrorMovil] = useState(false)
  const [valorMovil, setValorMovil] = useState('');
  const movilChange = (val) => {
    setValorMovil(val.target.value)
    setErrorMovil(false)
    setErrorPaso3(false)
  }

  const [errorCP, setErrorCP] = useState(false)
  const [valorCp, setValorCp] = useState('');
  const cpChange = (val) => {
    if (residenteEspanaRef.current.checked) {
      setValorCp('00000')
      setErrorCP(false)
      setErrorPaso3(false)
    }
    if (!residenteEspanaRef.current.checked) {
      setValorCp(val.target.value)
      setErrorCP(false)
      setErrorPaso3(false)
    }
  }
  function handleResidente() {
    if (residenteEspanaRef.current.checked) {
      setValorCp('00000')
      setErrorCP(false)
      setErrorPaso3(false)
    }
    if (!residenteEspanaRef.current.checked) {
      setValorCp('')
      setErrorCP(false)
      setErrorPaso3(false)
    }
  }

  const validaPasoUno = async () => {
    setErrorExisteMail(false)
    setErrorMailInactivo(false)
    setEnviadoMailVerifica(false)
    var regex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
    let dataForm = {
      "email": valorEmail.toLowerCase(),
    }
    let axiosConfig = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }
    await axios.post(`${process.env.REACT_APP_URL_API}auth/ExisteUsuarioRegistro`, dataForm, axiosConfig)
      .then((res) => {
        console.log('res::',res)
        setExisteMail(false)
        console.log("existeMailFalse:", existeMail);
        if (!regex.test(valorEmail)) {
          setErrorMail(true)
        }
        if (!existeMail && regex.test(valorEmail)) {
          setValorsteep(valorSteep + 1)
        }
      })
      .catch((err) => {
        console.log('Registro:: ERROR:2:', err)
        console.log('Registro:: ERROR:2:', err.response.data.data)
        console.log('Registro:: ERROR:2:', err.message)
        if(err.response.data.data == 'mailExiste'){
          setExisteMail(true)
          setErrorExisteMail(true)
          CerrarModal()
          props.toggleModalLogin(true)
          props.updateEmailLogin(valorEmail.toLowerCase())
          
          
        }else
        if(err.response.data.data == 'mailNoActivo'){
       
          setErrorMailInactivo(true)
          console.log("mailinactivo:", existeMail);
        }else{
          setExisteMail(true)
          setErrorExisteMail(true)
          console.log("existeMailTrue: 2", existeMail);
        }
        
      })
  }

  const validaPasoDos = () => {
    let formHasError = false
    var regex = /^(?=.*[0-9])(?=.*[!@#$%^&-_*])[a-zA-Z0-9!@#$%^&-_*]{6,16}$/;
    var regexchar = /^(?=.*[!@#$%^&-_*])[a-zA-Z0-9!@#$%^&-_*]{6,16}$/;
    if (regex.test(valorPass) || regexchar.test(valorPass)) {
      setErrorPass(false)
    } else {
      setErrorPass(true)
      formHasError = true
    }
    if (!terminosRef.current.checked) {
      formHasError = true
      setErrorAviso(true)
    } else {
      setErrorAviso(false)
    }
    !formHasError && setValorsteep(valorSteep + 1)
  }

  function titleCase(str) {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
  }
  function findWordInCargo(str) {
    let cleanCargo = [];
    var words = str.split(' ');
    words.map(word => {
      if (word === 'De') {
        word = 'de';
      }
      if (word === 'Y') {
        word = 'y';
      }
      cleanCargo.push(word)
    })
    cleanCargo = cleanCargo.join(' ')
    return cleanCargo;
  }

  const FinalizaInscripcion = async (event) => {
    setisclick(true)
    console.log('btn disable')
    
    let formHasError = false;
    const telefonoRegex = /^[0-9]*$/;

    if (valorNombre.length == 0) {
      formHasError = true
      setErrorNombre(true)
      setErrorPaso3(true)
    } else {
      setErrorNombre(false)
    }
    
    
    if (valorApellidos.length == 0) {
      formHasError = true
      setErrorapellidos(true)
      setErrorPaso3(true)
    } else {
      setErrorapellidos(false)
    }
    if (valorDNI.length != 9) {
      formHasError = true
      setErrorDni(true)
      setErrorPaso3(true)
    } else {
      setErrorDni(false)
    }
    if (valorEmpresa.length == 0) {
      formHasError = true
      setErrorEmpresa(true)
      setErrorPaso3(true)
    } else {
      setErrorEmpresa(false)
    }
    if (cnaeCode != null && cnaeCode.length == 0) {
      //formHasError = true
      //setErrorCnae(true)
      //setErrorPaso3(true)
      setErrorCnae(false) //cnae ahora no es obligatorio
    } else {
      setErrorCnae(false)
    }
    if (valorFacturacion === '- Elige una opción -') {
      //formHasError = true
      //setErrorFacturacion(true)
      //setErrorPaso3(true)
      setErrorFacturacion(false) //facturacion ahora no es obligatorio
    } else {
      setErrorFacturacion(false)
    }
    if (valorEmpleados === '- Elige una opción -') {
      //formHasError = true
      //setErrorEmpleados(true)
      //setErrorPaso3(true)
      setErrorEmpleados(false) //empleados ahora no es obligatorio
    } else {
      setErrorEmpleados(false)
    }
    if (valorFacturacion === 'sin determinar') {
      formHasError = true
      setErrorFacturacion(true)
      setErrorPaso3(true)
    } else {
      setErrorFacturacion(false)
    }
    if (valorEmpleados === 'sin determinar') {
      formHasError = true
      setErrorEmpleados(true)
      setErrorPaso3(true)
    } else {
      setErrorEmpleados(false)
    }
    if (valorTelefono.length <= 7 || valorTelefono.length >= 14 || !telefonoRegex.test(valorTelefono)) {
      formHasError = true
      setErrorTelefono(true)
      setErrorPaso3(true)
    } else {
      setErrorTelefono(false)
    }
    if (valorMovil.length >= 14 || !telefonoRegex.test(valorMovil)) {
      formHasError = true
      setErrorMovil(true)
      setErrorPaso3(true)
    } else {
      setErrorMovil(false)
    }
    if (valorPuesto.length == 0) {
      formHasError = true
      setErrorPuesto(true)
      setErrorPaso3(true)
    } else {
      setErrorPuesto(false)
    }
    if (valorCp.length != 5) {
      formHasError = true
      setErrorCP(true)
      setErrorPaso3(true)
    } else {
      setErrorCP(false)
    }
    console.log('intereses::', intereses)
    console.log('intereses::', intereses.length)

    if (intereses.length <= 0) {
      formHasError = true
      setErrorIntereses(true)
      setErrorPaso3(true)
    } else {
      setErrorIntereses(false)
    }


    if (formHasError){
      setisclick(false)
      return
    } 
    try {
      var dataForm = {
        "Email": valorEmail.toLowerCase(),
        "usuario": titleCase(valorNombre),
        "Password": valorPass,
        "Nombre": titleCase(valorNombre),
        "Apellidos": titleCase(valorApellidos),
        "CifEmpresa": valorDNI,
        "NombreEmpresa": valorEmpresa.toUpperCase(),
        "Puesto": findWordInCargo(titleCase(valorPuesto)),
        "Intereses": intereses,
        "TelefonoEmpresa": valorTelefono.split(" ").join(""),
        "movil": valorMovil.split(" ").join(""),
        "CPEmpresa": valorCp,
        "Cnae": cnaeCode,
        "DescripcionCnae": cnaeDescription,
        "Facturacion": valorFacturacion,
        "Empleados": valorEmpleados,
        "enlaceReturn": window.location.pathname
      }
      let axiosConfig = {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      };
      await axios.post(`${process.env.REACT_APP_URL_API}auth/registroweb`, dataForm, axiosConfig)
        .then(resLogin => {
          console.log('resLogin::',resLogin)
          if (resLogin.status === 200) {
            if (resLogin.data === 'error') {
              console.log(resLogin.data)
              setformIsInvalid(true)
              messageLoginChange('Email ya existe')
              setisclick(false)
              setValorsteep(1)
              
            } else {
              console.log('registrado:: form',dataForm)
              console.log('registrado:: 00',resLogin.data)
              setErrorPaso3(false)
              setformIsInvalid(false)
              setusuarioRegistrado(true)
              setQuitarPaso(true)
              setisclick(false)
            }
          } else {
            console.log(resLogin)
            setformIsInvalid(true)
            messageLoginChange('Email ya existe')
            setisclick(false)
            setValorsteep(1)
          
          }

        })
      console.log(dataForm)

    } catch (error) {
      messageLoginChange('Ha habido un error, intentelo de nuevo más tarde')
      setisclick(false)
      setformIsInvalid(true)
    }
 
  }

  if (!sectores) { return (<div></div>) }

  return (
    <div className={`baseModal ${props.mostrarModal ? 'open' : ''}`}>
      <div className="modalBody modalRegistro">
        <p className="modalRegistro_cerrar" onClick={() => CerrarModal()}> cerrar x</p>
        <div className="modalLogin_logo">
          <img src="https://businessplus.es/assets/imgpruebas/logotipo.svg" />
        </div>
        {
          !quitarPaso ?
            <div className="small">PASO {valorSteep} DE 3</div>
            : null
        }
        {
          valorSteep == 1 &&
          <div>
            <div className="titulo">Crea tu cuenta gratis y empieza a disfrutar del mejor contenido</div>
            {formIsInvalid && <div className="mensajeError" dangerouslySetInnerHTML={{ __html: messageLogin }} />}
            <label className="labelInput">Email profesional</label>
            <input className={errorMail || errorExisteMail ? "inputText inputMail hasError" : "inputText inputMail"} type="email" value={valorEmail} onChange={emailChange}></input>
            {errorMail && <div className="mensajeError">Email incorrecto</div>}
            {errorExisteMail && <div className="mensajeError">Email existente</div>}
            {enviadoMailVerifica && <div className="errormail">Email de activación de cuenta enviado, Comprueba tu correo para verificar la cuenta</div>}
            {errorMailInactivo ? 
              <div className='errormail'>
                <div><span className="mensajeError">Cuenta sin verificar.</span> Comprueba tu bandeja de correo para verificar tu cuenta. Si tienes algún problema, puedes ponerte en contacto con nosotros a través de <a href="https://businessplus.es/ayuda-y-soporte/" target={'_blank'}>Ayuda y soporte</a></div>
                <button className="boton_registrarse" onClick={() =>reenviarVerifica()}>Volver a enviar email de verificación</button>
              </div>
              :
              <button className="boton_registrarse" onClick={() => validaPasoUno()}>Siguiente</button>
            }
            
          </div>
        }
        {
          valorSteep == 2 &&
          <div>
            <div className="titulo">Establece tu contraseña y únete a Business+</div>
            <label>Crea tu contraseña</label>
            <input className={errorPass ? "inputText hasError" : "inputText"} type={showPass ? 'text' : 'password'} onChange={passChange}></input>
            {
              showPass ?
                <span toggle="#password-field" class="icono-input"><AiFillEye onClick={showPassword} /></span>
                :
                <span toggle="#password-field" class="icono-input"><AiFillEyeInvisible onClick={showPassword} /></span>
            }
            <div className="small">Utiliza un mínimo de 6 caracteres (distingue entre mayúsculas y minúsculas). Incluye algún número o carácter especial.</div>
            {errorPass && <div className="mensajeError">La contraseña no cumple el patron de seguridad</div>}

            <div className="form-aviso">
              <input type="checkbox" className={errorAviso ? "hasErrorCheck" : ""} ref={terminosRef} />
              <label className="small"><span>&nbsp;Quiero unirme a Business+</span> y tener acceso a todo el contenido de la plataforma. Al darle a “siguiente” aceptas los <a href="/aviso-legal/">términos y condiciones de la plataforma.</a></label>
              {errorAviso && <div className="mensajeError">Debe aceptar los terminos y condiciones de la plataforma</div>}
            </div>
            <button className="boton_registrarse" onClick={() => validaPasoDos()}>Siguiente</button>
          </div>
        }
        {
          valorSteep == 3 &&
          <div className="step3">
            {usuarioRegistrado ?
              <div className="row popup_registroPlataforma">
                <div className="titulo">Estás a un paso de registrarte en Business+</div>
                <p>Te hemos enviado un email para activar tu cuenta de usuario en nuestra plataforma. Por favor, revisa tu bandeja de entrada, correo no deseado o spam.</p>
                <span>Si tienes alguna duda o problema con la recepción de nuestros correos, puedes ponerte en contacto con nosotros escribiendo a incidencias@businessplus.es</span>

                <Botonbase accion={CerrarModal} clase='transparente' texto="Cerrar" />
              </div>
              :
              <div>
                <ModalCnae show={showCnaeModal} pickCnaeFunction={pickCnae} setshowCnaeModal={setshowCnaeModal} />
                <div className="titulo">Personaliza tu cuenta</div>
                <label className="step3_form_title"><span>Completa tu perfil</span> con datos profesionales y preferencias para poder ofrecerte un servicio exclusivo y de calidad.</label>

                <div className="row row1_form_separacion">
                  <div className="form-group col-2">
                    <label className="small">Nombre</label>
                    <input className={errorNombre ? "inputText hasError" : "inputText"} type="text" onChange={nombreChange}></input>
                    {errorNombre && <div className="mensajeError">Introduce un nombre válido</div>}
                  </div>
                  <div className="form-group col-2">
                    <label className="small">Apellidos</label>
                    <input className={errorApellidos ? "inputText hasError" : "inputText"} type="text" onChange={apellidosChange}></input>
                    {errorApellidos && <div className="mensajeError">Introduce un apellido válido</div>}
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-1 step3_col_ancho">
                    <label className="small">Cargo</label>
                    <input className={errorPuesto ? "inputText hasError" : "inputText"} type="text" onChange={puestoChange}></input>
                    {errorPuesto && <div className="mensajeError">Introduce un cargo</div>}
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-1 step3_col_ancho">
                    <label className="small">CIF</label>
                    <input className={errorDni ? "inputText hasError" : "inputText"} type="text" onChange={dniChange}></input>
                    {errorDni && <div className="mensajeError">El CIF debe ser un texto alfanumérico de 9 caracteres, sin guiones válido</div>}
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-1 step3_checkbox">
                    <input type="checkbox" className={errorAviso ? "hasErrorCheck" : ""} ref={nombreFiscalRef} onClick={() => handleAutonomo()} />
                    <label className="checkbox_text small">Marca esta casilla si eres autónomo y no dispones de nombre fiscal de empresa</label>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-1 step3_col_ancho">
                    <label className="small">Nombre fiscal de empresa</label>
                    <input className={errorEmpresa ? "inputText inputEmpresa hasError" : "inputText inputEmpresa"} type="text" onChange={empresaChange} value={valorEmpresa}></input>
                    {errorEmpresa && <div className="mensajeError">Introduce el nombre de tu empresa</div>}
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-1 step3_col_ancho">
                    <label className="small">CNAE</label>
                    <p>Si no conoces tu CNAE, puedes usar nuestro buscador de actividades para localizarlo. Para activarlo pincha en la lupa</p>
                    <div className="step3_selector">
                      <div className="step3_selector_input">
                        <input className={errorCnae ? "inputText hasError" : "inputText"} type="text" value={cnaeCode} onClick={() => openModalCnae()} onChange={cnaeChange}></input>
                        {errorCnae && <div className="mensajeError">Debes rellenar el CNAE</div>}
                      </div>
                      <div className="step3_selector_icon" onClick={() => openModalCnae()}>
                        <span class="material-icons-outlined">search</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-1 step3_col_ancho">
                    <label className="small">Facturación</label>
                    <SelectorInput
                      options={["De 0 a 1.5 Millones de Euros", "De 1.5 a 2.5 Millones de Euros", "De 2.5 a 5 Millones de Euros", "De 5 a 25 Millones de Euros", "Más de 50 Millones de Euros"]}
                      id="facturacionRegistro"
                      selectedValue={valorFacturacion}
                      setSelectedValue={setValorFacturacion}
                      error={errorFacturacion}
                    />
                    {errorFacturacion && <div className="mensajeError2">Elige una opción</div>}
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-1 step3_col_ancho">
                    <label className="small">Nº de empleados</label>
                    <SelectorInput
                      options={["de 1 a 10", "de 11 a 50", "de 51 a 100", "más de 100"]}
                      id="empleadosRegistro"
                      selectedValue={valorEmpleados}
                      setSelectedValue={setValorEmpleados}
                      error={errorEmpleados}
                    />
                    {errorEmpleados && <div className="mensajeError2">Elige una opción</div>}
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-2">
                    <label className="small">Teléfono profesional</label>
                    <input className={errorTelefono ? "inputText hasError" : "inputText"} type="text" onChange={telefonoChange} value={valorTelefono}></input>
                    {errorTelefono && <div className="mensajeError">Introduce un número de teléfono válido</div>}
                  </div>
                  <div className="form-group col-2">
                    <label className="small">Teléfono movil</label>
                    <input className={errorMovil ? "inputText hasError" : "inputText"} type="text" onChange={movilChange} value={valorMovil}></input>
                    {errorMovil && <div className="mensajeError">Introduce un número de teléfono móvil válido</div>}
                  </div>
                  <div className="form-group col-1 step3_checkbox">
                    <input type="checkbox" className={errorAviso ? "hasErrorCheck" : ""} ref={residenteEspanaRef} onClick={() => handleResidente()} />
                    <label className="checkbox_text small">Marca esta casilla si NO resides en España</label>
                  </div>
                  <div className="form-group col-2">
                    <label className="small">Código Postal</label>
                    <input className={errorCP ? "inputText hasError" : "inputText"} type="text" onChange={cpChange} value={valorCp}></input>
                    {errorCP && <div className="mensajeError">Introduce un código postal de 5 dígitos válido</div>}
                  </div>
                </div>
                <div className="row step3_etiquetas">
                  <div className="form-group col-1">
                    <label className="small">Intereses (elige al menos una)</label>
                  </div>
                  <div className="step3_etiquetas_items">
                    {sectores.map(item =>
                      cleanIntereses.includes(item.idCategoria) ?
                        <div className="small labelckeck selected" onClick={(e) => { marcarIntereses('del', item.idCategoria) }} id={`etiqueta-${item.idCategoria}`} > {item.descripcion}</div>
                        :
                        <div className="small labelckeck" onClick={(e) => { marcarIntereses('add', item.idCategoria) }} id={`etiqueta-${item.idCategoria}`} >{item.descripcion}</div>
                    )}
                  </div>

                  {errorIntereses && <div className="mensajeError">Selecciona al menos un interés</div>}
                </div>
                <button className={isclick ? "loading boton_registrarse" : "boton_registrarse"}  disabled={isclick ? true : false} onClick={() => FinalizaInscripcion()}>Finalizar</button>
                {errorPaso3 && <div className="mensajeError">Rellena todos los datos</div>}
                {formIsInvalid && <div className="mensajeError" dangerouslySetInnerHTML={{ __html: messageLogin }} />}
              </div>
            }
          </div>

        }
      </div>
    </div>
  )
}