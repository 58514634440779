import React, { Component, useState, useContext, useRef, useEffect } from 'react';
import videojs from 'video.js'
import axios from 'axios';
import Script from 'react-load-script'
import GuardarLogContext from '../../ContextLog'
import GuardarLogPlayerContext from '../../ContextLogPlayer'
import { FaRegPlayCircle } from "react-icons/fa";
import './estilos.css';

export const VideoPlayer = (props) => {

  const [tiempovisionado, setTiempovisionado] = useState(0)
  const [mostrarContinuar, setmostrarContinuar] = useState(true)
  const [endedVideo, setEndedVideo] = useState(false)
  const [idev, setIdev] = useState(false)

  const { videoSrc } = props.urlSource;
  const videoPlayerRef = useRef();
  const cajaid = useRef();
  const playerRef = useRef(null);
  let videoJSOptions = {
    autoplay: false,
    aspectRatio: '16:9',
    controls: true,
    sources: [
      {
        src: props.urlSource,
        type: props.tipo
      },
    ],
  }

  const getVisualizacion = async () => {
    const player2 = playerRef.current
    
    
    let url = `${process.env.REACT_APP_URL_API}eventos/MostarVisualizacionesEvento`
    //console.log('TIEMPO:: GET horacomienzo id::', idev)
    let dataFormEventos = {
      "idEvento": props.idEvento,
      "idUsuario": localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-idu`),
    }
    let axiosConfig = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-token`)}`
      },
    };
    axios.post(url, dataFormEventos, axiosConfig)
      .then((res) => {

        //setdatos(res.data.data)
        //console.log('TIEMPO:: GET horacomienzo::', res.data.data.horaComienzo)
        if (isNaN(player2.cache_.duration)) {
            setEndedVideo(false)
        }
       
        if (player2.currentTime() !== 0 && player2.currentTime() < player2.cache_.duration) {
          if(endedVideo){
            setEndedVideo(false)
          }
        }
        
       
        setTiempovisionado(res.data.data.horaComienzo)
      })
      .catch((err) => {
        console.log('ERROR:', err)
        // props.removeAuth()
      })
  }

  useEffect(() => {
    
    getVisualizacion();
  }, [props])

  const grabarDatosVisualizacion = async (idEvento, tiempo, isEnded) => {
    //console.log("isEnded", isEnded);
    //console.log('TIEMPO:: update cajaid',cajaid.current.id)
    let idupdate = 0;
    if(cajaid.current.id !== false && cajaid.current.id !== undefined){
    
    
    await getVisualizacion();
    
    let url = `${process.env.REACT_APP_URL_API}eventos/Visualizacionesevento`
    let dataFormEventos = {
      "idEvento": cajaid.current.id,
      "idUsuario": localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-idu`),
      "horacomienzo": Math.round(tiempo),
      "visualizado": isEnded
    }
    let axiosConfig = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-token`)}`
      },
    };
    axios.post(url, dataFormEventos, axiosConfig)
      .then((res) => {

        //setdatos(res.data.data)
        console.log('TIEMPO:: update horacomienzo id props::', idupdate)
        console.log('TIEMPO:: update horacomienzo id::', idEvento)
        console.log('TIEMPO:: update horacomienzo::', tiempo)
        console.log('TIEMPO:: set DATA VISUALIZA::', res.data.data)

      })
      .catch((err) => {
        console.log('TIEMPO:: ERROR:', err)
        // props.removeAuth()
      })
    }
  }

  

  const MINUTE_MS = 5000;

  useEffect(() => {
    const interval = setInterval(() => {
      const player2 = playerRef.current
        if (player2.currentTime() !== 0 && player2.currentTime() < player2.cache_.duration) {
          console.log('grabar @@@@@@@')
          grabarDatosVisualizacion(props.idEvento, player2.currentTime(), false)
        }
    }, MINUTE_MS);
    return () => {
      
      
        clearInterval(interval);
    }
    
  }, [props.urlSource])


 

  const continuarVideo = (tiempo) => {
    getVisualizacion();
    videoJSOptions = {
      autoplay: false,
      aspectRatio: '16:9',
      controls: true,
      sources: [
        {
          src: props.urlSource,
          type: props.tipo
        },
      ],
    }
    setIdev(props.idEvento)
    const player = playerRef.current
      player.src(props.urlSource)
      player.currentTime(tiempo)
      setmostrarContinuar(false)
      player.play()
      
  }

  

  useEffect(() => {
    
    setmostrarContinuar(true)
    videoJSOptions = {
      autoplay: false,
      aspectRatio: '16:9',
      controls: true,
      sources: [
        {
          src: props.urlSource,
          type: props.tipo
        },
      ],
    }
    const player = playerRef.current = videojs(videoPlayerRef.current, videoJSOptions, () => {
      player.src(videoSrc)
      
      player.on('play', () => {
        HandleClickPlay('play', player.currentTime(), false)
      });
      player.on('pause', () => {
        //console.log('pause', player.currentTime())
        HandleClick('pause', player.currentTime(), false)
      });
      player.on('ended', () => {
        //console.log('end', player.currentTime())
        HandleClick('end', player.currentTime(), true)
      
        setEndedVideo(true)
        setmostrarContinuar(true)
      });
      player.on('seeked', () => {
        HandleClick('seeked', player.currentTime(), false)
      });
    })

    return () => {
      if (player) {
       player.reset();
        //alert('Player Disposed')
      } else {
        //alert('Player NO Disposed')
      }
    }
  }, [props.urlSource])

  let isBajoDemanda = false;
  if (props.isBajodemanda == 'true') {
    isBajoDemanda = true
  }

  const log = useContext(GuardarLogContext)
  const logPlayer = useContext(GuardarLogPlayerContext)
  const HandleClickPlay = (accion, tiempo) => {
    //console.log('ENTRA EN HANDELCLICK VISUALIZA',props.idEvento)
    logPlayer.guardarLogPlayer(props.idEvento, accion, tiempo)
    log.logEvento(props.idEvento, isBajoDemanda, accion, tiempo)

  }

  const [handleaction, sethandleaction] = useState('')
  const [handletiempo, sethandletiempo] = useState('')
  const changeaction = (val) => {
    sethandleaction(val)
  }
  const changetiempo = (val) => {
    sethandletiempo(val)
  }

  const HandleClick = (accion, tiempo, isEnded) => {
    console.log('LOG:: HandleClick')
    if(handleaction != accion && handletiempo !== tiempo){
      console.log('LOG:: entra')
      changeaction(accion)
      changetiempo(tiempo)
      grabarDatosVisualizacion(props.idEvento, tiempo, isEnded)
      //logPlayer.guardarLogPlayer(props.idEvento, accion, tiempo)
      log.logEvento(props.idEvento, isBajoDemanda, accion, tiempo)
    }
    

  }

  return (
    <div className="imagenEvento">
      <div id={props.idEvento} ref={cajaid} className="cajaid">
       
      <div className="container-img">
        {
          props.directo ?
            <div className="anuncio_directo">
              <img src="https://businessplus.es/assets/imgpruebas/anuncio_emision_logo.svg" alt="icono de directo" />
              <p>En Directo</p>
            </div>
            : null
        }

        {
          (tiempovisionado > 0 && !props.directo && !endedVideo) ?
            <div className={mostrarContinuar > 0 ? "video-resume active" : "video-resume"}>
              <div className="btn-resume" onClick={() => { continuarVideo(tiempovisionado) }}>Continuar</div>
              <div className="btn-resume2" onClick={() => { continuarVideo(1) }}>Ver desde el principio</div>
            </div>
            :
            endedVideo ?
              <div className={mostrarContinuar > 0 ? "play_btn" : "play_btn_hide"} onClick={() => { continuarVideo(0) }}>
                <div className="btn-resume2" onClick={() => { continuarVideo(1) }}>Ver desde el principio</div>
              </div>
              :
              <div className={mostrarContinuar > 0 ? "play_btn" : "play_btn_hide"} onClick={() => { continuarVideo(0) }}>
                <FaRegPlayCircle />
              </div>
        }
        
        <video id={props.idEvento} ref={videoPlayerRef} className='video-js' />

      </div>
      </div>
    </div>
  )
}