import React, { useState } from 'react'
import axios from 'axios';
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import './estilos.css';

export const CajaItemPonente = (props) => {



    return (
        <div className={`caja-item-ponente base ${props.clase}`}  >
            <div className="caja-item-ponente-contendor-imagen">
                <div className="caja-item-ponente-imagen">
                    <img src={props.imagen} />
                </div>
            </div>
            <div className="caja-item-ponente-body">
                <div className="caja-item-ponente-nombre">{props.nombre}</div>
                <div className="caja-item-ponente-cargo">{props.cargo}</div>
                <div className="caja-item-ponente-empresa">{props.empresa}</div>
            </div>
        </div>
    )
}