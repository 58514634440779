import React, { Fragment, useState, useEffect, useContext } from 'react'
import axios from 'axios';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { RiArrowDropRightFill } from "react-icons/ri";
import { Helmet } from "react-helmet";

import { Header } from "../../componentes/header";
import { BannerPrincipal } from "../../componentes/bannerPrincipal";
import { CargaContenido } from "../../componentes/cargaContenido";
import { SliderItems } from "../../componentes/sliderItems";
import {SliderHome} from "../../componentes/sliderHome";
import { SliderCanales } from "../../componentes/sliderCanales";
import { CajaTexto } from "../../componentes/cajaTexto";
import { CajaInfo } from "../../componentes/cajaInfo";
import { CajaItem } from "../../componentes/cajaItem";
import { Footer } from "../../componentes/footer";
import Context from '../../Context';
import { ScrollToTop } from '../../componentes/scrollToTop';

export const Home = (props) => {
  console.log('SEO', props.seoContenido)
  if (!props.zonasContenido) { return (<div></div>) }

  return (
    <Context.Consumer>{({ modalRegistro, toggleModalRegisitro }) => <>
      {
        props.seoContenido !== null &&
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content={props.seoContenido.seoDescription} />
          <title>{props.seoContenido.seoTitle}</title>
          <meta property="og:title" content={props.seoContenido.seoTitle} />
          <meta property="og:description" content={props.seoContenido.seoDescription} />
          <meta property="og:locale" content="es_ES" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:site_name" content="Business+"></meta>
          <meta property="article:modified_time" content={props.seoContenido.fechaModificacion}></meta>
          <meta property="og:image" content={props.seoContenido.seoImage} />
          <meta property="og:image:width" content={props.seoContenido.seoImageWidth} />
          <meta property="og:image:height" content={props.seoContenido.seoImageHeight} />
          <meta name="twitter:title" content={props.seoContenido.seoTitle} />
          <meta name="twitter:description" content={props.seoContenido.seoDescription} />
          <meta name="twitter:image" content={props.seoContenido.seoImage} />
        </Helmet>
      }

      <Header logo={props.zonasContenido[0].texto} data={props.zonasContenido} />
      <SliderHome>{
      
        props.zonasContenido.map(item =>
          item.idZona === 3 &&
              <CargaContenido item={item} tipo={item.tipoZona} esHome={true} valoreExtra={props.valoreExtra}  />
        )
      
      }</SliderHome>

      <div className="section bg-gradientDark">
        <div className="container">
          <div className="txt-centrado homepublica_canales">
            {
              props.zonasContenido.map(item =>
                <Fragment>

                  {item.idZona === 4 &&

                    <CargaContenido item={item} tipo={item.tipoZona} />
                  }
                </Fragment>
              )
            }
          </div>

        </div>
      </div>

      {

        props.zonasContenido.map(item =>
          <Fragment>

            { //carrusel
              item.idZona === 5 &&
              <Fragment>
                <CargaContenido item={item} tipo={item.tipoZona} isAuth={props.isAuth} />
              </Fragment>
            }

          </Fragment>
        )

        }


      <div className="section bg-dark">
        <div className="container">
          <div className="row v-center homepublica_haztemiembro">
            <div className="col-2">
              <CajaTexto>
                {
                  props.zonasContenido.map(item =>
                    <Fragment>
                      {item.idZona === 6 &&
                        <CargaContenido item={item} tipo={item.tipoZona} />
                      }
                    </Fragment>
                  )}
              </CajaTexto>
            </div>
            <div className="col-2 homepublica_haztemiembro2">
              <CajaTexto>
                {
                  props.zonasContenido.map(item =>
                    <Fragment>
                      {item.idZona === 7 &&
                        <CargaContenido item={item} tipo={item.tipoZona} />
                      }
                    </Fragment>
                  )}
                <div className="homepublica_repuesto_btn" onClick={() => toggleModalRegisitro()}>Crea tu cuenta gratis</div>
              </CajaTexto>
            </div>
          </div>
        </div>
      </div>

      <div className="section section-border-t">
        <div className="container">
          <div className="row homepublica_iconrow">
            {
              props.zonasContenido.map(item =>
                <Fragment>
                  {item.idZona === 8 &&
                    <CargaContenido item={item} tipo={item.tipoZona} />
                  }
                </Fragment>
              )}

          </div>
        </div>
      </div>
      <div className="footBanners">
        {
          props.zonasContenido.map(item =>
            <Fragment>
              {item.idZona === 10 &&
                <CargaContenido item={item} tipo={item.tipoZona} />
              }
            </Fragment>
          )}
        {
          props.zonasContenido.map(item =>
            <Fragment>
              {item.idZona === 11 &&
                <CargaContenido item={item} tipo={item.tipoZona} />
              }
            </Fragment>
          )}
      </div>

      <Footer logo={props.zonasContenido[0].texto} data={props.zonasContenido} />
      <ScrollToTop />
    </>}
    </Context.Consumer>

  )

}