import React, { useState, useRef, useContext } from 'react';
import axios from 'axios';
import { AiFillEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";
import { Botonbase } from "../botonbase";
import GuardarLogContext from '../../ContextLog'
import './estilos.css'; // Tell webpack that Button.js uses these styles

export const ModalLogin = (props) => {
  const log = useContext(GuardarLogContext)

  const [valorSteep, setValorsteep] = useState(1);
  const [valorEmail, setValorEmail] = useState('');
  const [valorPass, setValorPass] = useState('');
  const [valorNews, setValorNews] = useState(false);
  const newsletterRef = useRef();
  const terminosRef = useRef();
  const recuerdame = useRef()

  const [showPass, setshowPass] = useState(false);
  function showPassword() {
    if (showPass) {
      setshowPass(false)
    } else {
      setshowPass(true)
    }
  }

  const limpiaFormulario = () => {
    setValorsteep(1)
    setValorEmail('')
    setformIsInvalid(false)
    //setValorNews('')
    //if(newsletterRef.current != undefined){newsletterRef.current.checked = false}
  }

  const CerrarModal = () => {
    limpiaFormulario()
    props.toggleModalLogin()
  }
  const [messageLogin, setmessageLogin] = useState('');
  const messageLoginChange = (val) => {
    setmessageLogin(val)
  }

  const [formIsInvalid, setformIsInvalid] = useState(false);
  const formIsInvalidChange = (val) => {
    setformIsInvalid(val)
  }


  const SiguientePaso = (val) => {
    setValorsteep(val)
    messageLoginChange('')
  }

  const cerrarRecupera = () => {
    setusuarioRecupera(false)
    props.toggleModalLogin()
  }
  const [usuarioRecupera, setusuarioRecupera] = useState(false)
  const [emailRecupera, setEmailRecupera] = useState(null);
  const emailRecuperaChange = (val) => {
    setEmailRecupera(val.target.value)
  }
  const recuperaPass = async () => {
    try {
      var dataForm = {
        "Email": emailRecupera
      }
      let axiosConfig = {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      };
      await axios.post(`${process.env.REACT_APP_URL_API}auth/RecuperarCuenta`, dataForm, axiosConfig)
        .then(resLogin => {
          if (resLogin.status === 200) {
            if (resLogin.data === 'error') {
              messageLoginChange('Email incorrecto')
              setusuarioRecupera(false)
            } else {
              formIsInvalidChange(false)
              setusuarioRecupera(true)
              // props.modaToggle(false)
              // props.showRecoveryToggle(false)
              // props.showRegistroToggle(false)

            }
          } else {
            messageLoginChange('Ha habido un error')
            setusuarioRecupera(false)
          }

        })

    } catch (error) {
      //setloaddata(false);
      messageLoginChange('Ha habido un error. Intentelo de nuevo más tarde')
      setusuarioRecupera(false)
      formIsInvalidChange(true)
    }
  }

  const abreRegistro = () => {
    limpiaFormulario()
    props.toggleModalLogin()
    props.toggleModalRegisitro()
  }
  let valorinicial = props.emailLogin  ? props.emailLogin : ''
  const [nombreData, setNombreData] = useState(valorinicial);

  const nombreChange = (val) => {
    setNombreData(val.target.value)
  }

  const [passData, setPassData] = useState(null);
  const passChange = (val) => {
    setPassData(val.target.value)
  }

  const realizarLogin = async () => {
    let caducidad = 86400000 //24 horas
    if (recuerdame.current.checked === true) {
      caducidad = 2592000000 //30 dias
    }
    let loginnombreData = nombreData == '' && props.emailLogin != '' ?  props.emailLogin :  nombreData
    try {
      var dataForm = {
        "email": loginnombreData,
        "password": passData,
        "caducidad": caducidad
      }
      let axiosConfig = {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      };
      await axios.post(`${process.env.REACT_APP_URL_API}auth/LoginMail`, dataForm, axiosConfig)
        .then(resLogin => {
          if (resLogin.status === 200) {
            if (resLogin.data === 'error') {
              // messageLoginChange('Email o contraseña incorrectos. <BR/> Debes registrarte primero para acceder al contenido.')
              console.log('Email o contraseña incorrectos. <BR/> Debes registrarte primero para acceder al contenido.')
            } else {
              let loginTime = new Date().getTime();
              let limitTime = new Date().getTime() + caducidad;
              localStorage.setItem(`${process.env.REACT_APP_ID_STORAGE}-loginTime`, loginTime)
              localStorage.setItem(`${process.env.REACT_APP_ID_STORAGE}-limitTime`, limitTime)
              localStorage.setItem(`${process.env.REACT_APP_ID_STORAGE}-idu`, resLogin.data.data.idUsuario)
              localStorage.setItem(`${process.env.REACT_APP_ID_STORAGE}-nombre`, resLogin.data.data.usuario)
              localStorage.setItem(`${process.env.REACT_APP_ID_STORAGE}-token`, resLogin.data.data.token)
              localStorage.setItem(`${process.env.REACT_APP_ID_STORAGE}-id`, resLogin.data.data.claveUsuario)
              localStorage.setItem(`${process.env.REACT_APP_ID_STORAGE}-email`, resLogin.data.data.email)
              log.logEvento(null, false, 'Login', 'true')
              //.modaToggle(false)

              //formIsInvalidChange(false)

              props.activateAuth()
              props.toggleModalLogin()
              window.location.reload()
            }
          } else {
            // messageLoginChange('Email o contraseña incorrectos. <BR/> Debes registrarte primero para acceder al contenido.')
            formIsInvalidChange(true)
            messageLoginChange('Email o contraseña incorrecto')
            console.log('Email o contraseña incorrecto')
          }

        })

    } catch (error) {
      //setloaddata(false);
      formIsInvalidChange(true)
      messageLoginChange('Email o contraseña incorrecto')
      console.log('Email o contraseña incorrectos. <BR/> Debes registrarte primero para acceder al contenido. 33', error)
    }
  }

  return (
    <div className={`baseModal ${props.mostrarModal ? 'open' : ''}`}>
      <div className="modalBody modalLogin">
        <p className="modalLogin_cerrar" onClick={() => CerrarModal()}>cerrar x</p>
        <div className="modalLogin_logo">
          <img src="https://businessplus.es/assets/imgpruebas/logotipo.svg" />
        </div>
        {
          valorSteep == 1 &&
          <div>
            <div className="titulo">Inicia sesión con tu correo electrónico y contraseña</div>
            {formIsInvalid && <div className="mensajeError" dangerouslySetInnerHTML={{ __html: messageLogin }} />}
            <div className="form-group col-1">
              <label className="labelInput">Correo electrónico</label>
              <input className="inputText" name='username' id='username' type="email" onChange={nombreChange} value={nombreData ? nombreData : props.emailLogin != false ? props.emailLogin  : ''} ></input>
            </div>
            <div className="form-group col-1">
              <label className="labelInput">Contraseña</label>
              <input className="inputText" name='password' id='password' autoComplete="off" type={showPass ? 'text' : 'password'} onChange={passChange}></input>
              {
                showPass ?
                  <span toggle="#password-field" class="icono-input"><AiFillEye onClick={showPassword} /></span>
                  :
                  <span toggle="#password-field" class="icono-input"><AiFillEyeInvisible onClick={showPassword} /></span>
              }
            </div>
            <div className='recuerdame'>
              <input type="checkbox" ref={recuerdame} />
              <label className='small'>Recuérdame</label>
            </div>
            <div className="form-group col-1">
              <button className="boton_iniciarsesion" onClick={() => realizarLogin()}>Entrar</button>
            </div>
            <div className="helperLogin">
              <div className="small" onClick={(e) => { SiguientePaso(2) }}>¿Has olvidado tu contraseña?</div>
              <div className="small" onClick={(e) => { abreRegistro() }}><span>¿No tienes cuenta?</span> ¡Créala aquí!</div>
            </div>
          </div>
        }
        {
          valorSteep == 2 &&
          <div>
            {!usuarioRecupera ?
              <>
                <div className="titulo">Recuperar contraseña</div>
                <label className="labelInput">Correo electrónico</label>
                <input className={formIsInvalid ? "inputText hasError" : "inputText"} type="email" name='emailuser' id='emailuser' onChange={emailRecuperaChange}></input>
                {formIsInvalid && <div className="mensajeError" dangerouslySetInnerHTML={{ __html: messageLogin }} />}
                <button className="boton_registrarse" onClick={() => recuperaPass()}>Recuperar</button>

              </>
              :
              <>
                <div className="titulo titulo_popup_pass">Solicitud recibida</div>
                <div className="texto_popup">Te hemos enviado un email para que puedas recuperar tu contraseña.</div>
                <div className="btn transparente btn_popup" onClick={() => cerrarRecupera()}>Cerrar</div>
              </>
            }
            <div className="helperLogin">
              <div className="small" onClick={(e) => { SiguientePaso(1) }}>Iniciar sesión</div>
              <div className="small" onClick={(e) => { abreRegistro() }}> <span>¿No tienes cuenta?</span> ¡Créala aquí!</div>
            </div>
          </div>
        }

      </div>
    </div>
  )
}