import React, { createContext, useState } from 'react'

const Context = createContext()

let userislogin = false

if (localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-token`) !== null) {
    userislogin = true
} else {
    userislogin = false
}

const Provider = ({ children }) => {

    const [isAuth, setIsAuth] = useState(userislogin)
    const [modalRegistro, setModalRegistro] = useState(false)
    const [modalLogin, setModalLogin] = useState(false)
    const [emailLogin, setEmailLogin] = useState(false)
    const value = {
        isAuth,
        modalRegistro,
        modalLogin,
        emailLogin,

        activateAuth: () => {
            //console.log('USER LOGIN OK', isAuth)
            setIsAuth(true)

        },
        removeAuth: () => {
            localStorage.clear()
            setIsAuth(false)
            document.location.href = "/";

        },
        toggleModalRegisitro: () => {
            //console.log('abrir cerrar modal::', modalRegistro)
            setModalRegistro(!modalRegistro)
            //console.log('abrir cerrar modal2::', modalRegistro)
        },
        toggleModalLogin: () => {
            setModalLogin(!modalLogin)
        },
        updateEmailLogin: (val) => {
            //console.log('updateEmailLogin',val)
            setEmailLogin(val)
        }
    }

    return (
        <Context.Provider value={value}>
            <>
                {children}
            </>
        </Context.Provider>
    )
}

export default {
    Provider,
    Consumer: Context.Consumer
}