import React, { Fragment, useState, useEffect, useContext } from 'react'
import axios from 'axios';
import { Helmet } from "react-helmet";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import Slider from "react-slick";
import { RiArrowDropRightFill } from "react-icons/ri";
import { Header } from "../../componentes/header";
import { BannerPrincipal } from "../../componentes/bannerPrincipal";
import { CargaContenido } from "../../componentes/cargaContenido";
import { SliderItems } from "../../componentes/sliderItems";
import { SliderCanales } from "../../componentes/sliderCanales";
import { CajaItemEventoSesion } from "../../componentes/cajaItemEventoSesion";
import { CajaItemEventoPresencial } from "../../componentes/cajaItemEventoPresencial";
import { CajaTexto } from "../../componentes/cajaTexto";
import { CajaInfo } from "../../componentes/cajaInfo";
import { CajaItem } from "../../componentes/cajaItem";
import { Footer } from "../../componentes/footer";
import { Botonbase } from "../../componentes/botonbase";
import { ScrollToTop } from '../../componentes/scrollToTop';

export const EventosSesiones = (props) => {
  const [itemsZona5, setItemsZona5] = useState(false)
  const [itemsZona7, setItemsZona7] = useState(false)
  
  

  const checkzonas = async () => {
    props.zonasContenido.map(item =>
    
      {
        if(item.idZona === 5 ||item.idZona === 6 ){
          setItemsZona5(true)
        }
        if(item.idZona === 7 ){
          setItemsZona7(true)
        }
        
      }
    
    )}
  
     useEffect(() => {
       checkzonas();
    }, [])

  
  // const handleClick = () => {
  //   console.log('click btn 2')
  // }

  // const settings = {
  //   dots: true,
  //   className: "slider-eventos",
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   arrows: false,
  //   infinite: false,
  //   centerMode: false,
  //   centerPadding: "0",
  // };

  // const [listaEventos, setListaEventos] = useState(false)
  // const fetchListaEventos = async () => {
  //   let dataFormEventos = {
  //     "formato": "home_publica",

  //   }

  //   let axiosConfig = {
  //     headers: {
  //       'Accept': 'application/json',
  //       'Content-Type': 'application/json',

  //     },
  //   };
  //   axios.post(`${process.env.REACT_APP_URL_API}formatos/obtenerformatopublico`, dataFormEventos, axiosConfig)
  //     .then((res) => {

  //       console.log('FOTMATO PUBLICO::', res.data.data)
  //       setListaEventos(res.data.data)
  //       console.log('DATA HOME::', res.data.data)

  //     })
  //     .catch((err) => {
  //       console.log('ERROR:', err)
  //       // props.removeAuth()
  //     })
  // }

  // useEffect(() => {
  //   fetchListaEventos();
  // }, [])

  // if (!listaEventos) { return (<div></div>) }
  console.log("items pagina", props.zonasContenido);

  return (
    <>
      {
        props.seoContenido !== null &&
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content={props.seoContenido.seoDescription} />
          <title>{props.seoContenido.seoTitle}</title>
          <meta property="og:title" content={props.seoContenido.seoTitle} />
          <meta property="og:description" content={props.seoContenido.seoDescription} />
          <meta property="og:locale" content="es_ES" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:site_name" content="Business+"></meta>
          <meta property="article:modified_time" content={props.seoContenido.fechaModificacion}></meta>
          <meta property="og:image" content={props.seoContenido.seoImage} />
          <meta property="og:image:width" content={props.seoContenido.seoImageWidth} />
          <meta property="og:image:height" content={props.seoContenido.seoImageHeight} />
          <meta name="twitter:title" content={props.seoContenido.seoTitle} />
          <meta name="twitter:description" content={props.seoContenido.seoDescription} />
          <meta name="twitter:image" content={props.seoContenido.seoImage} />
        </Helmet>
      }
      <Header logo={props.zonasContenido[0].texto} data={props.zonasContenido} />
      <div className="bannerInterior">
        {
          props.zonasContenido.map(item =>
            <Fragment>
              {item.idZona === 3 &&
                <CargaContenido item={item} tipo={item.tipoZona} />
              }
            </Fragment>
          )
        }
      </div>


      <div className="section bg-gradientDarkFlip">
        <div className="homepublica_contenidos_todo">
          <div className="homepublica_contenidos_cabecera">
            {
            props.zonasContenido.map(item =>
              <Fragment>
                {item.idZona === 4  && item.tipoZona === "texto" &&
                  <CargaContenido item={item} tipo={item.tipoZona} />
                }
              </Fragment>
            )
          }
          </div>
          <div className="homepublica_contenidos_8grid"> 
            {
              props.zonasContenido.map(item =>
                <Fragment>
                  {item.idZona === 4 && item.tipoZona === "lista_eventos_hijos" &&
                    item.eventos.map(evento =>
                      evento.logo === null  ? 
                        <CajaItemEventoSesion evento={evento} titulo={evento.nombre} link={evento.enlace} ciudad={evento.ciudad} fechaEvento={evento.fechaEvento} isAuth={props.isAuth} />
                      :
                        <CajaItemEventoPresencial imagen={evento.logo} enlace={evento.enlace} /> 
                    )
                  }
                </Fragment>
              )
            }
          </div>
        </div>
      </div>
      { itemsZona5 &&
      <div className="section bg-dark">
        <div className="container">
          <div className="row v-center homepublica_haztemiembro">
            <div className="col-2">
              <CajaTexto>
                {
                  props.zonasContenido.map(item =>
                    <Fragment>
                      {item.idZona === 5 &&
                        <CargaContenido item={item} tipo={item.tipoZona} />
                      }
                    </Fragment>
                  )}
              </CajaTexto>
            </div>
            <div className="col-2 homepublica_haztemiembro2">
              <CajaTexto>
                {
                  props.zonasContenido.map(item =>
                    <Fragment>
                      {item.idZona === 6 &&
                        <CargaContenido item={item} tipo={item.tipoZona} />
                      }
                    </Fragment>
                  )}
              </CajaTexto>
            </div>
          </div>
        </div>
      </div>
      }
      { itemsZona7 &&
      <div className="section section-border-t">
        <div className="container">
          <div className="row homepublica_iconrow">
            {
              props.zonasContenido.map(item =>
                <Fragment>
                  {item.idZona === 7 &&
                    <CargaContenido item={item} tipo={item.tipoZona} />
                  }
                </Fragment>
              )}

          </div>
        </div>
      </div>
      }

      <div className="footBanners">
        {
          props.zonasContenido.map(item =>
            <Fragment>
              {item.idZona === 8 &&
                <CargaContenido item={item} tipo={item.tipoZona} />
              }
            </Fragment>
          )}
        {
          props.zonasContenido.map(item =>
            <Fragment>
              {item.idZona === 9 &&
                <CargaContenido item={item} tipo={item.tipoZona} />
              }
            </Fragment>
          )}
      </div>

      {
        props.zonasContenido.map(item =>
          item.idZona === 10 && item.orden === 1 ?

            <div className="section bg-white section_sponsors">
              {
                props.zonasContenido.map(item =>
                  item.idZona === 10 && item.orden === 1 ?
                    <div className="cajaSponsors destacado">
                      <div className="tituloSponsor">Sponsors</div>
                      <div className="gridsponsors">
                        {
                          props.zonasContenido.map(item =>
                            item.idZona === 10 && item.orden === 1 &&
                            item.logotipos.map(logotipoSponsor =>
                              <div className="itemSponsor"><a href="#"><img src={logotipoSponsor.urlLogotipo} /></a></div>
                            )
                          )
                        }
                      </div>
                    </div>
                    : null
                )
              }

              {
                props.zonasContenido.map(item =>
                  item.idZona === 10 && item.orden === 2 && item.logotipos > 0 ?

                    <div className="cajaSponsors">
                      <div className="tituloSponsor">Colaboradores</div>
                      <div className="gridsponsors">
                        {
                          props.zonasContenido.map(item =>
                            item.idZona === 10 && item.orden === 2 &&
                            item.logotipos.map(logotipoSponsor =>
                              <div className="itemSponsor"><a href="#"><img src={logotipoSponsor.urlLogotipo} /></a></div>
                            )
                          )
                        }
                      </div>
                    </div>
                    : null
                )
              }

              {
                props.zonasContenido.map(item =>
                  item.idZona === 10 && item.orden === 3 ?
                    <div className="cajaSponsors">
                      <div className="tituloSponsor">Media Partners</div>
                      <div className="gridsponsors">
                        {
                          props.zonasContenido.map(item =>
                            item.idZona === 10 && item.orden === 3 &&
                            item.logotipos.map(logotipoSponsor =>
                              <div className="itemSponsor"><a href="#"><img src={logotipoSponsor.urlLogotipo} /></a></div>
                            )
                          )
                        }
                      </div>
                    </div>
                    : null
                )
              }

            </div>
            : null
        )
      }

      <Footer logo={props.zonasContenido[0].texto} data={props.zonasContenido} />
      <ScrollToTop />
    </>

  )

}