import React, { Component } from 'react';
import Slider from "react-slick";
import { Link } from "react-router-dom";
import './estilos.css';

export const SliderCanales = (props) => {

  console.log("canales", props.canales)
  const abrecanal = (id) => {
    document.location.href = `canales/${id}`;
  }
  const settings = {
    dots: false,
    className: "slider-canales",
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide:0,
    infinite: false,
    centerMode: false,
    variableWidth: false,
    responsive: [
      {
        breakpoint: 1700,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,

        }
      },
      {
        breakpoint: 1450,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,

        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,

        }
      },
    
      {
        breakpoint: 860,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 340,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  if (props.canales == undefined) { return (<></>) }
  return (
    <div className="sliderCanales">
      <div className={`contenedorSlider ${props.clase}`} onClick={props.accion} >
        <Slider {...settings}>
          {
            props.canales.map(item =>
              <div className="item-slider-conteiner">
                <Link to={item.enlace}>
                  <div className="item-slider">
                    <img src={item.logo} alt={item.nombreReducido} />
                  </div>
                </Link>
              </div>
            )
          }
        </Slider>
      </div>
    </div>
  )
}